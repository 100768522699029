import { useAttachmentAPI, useMemoRef } from 'platform/react/hook'
import Toolbar from 'platform/react/hoc/attachment/toolbar'
import { useTheme } from '@mui/material'

/**
 * Displays a simple text file in an iframe.
 *
 * @param {Object} attachment   information about the attachment
 * @param {string} group        attachment group
 * @param {Object} props        additional props
 * @returns {JSX.Element}
 * @constructor
 */
const Text = ({ attachment, group, ...props }) => {
  const theme = useTheme()
  const { state } = useAttachmentAPI({ initial: !attachment.value.url, attachment, group })

  return (
    // We can't fetch the attachment in JS due to CORS. But we can use an iframe.
    <iframe
      src={attachment?.value?.url || state?.src}
      style={{
        height: '100%',
        width: '100%',
        border: 'none',
        backgroundColor: theme.palette.common.white,
        borderRadius: '0.5rem',
      }}>
    </iframe>
  )
}

export default useMemoRef(Toolbar(Text), props => [props.attachment])
