import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellOrganisationChildOrParent } from 'ui/Element/List/Item/Cell/Organisation/ChildOrParent'

export default CellHOC(
  LabeledHOC(
    props => props.label,
    ListItemCellOrganisationChildOrParent,
  ),
)
