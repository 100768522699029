import PersonName from 'ui/Element/List/Item/Cell/Person/Labeled/FullName'
import RequestType from 'ui/Element/List/Item/Cell/Request/Labeled/Type'
import ServiceItemName from 'ui/Element/List/Item/Cell/ServiceItem/Labeled/Name'
import OrganisationName from 'ui/Element/List/Item/Cell/Organisation/WithAddress'

/**
 * Displays the appropriate list item based on the incoming {@code type}.
 *
 * @param {object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellOrganisationMultiIcon = (props) => {
  const { docType } = props

  return (
    <>
      {docType === 'person' && (<PersonName {...props} labels={props.personLabels} />)}
      {docType === 'request' && (<RequestType {...props} />)}
      {docType === 'serviceitem' && (<ServiceItemName {...props} labels={props.serviceItemLabels} />)}
      {docType === 'organisation' && (<OrganisationName {...props} />)}
    </>
  )
}

export default ListItemCellOrganisationMultiIcon
