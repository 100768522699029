/* global React */
import { cloneElement, forwardRef, useCallback, useState } from 'react'
import SimpleButton from 'ui/Element/Button/Simple'
import { Menu, useTheme } from '@mui/material'
import { useMemoRef } from 'platform/react/hook'
import { arrayOf } from 'lib/util'

const Button = forwardRef(SimpleButton)

/**
 * MenuButton Component
 * Displays a button using {@link SimpleButton}, opening a {@link Menu} to show {@param children}.
 *
 * @param {React.Ref} forwardedRef  ref to the element
 * @param {boolean} hidden          whether the button is hidden
 * @param {Object} buttonProps      props for the button
 * @param {string} value            value for the button
 * @param {Object} events           events for the menu
 * @param {Object[]} children       children to display
 * @param {Object} props            additional props
 * @returns {null|JSX.Element}
 * @constructor
 */
const MenuButton = ({ forwardedRef, hidden, buttonProps, value, events, children, ...props }) => {
  const theme = useTheme()
  const [anchor, setAnchor] = useState(null)
  const open = Boolean(anchor)

  const targetIconProps = buttonProps.startIconProps || buttonProps.iconProps

  const handleClick = useCallback((e) => {
    if (open) {
      setAnchor(null)
      events?.onClose?.()
    } else {
      setAnchor(e.currentTarget)
      events?.onOpen?.()
    }
  }, [open, events?.onOpen, events?.onClose])

  return hidden ? null : (
    <>
      <Button
        ref={forwardedRef}
        {...buttonProps}
        aria-controls={open ? `${value}-menu` : undefined}
        aria-haspopup={true}
        aria-expanded={open}
        onClick={handleClick}
        {...props.chevron && {
          endIcon: 'chevron_right',
          endIconProps: {
            ...targetIconProps,
            variant: 'filled',
            rotate: !open ? 90 : 270,
          },
        }}
        value={value}
      />
      <Menu
        id={`${value}-menu`}
        open={open}
        anchorEl={anchor}
        onClose={handleClick}
        elevation={props?.elevation || 3}
        MenuListProps={{
          sx: {
            padding: '0.5rem',
          },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            sx: {
              width: props.width || '20rem',
              marginTop: '0.5rem',
              boxShadow: `0px 8px 14px 0px ${theme.palette.gray[950]}`,
            },
          },
        }}
      >
        {arrayOf(children).reduce((acc, child, index) => {
          !child.props.hidden
            && acc.push(cloneElement(child, {
              ...child.props,
              key: index,
              parentAnchorState: setAnchor,
              closeAfterClick: props?.closeAfterClick,
            }))
          return acc
        }, [])}
      </Menu>
    </>
  )
}

export default useMemoRef(MenuButton, props => [
  props.value,
  props.events,
  props.children,
])
