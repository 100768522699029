/* global G, React */
import useMemoRef from '@platform/react/hook/useMemoRef'
import usePromise from '@platform/react/hook/usePromise'
import ApplicationContext from '@platform/react/context/application'
import ChildrenHOC from '@platform/react/hoc/children'
import { useEffect } from 'react'

const {
  Suspense, lazy, useContext, useLayoutEffect,
} = React

/**
 * SuspendedList component
 *
 * Attempts to obtain the list data, to send it to the adapter to be initialized with it and returns
 * the child with key "list" to be rendered.
 *
 * @param {{read: function}} promisedData the wrapped promised data
 * @param {boolean} fetch                 whether the component should fetch remote data during its
 *                                        initialization
 * @param {Object} props                  additional component's properties
 * @param {Object} props.childrenObject   an object containing an element for each child of this
 *                                        component for a better access to them. Generated with
 *                                        {@link ChildrenHOC}
 * @returns {JSX.Element}                 a new list component
 * @constructor
 */
const SuspendedList = ({ promisedData, fetch, ...props }) => {
  const suspendedData = promisedData.read()
  const { eventBus } = useContext(ApplicationContext)
  const { List } = props.childrenObject
  const { group } = props

  useEffect(() => {
    const eventType = eventBus.type(G.ATTACHMENT, G.CACHE, group)
    const data = { [G.DATA]: fetch && suspendedData }
    eventBus.dispatch(eventType, { [G.DATA]: data })
  }, [suspendedData])

  return (
    <List { ...props } data={suspendedData} />
  )
}

/**
 * Attachment Gallery component
 *
 * Starts loading list data by calling {@code props.events.onOpen} and lazy-loads the element to be
 * used to display each obtained item from the {@code template} parameter's path. While
 * lazy-loading the element, it displays a temporary {@code label} view.
 *
 * @param {string} template               the path to the element to be used to render each item
 * @param {string} label                  the label to display while the data is being loaded
 * @param {Object} props                  additional component's properties
 * @param {boolean} props.fetch           whether the component should fetch remote data during its
 *                                        initialization
 * @param {Object} props.events           the events to be used by this and its children components
 * @param {function} props.events.onOpen  the function to be used to obtain the data to be displayed
 * @returns {JSX.Element}                 a new temporarily suspended list
 * @constructor
 */
const AttachmentGallery = ({ template, label, ...props }) => {
  try {
    const promisedData = props.fetch ? usePromise(props.events.onOpen) : { read: () => [] }
    // we have to lazy load the item template here,
    // in order to reduce re-rendering cycles of children and the component itself
    const ListItem = lazy(() => import(`@ui/${template}`))
    return (
      <Suspense fallback={label}>
        <SuspendedList { ...props } promisedData={promisedData} ListItem={ListItem}/>
      </Suspense>
    )
  } catch (e) {
    console.error('gallery error', e.message)
    return (<p>ERROR</p>)
  }
}

export default useMemoRef(ChildrenHOC(AttachmentGallery), props => [
  props.classes,
  props.itemClasses,
])
