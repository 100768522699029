import CellHOC from '@platform/react/hoc/list/cell'
import LabeledHOC from '@platform/react/hoc/list/labeled'
import { ListItemCellOrganisationApprovalStatus } from 'ui/Element/List/Item/Cell/Organisation/ApprovalStatus'

export default CellHOC(
  LabeledHOC(
    props => props.label,
    ListItemCellOrganisationApprovalStatus,
  ),
)
