/* eslint-disable object-curly-newline */
/* global React */
import { useState, useTransition, useEffect, useRef, useCallback } from 'react'
import { FormControl, InputAdornment, Input, FormHelperText, Grid } from '@mui/material'
import PlatformEvent from 'lib/util/event'
import { useEventCallback, useMemoRef, useStyles } from 'platform/react/hook'
import { ClearAdornment } from 'ui/Element/Field/Input'
import SvgIcon from 'ui/Element/Icon/Svg'

const adornmentStyles = (theme, { size }, additionalStyles = {}) => ({
  height: 'auto',
  width: 'auto',
  maxHeight: 'none',
  padding: theme.spacing(0.4),
  ...size === 'large' ? {
    padding: '0.5rem',
    marginLeft: '0.5rem',
  } : {
    padding: '0.25rem',
    marginLeft: '0.3rem',
  },
  ...additionalStyles,
})

const styles = (theme, { size }) => ({
  root: {
    position: 'relative',
    margin: 0,
  },
  field: {
    height: 'auto',
    transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 50,
    marginTop: [[0], '!important'],
    backgroundColor: theme.palette.gray[960],
    color: theme.palette.black.main,
    fontWeight: 500,
    ...size === 'large' ? {
      padding: [['0.5rem', '0.5rem', '0.5rem', '1.5rem'], '!important'],
      fontSize: '1rem',
      lineHeight: '1.5rem',
    } : {
      padding: [['0.25rem', '0.25rem', '0.25rem', '1rem'], '!important'],
      fontSize: '0.875rem',
      lineHeight: '1.375rem',
    },
    // '&:focus-within': {
    //   backgroundColor: theme.palette.gray[900],
    // },
    '&::after, &::before, &:hover::after, &:hover::before': {
      borderBottom: ['none', '!important'],
    },
    '& > input': {
      padding: 0,
    },
  },
  clearAdornment: adornmentStyles(theme, { size }),
  clearIcon: {
    color: theme.palette.black.main,
  },
  searchAdornment: adornmentStyles(theme, { size }, {
    marginRight: 2,
    borderRadius: 50,
    backgroundColor: theme.palette.white.main,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.gray[900],
    },
  }),
  searchIcon: {
    color: theme.palette.black.main,
  },
  helperText: {
    marginTop: 0,
  },
  ...theme.custom.actionSearchField,
})

/**
 * Sets {@param value} as the value of the HTMLInputElement element referenced by {@param ref} and
 * dispatches an input event accordingly.
 *
 * @param {React.Ref} ref a react reference to an HTMLInputElement element
 * @param {string} value  the value to set
 */
const setInputValue = (ref, value) => {
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype,
    'value').set
  nativeInputValueSetter.call(ref.current, value)
  ref.current.dispatchEvent(new Event('input', { bubbles: true }))
}

/**
 * SearchAdornment element
 *
 * Returns the search button adornment, to be prepended to the search field.
 *
 * @param {Object} classes    the component's style classes
 * @param {number} [size=16]  icon dimensions
 * @param {function} onClick  the handler to call whenever the button is clicked
 * @returns {JSX.Element}     the new SearchAdornment element
 * @constructor
 */
const SearchAdornment = ({ classes, size = 16, onClick }) => (
  <InputAdornment
    position="start"
    className={classes.searchAdornment}
    onClick={onClick}
  >
    <SvgIcon
      icon={'search'}
      className={classes.searchIcon}
      size={size}
    />
  </InputAdornment>
)

/**
 * SearchField element
 *
 * Returns a field to be used to search among different domain objects, of one type at each given
 * time. The SearchField is additionally composed by a search button and a clear button, and the
 * search event can be fired either by clicking on the said search button or by pressing the enter
 * key.
 *
 * @param {Object} forwardedRef
 * @param {'medium'|'large'} [size]         field dimensions
 * @param {boolean} [fullWidth]             whether the element should take parent's full width
 *                                          time its {@param props.events.onSearch} function
 *                                          changes.
 * @param {Object} [props]                  additional element's properties
 * @param {Object} [props.transform]        transform type to be used for the search request
 * @param {string} props.events             the event handlers to be used by the element
 * @param {function} props.events.onSearch  the handler to be called whenever the search button is
 *                                          clicked or the enter key is pressed
 * @param {function} props.events.onChange  the handler to be called whenever the value of the
 *                                          search input is changed
 * @param {string} [props.value]            a string to set as the value of the field
 * @param {string} [props.placeholder]      the text to be used as the search field's placeholder
 * @param {Object} [props.classes]          the component's style classes
 * @param {string} [props.focused]          whether to focus the field as soon as it is rendered
 * @param {boolean} [props.iconEnd]         whether to display the search button at the end of the
 *                                          field
 * @param {boolean} [props.searchOnClear]   whether the field calls {@code onSearch} after clearing
 *                                          the field through the clear button
 * @returns {JSX.Element}                   the new SearchField component
 * @constructor
 */
const SearchField = ({ forwardedRef, size = 'medium', fullWidth = true, focused, ...props }) => {
  const { transform, events: { onChange, onSearch } = {}, helperText, ...rest } = props
  const { value: controlledValue, placeholder, iconEnd, searchOnClear = true, ...inputProps } = rest
  const [, startTransition] = useTransition()
  const [value, setValue] = useState(props.value || '')
  const inputRef = useRef(null)
  const rootClasses = useStyles(styles, { size })()
  const classes = { ...rootClasses, ...props.classes || {} }

  /**
   * Updates element's state whenever the inner {@link Input} element changes.
   */
  const handleChange = (event) => {
    const rawValue = event.target.value
    onChange?.(new PlatformEvent(event, { value: rawValue }))
    setValue(rawValue)
  }

  /**
   * Passes the search event to the {@link onSearch} event handler with the current content of the
   * search input field.
   */
  const search = useEventCallback((event) => {
    startTransition(() => {
      onSearch?.(new PlatformEvent(event, { term: event ? value.trim() : '', transform }))
    })
  })

  /**
   * Sets the field value as an empty string and passes it to the {@link onSearch} event handler.
   */
  const clearAndSearch = useEventCallback((event) => {
    const empty = ''
    setInputValue(inputRef, empty)
    inputRef.current?.focus()
    searchOnClear && onSearch?.(new PlatformEvent(event, { term: empty, transform }))
    setValue(empty)
  })

  useEffect(() => {
    setValue(props.value || '')
  }, [props.value])

  return (
    <Grid
      container
      component={FormControl}
      className={classes.root}
      direction={'column'}
      fullWidth={fullWidth}
      flexWrap={'nowrap'}
    >
      <Input
        {...inputProps}
        ref={forwardedRef}
        inputRef={inputRef}
        placeholder={placeholder || 'Search...'}
        onKeyUp={e => e.key === 'Enter' && search(e)}
        onChange={handleChange}
        value={value}
        className={classes.field}
        classes={{
          input: {
            padding: 0,
          },
        }}
        autoFocus={focused}
        autoComplete={'new-password'}
        startAdornment={
          <>
            {inputProps.startAdornment}
            {!iconEnd && (
              <SearchAdornment
                classes={classes}
                onClick={search}
              />
            )}
          </>
        }
        endAdornment={
          <>
            {inputProps.endAdornment}
            <ClearAdornment
              onClick={clearAndSearch}
              classes={classes}
              size={size === 'medium' ? 20 : 24}
              show={value.length > 0}
            />
            {iconEnd && (
              <SearchAdornment
                classes={classes}
                size={size === 'medium' ? 20 : 24}
                onClick={search}
              />
            )}
          </>
        }
      />
      <FormHelperText
        type={inputProps.error ? 'error' : 'info'}
        className={classes.helperText}
      >
        {helperText}
      </FormHelperText>
    </Grid>
  )
}

export default useMemoRef(SearchField, props => [
  props.value, props.placeholder, props.onSearch, props.transform, props.inputProps,
])
