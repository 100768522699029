import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Intended to display the name of the number of items in a cart.
 *
 * @param {Object} props    element's properties
 * @param {Object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellCartItems = (classes, props = {}) => {
  const { positions = [] } = props
  const totalPositionsAmount = positions.reduce((acc, key) => acc + key.amount, 0)

  return <>
    <Typography
      variant={'14/medium'}
      color={'textDark'}
    >
      {totalPositionsAmount || '-'}
    </Typography>
  </>
}

export default CellHOC(ListItemCellCartItems)
