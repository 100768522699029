/* eslint-disable default-param-last */
/* global React */
import { cloneElement, Suspense, useEffect, useState, useTransition } from 'react'
import { Badge, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { arrayOf, PlatformEvent } from 'lib/util'
import { useMemoRef } from '@platform/react/hook'

/**
 * Returns the TabsGroup component's current theme styles.
 *
 * @param {Object} theme  the application's current theme
 * @returns {Object}      the VerticalStep component's styles
 */
const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  verticalHeader: {
    width: '100%',
    [theme.breakpoints.up('lg')]: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  horizontalHeader: {
    marginBottom: 24,
    minHeight: 25,
  },
  tab: {
    alignItems: 'flex-start',
    padding: [['0.5rem', '1rem']],
    borderRadius: 50,
    color: theme.palette.common.black,
    marginRight: 12,
    '&:last-child': {
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: theme.palette.gray[900],
    },
    '&.Mui-selected': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.gray[950],
    },
    '&.MuiButtonBase-root': {
      minHeight: '10px',
    },
  },
  content: {
    height: '100%',
  },
  badge: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.signal.main,
  },
  ...theme.custom.tabsGroup,
}))

/**
 * Function to render the {@link Tabs}
 *
 * @param {Object[]} children       children to render
 * @param {Object} options          component options
 * @param {Object} options.classes  styles for the component
 * @returns {Promise<[]>}
 * @private
 */
const _headers = (children = [], options = {}) => arrayOf(children)
  .reduce(async (acc, child, index) => {
    const result = await acc
    const { classes, horizontal } = options
    /**
     * Executing {@code onOpen} event handler for each tab
     */
    await child.props?.events?.onOpen?.(null)

    /**
     * Executing {@code getLabel} event handler
     */
    const {
      label,
      badge = false,
      count,
      hidden = false,
    } = await child.props?.events?.getLabel?.(null) || {}

    const tabLabelText = (
      <Typography
        variant={'14/semibold'}
      >
        {label || child.props.label}
      </Typography>
    )

    const tabLabel = badge
      ? <Badge
        classes={{ badge: classes.badge }}
        badgeContent={count}
        variant={!count ? 'dot' : 'standard'}
      >
        {tabLabelText}
      </Badge>
      : tabLabelText

    !hidden && !child.props.hidden && result.push(
      <Tab
        key={index}
        className={classes.tab}
        value={child.props.id || index}
        label={tabLabel}
        style={{
          ...!horizontal && {
            marginBottom: 12,
          },
        }}
      />,
    )
    return result
  }, Promise.resolve([]))

/**
 * Function to render the tabs content.
 *
 * @param {Object[]} children       children to render
 * @param {number} activeTab        index or id of active tab
 * @param {Object} options          component options
 * @param {Object} options.classes  styles for the component
 * @returns {Array}
 * @private
 */
const _content = (children = [], activeTab, options = {}) => {
  const { classes, horizontal, tabWidth } = options
  return arrayOf(children).reduce((acc, child, index) => {
    !child.props.hidden
      && activeTab === (child.props.id || index)
      && acc.push(cloneElement(child, {
        key: index,
        paddingLeft: !horizontal && 2,
        className: classes.content,
        tabWidth,
        xs: horizontal ? 12 : true,
      }))
    return acc
  }, [])
}

/**
 * TabsGroup Component.
 *
 * Designed to represent each direct child with a tab element. Each child (tab) can have an id
 * prop set. A child element is displayed, whenever the {@param props.activeTab} has that child's
 * id as value.
 *
 * @param {Object} forwardedRef             a reference object to the root element
 * @param {boolean} header                  {@code true} to display a tab button for each child. The
 *                                          button's text will be set from a label property in each
 *                                          child's options
 * @param {Object[]} children               the children elements to be set as one tab each
 * @param {number|string} children.id       the value the {@param props.activeTab} must have for
 *                                          this element to be displayed
 * @param {Object[]} [children.events]      events for the tab
 * @param {Object} [events]                 component's event handlers
 * @param {function} [events.onChange]      event handler executed whenever the active tab changes
 * @param {Object} [props]                  additional component's properties
 * @param {number|string} [props.activeTab] the key of the currently active tab's element
 * @constructor
 */
const TabsGroup = ({ forwardedRef, header, children, events, ...props }) => {
  const { tabWidth = '11rem' /* 192px */, horizontal = true } = props
  const theme = useTheme()
  const classes = useStyles()
  const [, startTransition] = useTransition()
  const [activeTab, setActiveTab] = useState(props.activeTab || 0)
  const [headers, setHeaders] = useState([])

  const handleChange = (event, newValue) => {
    startTransition(() => setActiveTab(newValue))
    events?.onChange?.(new PlatformEvent('change', { activeTab: newValue }))
  }

  useEffect(() => {
    startTransition(() => setActiveTab(props.activeTab || 0))
  }, [props.activeTab])

  useEffect(() => {
    (async () => {
      const newHeaders = await _headers(children, { classes, horizontal })
      const [firstHeader] = newHeaders
      setActiveTab(props.activeTab || firstHeader.props.value)
      setHeaders(newHeaders)
    })()
  }, [])

  const isMd = useMediaQuery(t => t.breakpoints.down('lg'))

  /**
   * If {@param header} is true, wait for {@code headers} to be fully fetched,
   * then render content, so that onOpen event handlers get executed before
   * content is rendered.
   *
   * Otherwise, render content immediately.
   */
  return !children.length ? null : (
    <Grid
      item
      container
      ref={forwardedRef}
      className={classes.root}
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
    >
      {header && headers.length > 0 && (
        <Grid
          item
          container
          xs={12}
          {...horizontal || isMd
            ? { sm: 12 }
            : { sx: { width: tabWidth, flexBasis: 'auto!important' } }
          }
        >
          <Tabs
            value={activeTab}
            onChange={handleChange}
            orientation={isMd || horizontal ? 'horizontal' : 'vertical'}
            scrollButtons={'auto'}
            allowScrollButtonsMobile={true}
            variant={'scrollable'}
            className={
              horizontal
                ? classes.horizontalHeader
                : classes.verticalHeader
            }
            TabIndicatorProps={{ hidden: true }}
            sx={{ ...!horizontal && !isMd && { paddingRight: theme.spacing(2) } }}
          >
            {headers}
          </Tabs>
        </Grid>
      )}
      {(!header || headers.length > 0) && (
        <Suspense>
          {_content(children, activeTab, {
            classes,
            horizontal: horizontal || isMd,
            tabWidth,
          })}
        </Suspense>
      )}
    </Grid>
  )
}

export default useMemoRef(TabsGroup, props => [props.children, props.activeTab])
