/* eslint-disable no-unused-vars */
/* global React */
import CellHOC from '@platform/react/hoc/list/cell'
import { useCallback, useRef, useState } from 'react'
import Check from 'ui/Element/Field/Check'
import PlatformEvent from 'lib/util/event'
import { Grid } from '@mui/material'

/**
 * Returns the ListItemCellUserAdminRoleDelete element's current theme styles.
 *
 * @param {Object} theme    the application's current theme
 * @returns {Object}        the ListItemCellUserAdminRoleDelete component's styles
 */
const styles = theme => ({
  root: {
    '& .MuiFormControlLabel-root': {
      margin: 0,
    },
  },
})
/**
 * Displays a button to delete the current users' respective role.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellUserAdminRoleDelete = (classes, props = {}) => {
  const { index, label, events } = props
  const parentRef = useRef(null)
  const [deleted, setDeleted] = useState(false)

  const Field = React.forwardRef(Check)

  const handleDelete = useCallback((e) => {
    const deleteEvent = new PlatformEvent(e, { ...e.detail, index })
    const { checked } = e.detail
    events?.onRoleDelete(deleteEvent)
    setDeleted(checked)
  }, [events?.onRoleDelete])

  return (
    <Grid
      item
      xs={12}
      container
      justifyContent={'flex-end'}
    >
      <Field
        events={{ onChange: handleDelete }}
        label={label}
        checked={deleted || false}
        ref={parentRef}
      />
    </Grid>
  )
}

export default CellHOC(ListItemCellUserAdminRoleDelete, styles)
