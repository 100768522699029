import { Box, Typography, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import SvgIcon from 'ui/Element/Icon/Svg'
import { getThemeColor } from 'lib/util'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = theme => ({
  label: {
    maxWidth: 'calc(100% - 3.125rem)',
  },
  article: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})
/**
 * Displays an article.
 *
 * @param {Object} classes  element's style classes
 * @param {Object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellEquipmentInformationNameArticle = (classes, props = {}) => {
  const theme = useTheme()
  const { label = '-', articleLabel: subLabel, serialLong, info1 } = props

  return (
    <Box className={classes.article}>
      <Box className={classes.label}>
        <OverflowTooltip
          color={'black'}
          variant={'14/bold'}
          classes={{ label: classes.row }}
        >
          {label}
        </OverflowTooltip>
        <OverflowTooltip
          variant={'12/medium'}
          classes={{ label: classes.row }}
        >
          {`${subLabel} - ${serialLong || info1}`}
        </OverflowTooltip>
      </Box>
      <Box>
        <SvgIcon
          variant={'filled'}
          icon={'chevron_right'}
          color={getThemeColor(theme, 'text.secondary')}
        />
      </Box>
    </Box>
  )
}

export default CellHOC(ListItemCellEquipmentInformationNameArticle, styles)
