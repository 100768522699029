import { Box, Typography, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import settings from '@tenant/settings'
import SvgIcon from 'ui/Element/Icon/Svg'

/**
 * Displays the device name.
 *
 * @param {Object} classes  element's style classes
 * @param {Object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellServiceItemName = (classes, props = {}) => {
  const theme = useTheme()
  const status = []

  const {
    name = '-',
    partsAvailable: partsAvailableLabel,
  } = props

  const { partsAvailable } = props.equipment?.value || {}

  !props.equipment && status.push(props.noEquipment)
  !props.product && !settings.suppressProductDevices && status.push(props.noProduct)
  props.notConfirmed && status.push(props.notConfirmedLabel)

  return (
    <>
      <Typography
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {name}
      </Typography>
      {status.map((item, index) => (
        <Typography
          key={index}
          variant={'caption'}
          color={'textWarning'}
          className={classes.row}
        >
          {item}
        </Typography>
      ))}
      {partsAvailable === false && settings.showEquipmentPartsAvailable ? (
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
        }}>
          <SvgIcon
            width={'1rem'}
            height={'1rem'}
            icon={'triangle'}
            variant={'outlined'}
            color={theme.palette.warning.main}
          />
          <Typography
            color={theme.palette.warning.main}
            variant={'12/medium'}
          >
            {partsAvailableLabel}
          </Typography>
        </Box>
      ) : null}
    </>
  )
}

export default CellHOC(ListItemCellServiceItemName)
