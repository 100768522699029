/* global React */
import { Grid } from '@mui/material'
import ErrorBoundary from 'ui/Error'

/**
 * Acl Form Item designed to be displayed as a wrapper for each child of the Acl Form component.
 *
 * @param {Object} child        the form's child to wrap
 * @param {Object} options      additional element properties
 * @returns {null|JSX.Element}
 * @constructor
 */
const AclFormItem = ({ child, options }) => (options.props.hidden ? null : (
  <ErrorBoundary>
    <Grid item
      container
      xs={options.xs || child.props.xs || 10}
      md={options.md || child.props.md || 8}
      xl={options.xl || child.props.xl || 4}
      style={{
        marginBottom: '20px',
      }}
      alignItems={'flex-start'}
    >
      {React.cloneElement(child, options.props)}
    </Grid>
  </ErrorBoundary>
))

export default AclFormItem
