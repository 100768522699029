/* eslint-disable object-curly-newline, */
/* global React */
import { Grid, Icon, ListItem, ListItemIcon } from '@mui/material'
import { useMemoRef } from '@platform/react/hook'

/**
 * Extracts the Grid attributes from props.
 *
 * @param {Object} props  the properties to extract the grid attributes from
 * @returns {Object}      an object containing an object with the grid props,
 *                        the gutterBottom's value and an object with the child props
 */
const filterGridProps = (props) => {
  const { xs, sm, md, lg, xl, marginBottom, ...other } = props
  const gridProps = { xs, sm, md, lg, xl, marginBottom }
  return { gridProps, props: other }
}

/**
 * SimpleListItemChildren element. Returns an array containing the children elements of an item from
 * the list.
 *
 * @param {Object[]} children the children of the item
 * @param {Object} classes    the component's classes
 * @returns {JSX.Element[]}   the children elements of the list's item
 * @constructor
 */
const SimpleListItemChildren = ({ children, classes }) => React.Children.map(children, (item) => {
  const { gridProps, props } = filterGridProps(item.props)
  return (
    <Grid
      {...gridProps}
      item
      className={classes.itemChild}
    >
      {{ ...item, props }}
    </Grid>)
})

/**
 * SimpleListItem element. Returns a list item with its children elements.
 *
 * @param {number|JSX.Element} icon the item's icon
 * @param {Object} classes          the component's style classes
 * @param {string} headingColor     a color to be used for the heading
 * @param {Object[]} children       the component's properties
 * @returns {JSX.Element}           a new SimpleListItem element
 * @constructor
 * @deprecated Its usage can and should be replaced with other existing components.
 */
const SimpleListItem = ({ classes, icon, headingColor, children }) => {
  const listIcon = typeof icon === 'number' ? icon : icon && <Icon>{icon}</Icon>
  return (
    <ListItem
      className={classes.item}
      color={headingColor}
      alignItems={'flex-start'}>

      {listIcon ? (
        <ListItemIcon
          className={classes.itemIconContainer}>
          <div className={classes.itemIcon}>
            {listIcon}
          </div>
        </ListItemIcon>
      ) : null}

      <Grid
        container
        className={classes.itemContainer}>

        {children ? (
          <Grid
            item
            container
            className={classes.itemContent}>
            <SimpleListItemChildren classes={classes}>
              {children}
            </SimpleListItemChildren>
          </Grid>
        ) : null}

      </Grid>

    </ListItem>
  )
}

export default useMemoRef(SimpleListItem, [])
