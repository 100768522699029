import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

/**
 * Custom styles for the note spent time.
 *
 * @return {{root: {overflow: string}}}
 */
const styles = () => ({
  cell: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

/**
 * Displays the {@param props.spentTime} of the note.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellNoteSpentTime = (classes, props = {}) => {
  const { spentTime = 0 } = props
  const h = Math.floor(parseInt(spentTime, 10) / 3600)
  const m = Math.floor((parseInt(spentTime, 10) - h * 3600) / 60) % 60

  return (
    <Box className={classes.cell}>
      <OverflowTooltip
        variant={'16/bold'}
        color={'black.main'}
        component={Typography}
      >
        {`${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}`}
      </OverflowTooltip>
    </Box>
  )
}

export default CellHOC(ListItemCellNoteSpentTime, styles)
