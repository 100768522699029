/* eslint-disable no-param-reassign */
/* global React */
import { useState, useEffect, Children, forwardRef, useRef } from 'react'
import { Grid, Icon, Step, StepButton, StepContent, StepLabel, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useMemoRef } from '@platform/react/hook'
import Section from 'ui/Component/Grid/Section'
import Button from 'ui/Element/Button/Simple'
import ErrorBoundary from 'ui/Error'

const stepIconConfirmedStyles = {
  width: '1.5em',
  height: '1.5em',
  borderRadius: '50%',
  fontSize: '1rem',
  lineHeight: '1.5rem',
}

const ForwardedRefSection = forwardRef(Section)

/**
 * Returns the VerticalStep component's current theme styles.
 *
 * @param {Object} theme  the application's current theme
 * @returns {Object}      the VerticalStep component's styles
 */
const useStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'space-between',
  },
  stepLabel: {
    maxWidth: '100%',
    padding: 0,
  },
  stepIconConfirmed: {
    ...stepIconConfirmedStyles,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.warning.main,
  },
  stepIconConfirmedError: {
    ...stepIconConfirmedStyles,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.error.main,
  },
  button: {
    width: 'auto',
    flex: [[0, 0, 'auto']],
    marginLeft: -8,
    maxWidth: '100%',
  },
  buttonLabel: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',

    '& > *': {
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      textAlign: 'justify',
      lineClamp: 3,
      display: '-webkit-box',
      boxOrient: 'vertical',
      // whiteSpace: 'nowrap',
    },
  },
  stepContent: {
    width: ['100%', '!important'],
    maxWidth: '100%',
    borderColor: theme.palette.divider,
  },
  contentContainer: {
    maxHeight: '100%',
    margin: [[24, 0, 8]],
    maxWidth: '100%',
    width: ['100%', '!important'],
  },
  ...theme.custom.verticalStep,
}))

/**
 * Wraps the step {@param actions}.
 *
 * @param {Object[]} actions  a list of actions to show for the step
 * @returns {JSX.Element}
 * @constructor
 */
const StepActions = ({ actions = [] }) => (
  <Grid
    item
    container
    component={'span'}
    justifyContent={'flex-end'}
    columnSpacing={2}
    xs={'auto'}
  >
    {actions
      .filter(action => !action.props.hidden)
      .map((action, index) => (
        <Grid
          key={index}
          item
        >
          {action}
        </Grid>
      ))
    }
  </Grid>
)

/**
 * Step skip button element.
 *
 * @param {boolean} hidden      whether to hide or display the button
 * @param {string} value        button label's text
 * @param onClick               a function to be called when the button is clicked
 * @returns {null|JSX.Element}
 * @constructor
 */
const StepSkipButton = ({ hidden, value, onClick }) => (hidden ? null : (
  <Grid
    item
    component={'span'}
    justifyContent={'right'}
    sx={{ marginLeft: 'auto' }}
    xs={'auto'}
  >
    <Button
      variant={'text'}
      onClick={onClick}
      fullWidth={false}
      color={'primary'}
      value={value}
      startIcon={'next_plan_sharp'}
      xs={'auto'}
    />
  </Grid>
))

/**
 * Step component designed to be used as child for the {@link VerticalStep} and as a wrapper
 * for other elements.
 *
 * @param {Object} forwardedRef       a reference object to the root element
 * @param {number} [activeStep]       the index of the currently active step
 * @param {function} onClick          a function to be executed whenever the step label is clicked
 * @param {string} label              a string to be used as step label
 * @param {boolean} required          whether the step has to be completed
 * @param {Object[]} [actions]
 * @param {Object[]} children         an array of elements to be used as the step content
 * @param {boolean} visited           whether the step has already been visited
 * @param {string} [stepTitle]        a string to be used as step title
 * @param {string} [errorTitle]       a string to be used as step title when it is in error state
 * @param {string} [errorSubtitle]    a string to be used as step subtitle when it is in error state
 * @param {boolean} [confirmed]       whether the step has confirmed state
 * @param {string} [skipButtonText]   text to be displayed as skip button's label
 * @param {number} [skipTo]           step to go to if this one is skipped
 * @param {boolean} skipped           whether or not this step has been skipped
 * @param {string} [skipTitle]        title to be displayed if step is skipped
 * @param {Object} [props]            additional component's properties
 * @param {boolean} [props.index]     the step's position in the stepper
 * @param {boolean} [props.completed] whether the step has completed state
 * @param {string} [props.subtitle]   a string to be used as step subtitle
 * @param {string[]} [props.classes]  component's style definitions
 * @param {number} [props.spacing]    the number of spacings between the contained items
 * @param {number} [props.space]      the number of spacings above and below the contained items
 * @param {number} [props.gap]        the number of spacings at each side of the contained items
 * @returns {JSX.Element}
 * @constructor
 */
const VerticalStep = ({ forwardedRef, activeStep, onClick, label, actions, children, required,
  visited, confirmed, skipped, title: stepTitle, subtitle: stepSubtitle,
  error: { title: errorTitle, subtitle: errorSubtitle } = {}, skipTitle, skipButtonText, skipTo,
  gap, space, spacing, ...props }) => {
  const classes = useStyles()
  const sectionRef = useRef()

  const [child] = Children.toArray(children)

  const current = activeStep === props.index
  const [isVisited, setVisited] = useState(current || visited)
  const error = !props.completed && required && isVisited && !current
  const confirmError = props.completed && !confirmed && isVisited && !current
  const title = stepTitle || (error && errorTitle) || (skipped && skipTitle) || ''
  const subtitle = stepSubtitle || (!stepTitle && error && errorSubtitle) || ''

  useEffect(() => {
    setVisited(isVisited || visited)
  }, [visited])

  return (
    <Grid
      {...props}
      ref={forwardedRef}
      key={label}
      item
      container
      component={Step}
      justifyContent={'space-between'}
      classes={{ root: classes.root }}
      xs={child.props.stepProps?.xs || props.xs}
      sm={child.props.stepProps?.sm || props.sm}
      md={child.props.stepProps?.md || props.md}
      lg={child.props.stepProps?.lg || props.lg}
      xl={child.props.stepProps?.xl || props.xl}
    >
      <Grid
        item
        container
        justifyContent={'space-between'}
      >
        <Grid
          item
          component={StepButton}
          className={classes.button}
          onClick={onClick(props.index)}
          optional={!title || current ? null : (
            <>
              <Typography
                variant={'caption'}
                color={error ? 'error' : 'body2'}
              >
                {title}
              </Typography>
              <Typography
                variant={'caption'}
                color={error ? 'error' : 'body2'}
              >
                {subtitle}
              </Typography>
            </>
          )}
        >
          <StepLabel
            error={error}
            StepIconProps={{
              ...props.completed && !confirmed && !confirmError && {
                icon: <Icon className={classes.stepIconConfirmed}>edit</Icon>,
              },
              ...props.completed && !confirmed && confirmError && {
                icon: <Icon className={classes.stepIconConfirmedError}>edit</Icon>,
              },
            }}
            classes={{
              root: classes.stepLabel,
              labelContainer: classes.buttonLabel,
            }}
          >
            {label}
          </StepLabel>
        </Grid>
        <Grid
          item
          container
          xs={'auto'}
          justifyContent={'flex-end'}
          columnSpacing={2}
        >
          {current && (
            <StepActions
              actions={actions}
            />
          )}
          <Grid
            item
            component={StepSkipButton}
            value={skipButtonText}
            onClick={onClick(skipTo || props.index + 1, true)}
            hidden={required || !current || props.completed}
          />
        </Grid>
      </Grid>
      <Grid
        component={StepContent}
        className={classes.stepContent}
      >
        <ErrorBoundary>
          <ForwardedRefSection
            ref={sectionRef}
            spacing={spacing}
            gap={gap}
            space={space}
            classes={props.classes}
            className={classes.contentContainer}
          >
            {children}
          </ForwardedRefSection>
        </ErrorBoundary>
      </Grid>
    </Grid>
  )
}

export default useMemoRef(VerticalStep, false)
