import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import ApplicationContext from '@platform/react/context/application'
import { useContext } from 'react'

/**
 * Intended to display a serviceItem's installedAt property.
 *
 * @param {Object} classes  element's style classes
 * @param {Object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellServiceItemInstalledAt = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)

  const {
    simple = false,
    installedAt: {
      value: {
        name = '-',
        address = '',
      } = {},
    } = {},
  } = props

  const addressSummary = intl.address(address, { inline: true })

  return (
    <>
      <Typography
        title={name}
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: 700 }}
      >
        {name}
      </Typography>
      {simple ? null : (
        <Typography
          variant={'caption'}
          title={addressSummary}
          className={classes.row}
          sx={theme => ({ color: theme.palette.text.disabled })}
        >
          {addressSummary}
        </Typography>
      )}
    </>
  )
}

export default CellHOC(ListItemCellServiceItemInstalledAt)
