/* global React */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Paper, Icon, Typography } from '@mui/material'
import { useStyles, useMemoRef } from '@platform/react/hook'

/**
 * FabLike Styles
 *
 * the styles were copied from node_modules/@mui/material/Fab/Fab.js
 * be wary if MUI gets updated, and styles don't fit
 *
 * @param theme - mui theme
 * @return {Object} styles - themed styles
 */
export const styles = theme => ({
  root: {
    ...theme.typography.button,
    width: 48,
    height: 48,
    boxSizing: 'border-box',
    minHeight: 36,
    transition: theme.transitions.create(['background-color', 'box-shadow', 'border'], {
      duration: theme.transitions.duration.short,
    }),
    borderRadius: '50%',
    padding: 0,
    minWidth: 0,
    // width: 56,
    // height: 56,
    // boxShadow: theme.shadows[6],
    '&:active': {
      // boxShadow: theme.shadows[12],
    },
    // color: theme.palette.getContrastText(theme.palette.grey[300]),
    // backgroundColor: theme.palette.grey[300],
    // color: theme.palette.getContrastText(theme.palette.primary[50]),
    backgroundColor: theme.palette.primary[50],
    // '&$focusVisible': {
    //   // boxShadow: theme.shadows[6],
    // },
    '&:hover': {
      // backgroundColor: theme.palette.grey.A100,

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: theme.palette.grey[300],
      },
      // '&$disabled': {
      //   backgroundColor: theme.palette.action.disabledBackground,
      // },
      textDecoration: 'none',
    },
    // '&$disabled': {
    //   color: theme.palette.action.disabled,
    //   boxShadow: theme.shadows[0],
    //   backgroundColor: theme.palette.action.disabledBackground,
    // },

    // GAIA
    display: 'inline-flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
  },
  ...theme.custom.fabLikeIcon,
})

const FabLikeIcon = ({ forwardedRef, icon, className, variant, color }) => {
  const classes = useStyles(styles)()
  return (
    <Paper
      className={className}
      elevation={0}
      component={'span'}
      disabled={true}
      ref={forwardedRef}
    >
      <Typography
        variant={variant || 'h4'}
        className={classes.iconContainer}
      >
        <Icon
          color={color || 'primary'}
          fontSize={'inherit'}
        >
          {icon}
        </Icon>
      </Typography>
    </Paper>
  )
}

export default useMemoRef(FabLikeIcon, [])
