import { Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = theme => ({
  label: {
    maxWidth: 'calc(100%)',
  },
})

/**
 * Displays a description.
 *
 * @param {Object} classes  element's style classes
 * @param {Object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellFavoriteDescription = (classes, props = {}) => {
  const theme = useTheme()
  const { description } = props

  return (
    <Box className={classes.label}>
      <OverflowTooltip
        variant={'14/medium'}
        classes={{ label: classes.row }}
        color={theme.palette.black.main}
      >
        {description || '-'}
      </OverflowTooltip>
    </Box>
  )
}

export default CellHOC(ListItemCellFavoriteDescription, styles)
