/* eslint-disable object-curly-newline,no-unused-vars */
/* global React */
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Box, Grid, Icon, Typography } from '@mui/material'
import { debounce, isArr, isNum, PlatformEvent } from 'lib/util'
import { useMemoRef, useStyles } from '@platform/react/hook'
import stringFormat from '@gaia/util/string'
import { format, parseISO } from 'date-fns'
import DecoratedText from 'ui/Element/Text/Decorated'
import GaiaImageList from 'ui/Component/Attachment/List'
import MuiListTemplateSmall from 'ui/Component/Attachment/List/Template/Small'
import MuiListTemplateLine from 'ui/Component/Attachment/List/Template/Line'
import fileConfig from 'config/web/ui/_shared/file.json5'
import GaiaImageCount from 'ui/Component/Attachment/Count'
import ApplicationContext from '@platform/react/context/application'
import Button from 'ui/Element/Button/Simple'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.gray.main,
    borderRadius: 4,

    '& #delete-button, & #edit-button': {
      display: 'none',
    },
    '&:hover #delete-button, &:hover #edit-button': {
      display: 'block',
    },
  },
  header: {
    flexWrap: 'nowrap',
    padding: theme.spacing(1, 2.5, 0, 2.5),
  },
  editIcon: {
    color: [[theme.palette.text.secondary], '!important'],
  },
  deleteIcon: {
    color: [[theme.palette.text.secondary], '!important'],
  },
  content: {
    padding: theme.spacing(0, 2.5, 2),
  },
  author: {
    minHeight: 56,
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    margin: theme.spacing(1, 0, 2, 0),
    textAlign: 'left',
    wordBreak: 'break-word',
  },
  type: {
    fontWeight: 500,
    textTransform: 'uppercase',
    fontSize: '.9rem',
  },
  footer: {
    alignItems: 'center',
  },
  attachIcon: {
    color: [[theme.palette.text.secondary], '!important'],
  },
  attachments: {
    padding: theme.spacing(2, 2, 0),
  },
  timeLabel: {
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(1, 2),
  },
  timeTrackingLabel: {
    display: 'flex',
    justifyContent: 'flext-start',
    alignItems: 'center',
  },
  time: {
    color: theme.palette.text.light,
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  date: {
    color: theme.palette.text.light,
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'right',
  },
  ...theme.custom.note,
})

const AuthorLabel = ({ value, classes, ...props }) => (
  <Grid
    key={'author'}
    {...props}
    item
    variant={'16/medium'}
    component={Typography}
    color={'textSecondary'}
    className={classes.author}
  >
    {value}
  </Grid>
)

const TextLabel = ({ value, classes, hidden, ...props }) => (hidden ? null : (
  <Grid
    {...props}
    item
    component={DecoratedText}
    className={classes.text}
    color={'textPrimary'}
  >
    {value}
  </Grid>
))

const TypeLabel = ({ value, assignee, team, date, decorators, classes, ...props }) => (
  <Grid
    {...props}
    key={'type'}
    item
    container
    alignItems={'center'}
  >
    <DecoratedText
      className={classes.type}
      decorators={decorators}
    >
      {stringFormat(value, { assignee, team, date })}
    </DecoratedText>
  </Grid>
)

const Attachments = ({ data, classes, hidden, download = true, ...props }) => (hidden ? null : (
  <Grid
    {...props}
    item
    key={'attachments'}
    className={classes.attachments}
  >
    <GaiaImageCount
      space={1}
      data={data}
      autoHide={true}
      variant={'caption'}
      color={'textSecondary'}
      decorators={{ bold: true }}
      filter={{ type: ['!image'] }}
      label={'ATTACHED FILES ({count})'}
    />
    <GaiaImageList
      space={2}
      data={data}
      download={download}
      types={fileConfig.types}
      filter={{ type: ['!image'] }}
      ListItem={MuiListTemplateLine}
      events={{ onClick: props?.events?.onClick }}
    />
    <GaiaImageCount
      space={1}
      data={data}
      autoHide={true}
      variant={'caption'}
      color={'textSecondary'}
      decorators={{ bold: true }}
      filter={{ type: ['image'] }}
      label={'ATTACHED IMAGES ({count})'}
    />
    <GaiaImageList
      space={2}
      data={data}
      download={download}
      types={fileConfig.types}
      filter={{ type: ['image'] }}
      ListItem={MuiListTemplateSmall}
      events={{ onClick: props?.events?.onClick }}
    />
  </Grid>
))

const DateTimeLabel = ({ intl, spentTime, submitTimestamp, startTimestamp, classes, ...props }) => {
  const h = Math.floor(parseInt(spentTime, 10) / 3600)
  const m = Math.floor((parseInt(spentTime, 10) - h * 3600) / 60) % 60

  const submitDate = submitTimestamp ? intl.getLocaleDate(submitTimestamp, {
    dateStyle: 'medium',
    timeStyle: 'short',
  }) : null

  const startDate = startTimestamp ? intl.getLocaleDate(startTimestamp, {
    dateStyle: 'medium',
  }) : null

  return (
    <Box className={classes.timeLabel}>
      {isNum(spentTime) && (
        <Box className={classes.timeTrackingLabel}>
          <Icon sx={{ marginRight: '5px' }}>
              restore
          </Icon>
          <Grid
            {...props}
            item
            key={'spentTime'}
            component={DecoratedText}
            className={classes.time}
          >
            {`${String(h).padStart(2, '0')}:${String(m).padStart(2, '0')}`}
          </Grid>
        </Box>
      )}
      <Grid
        {...props}
        item
        key={'dateTime'}
        component={DecoratedText}
        className={classes.date}
      >
        {startDate || submitDate}
      </Grid>
    </Box>
  )
}

/**
 * Note Template.
 *
 * Used to display existing notes.
 *
 * @param {Object} note                     the note object to display
 * @param {Object} types                    the available types of notes
 * @param {string} userId                   the id of the current user
 * @param {string} meLabel                  translation of english 'me'
 * @param {Object} events                   an object containing event handlers
 * @param {Function} events.getNoteType     called at the beginning to alternatively obtain the
 *                                          `note`'s type
 * @param {Function} events.onAttach        called whenever the attach button is clicked
 * @param {Function} events.onDelete        called whenever the delete button is clicked
 * @param {Function} events.onChange        called whenever the edit button is clicked
 * @param {Object} props                    additional props
 * @param {boolean} [props.download=false]  whether note attachments can be downloaded
 * @param {boolean} [props.meLabel]         label for 'Me'
 * @param {boolean} [props.unknownLabel]    label for 'Unknown'
 * @returns {JSX.Element}
 * @constructor
 */
const Note = ({ note, types, userId, events, ...props }) => {
  const { download, meLabel, unknownLabel } = props

  const { intl } = useContext(ApplicationContext)
  const hoveredState = useRef(false)
  const [isHovered, setIsHovered] = useState(false)

  const { getNoteType, onDelete, onChange } = events
  const { value, refs } = note
  const {
    text,
    spentTime = null,
    startDate = null,
    submitTimestamp,
    type: typeKey,
    date,
    attachments = [],
  } = value

  const classes = useStyles(styles)()

  const {
    submitter: [submitter] = [],
    assignee: [assignee] = [],
    team: [team] = [],
  } = refs || {}
  const {
    person: [submitterPerson] = [],
  } = (submitter && submitter.refs) || {}
  const {
    person: [assigneePerson] = [],
  } = (assignee && assignee.refs) || {}

  const sPerson = submitterPerson?.value
  const submitterRef = submitter?.key
  const submitterName = (sPerson?.firstName && sPerson?.lastName && `${sPerson.firstName} ${sPerson.lastName}`)
    || (sPerson?.name && sPerson.name)
    || unknownLabel

  const authorName = submitterRef === userId ? meLabel : submitterName

  const aPerson = assigneePerson?.value
  const assigneeName = (aPerson?.firstName && aPerson?.lastName && `${aPerson.firstName} ${aPerson.lastName}`)
      || (aPerson?.name && aPerson.name)
      || unknownLabel

  const teamName = team?.value?.name || unknownLabel
  const dateText = date ? format(parseISO(date), 'd.MM.yyyy, HH:mm') : unknownLabel

  const [noteType = types ? types[typeKey] : {}] = getNoteType?.(typeKey) || []
  const decorators = {
    [noteType.color]: true,
    uppercase: true,
    bold: true,
  }

  const canEdit = note.acl && isArr(note.acl)
    ? note.acl.includes(30)
    : false

  const canDelete = note.acl && isArr(note.acl)
    ? note.acl.includes(40)
    : false

  const hasOptions = canEdit || canDelete
  const handleEdit = useCallback((e) => {
    onChange?.(new PlatformEvent(e, { note }))
  }, [events?.onChange, note])

  const handleDelete = useCallback((e) => {
    onDelete?.(new PlatformEvent(e, { note }))
  }, [events?.onDelete, note])

  const setHovered = useCallback(() => {
    hoveredState?.current
      ? setIsHovered(true)
      : setIsHovered(false)
  }, [])
  const debouncedSetHovered = debounce(setHovered)

  const handleHovered = useCallback((e) => {
    if (e.type === 'mouseenter') hoveredState.current = true
    if (e.type === 'mouseleave') hoveredState.current = false

    debouncedSetHovered()
  }, [])

  return (
    <Grid
      className={classes.root}
      onMouseEnter={handleHovered}
      onMouseLeave={handleHovered}
      container
      item
    >
      <Grid
        key={'header'}
        className={classes.header}
        container
        item
        xs={12}
      >
        <AuthorLabel
          value={authorName}
          classes={classes}
          xs={true}
        />
        {(hasOptions && isHovered) && (
          <Grid
            item
            container
            xs={'auto'}
            alignItems={'center'}
            justifyContent={'flex-end'}
            gap={1}
          >
            {canEdit && (
              <Button
                onClick={handleEdit}
                fullWidth={false}
                variant={'contained'}
                color={'white'}
                size={'small'}
                icon={'edit'}
                iconProps={{ variant: 'outlined' }}
              />
            )}
            {canDelete && (
              <Button
                onClick={handleDelete}
                fullWidth={false}
                variant={'contained'}
                color={'white'}
                size={'small'}
                icon={'trash'}
                iconProps={{ variant: 'outlined' }}
              />
            )}
          </Grid>
        )}
      </Grid>
      <Grid
        key={'content'}
        className={classes.content}
        container
        item
        xs={12}
      >
        <TextLabel
          value={text}
          hidden={!text}
          classes={classes}
          xs={12}
        />
        {noteType.key !== 'blank' && (
          <TypeLabel
            value={noteType.value}
            team={teamName}
            assignee={assigneeName}
            date={dateText}
            decorators={decorators}
            classes={classes}
            xs={12}
          />
        )}
      </Grid>
      <Grid
        key={'footer'}
        className={classes.footer}
        container
        item
      >
        <Attachments
          xs={12}
          download={download}
          events={events}
          data={attachments}
          classes={classes}
          hidden={!attachments?.length}
        />
        <DateTimeLabel
          intl={intl}
          spentTime={spentTime}
          startTimestamp={startDate}
          submitTimestamp={submitTimestamp}
          classes={classes}
          xs={12}
        />
      </Grid>
    </Grid>
  )
}

export default useMemoRef(Note, props => [props.note, props.note?.value])
