import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { isNum } from 'lib/util'

/**
 * Returns the ListItemCellUserStatus element's current theme styles.
 *
 * @returns {Object}      the ListItemCellNameOrCount element's styles
 */
export const styles = (theme, { right }) => right && {
  root: {
    textAlign: 'right',
  },
}
/**
 * Displays the full name of the user's person.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellUserStatus = (classes, props = {}) => {
  const { person, events } = props

  // eslint-disable-next-line no-unsafe-optional-chaining
  const [status] = isNum(person?.value?.status)
    ? events?.getStatus?.(person.value.status) || []
    : []

  return (
    <Typography
      color={'black'}
      variant={'body2'}
      className={classes.row}
      sx={{ fontWeight: 700 }}
    >
      {status?.value || '-'}
    </Typography>
  )
}

export default CellHOC(ListItemCellUserStatus, styles)
