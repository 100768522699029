import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import internal from 'model/organisation/collection/internal'

/**
 * Styles
 *
 * @param {Object} theme
 * @return {{verified: {color}, invited: {color}}}
 */
const styles = theme => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  verified: {
    color: theme.palette.info.main,
  },
  invited: {
    color: theme.palette.signal.main,
  },
  subRow: {
    gap: '5px',
    display: 'flex',
    alignItems: 'center',
  },
})

/**
 * Shows the type (either child or parent) of the organisation
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellOrganisationChildOrParent = (classes, props = {}) => {
  const {
    parent: {
      key: parentId = null,
    } = {},
    childLabel,
    parentLabel,
  } = props

  const isParent = !parentId || internal.some(x => x.key === parentId)

  return <>
    <Typography
      variant={'body2'}
      className={classes.row}
    >
      {isParent ? parentLabel : childLabel}
    </Typography>
  </>
}

export default CellHOC(ListItemCellOrganisationChildOrParent, styles)
