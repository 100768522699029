import PersonEmail from 'ui/Element/List/Item/Cell/Person/Labeled/Email'
import RequestRecipient from 'ui/Element/List/Item/Cell/Request/Labeled/Recipient'
import ServiceItemSerial from 'ui/Element/List/Item/Cell/ServiceItem/Labeled/Serial'

/**
 * Displays the appropriate list item based on the incoming {@code type}.
 *
 * @param {object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellOrganisationMultiIcon = (props) => {
  const { docType } = props

  return (
    <>
      {docType === 'person' && (<PersonEmail {...props } labels={props.personLabels} />)}
      {docType === 'request' && (<RequestRecipient {...props } labels={props.requestLabels} />)}
      {docType === 'serviceitem' && (<ServiceItemSerial {...props } labels={props.serviceItemLabels} />)}
    </>
  )
}

export default ListItemCellOrganisationMultiIcon
