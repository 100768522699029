/* eslint-disable object-curly-newline, arrow-body-style, import/no-extraneous-dependencies */
import { cloneElement } from 'react'
import { Grid } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { getThemeColor, setKey } from 'lib/util'
import Text from '@ui/Element/Text/Simple'

/**
 * Returns a copy of the string passed as first parameter with the first letter in capital form.
 *
 * @param {string} first    the first character of the string
 * @param {string[]} rest   the rest of the string
 * @param {string} locale   the locale used to convert the first part of the string to uppercase
 * @return {string}
 */
export const capitalizeFirstLetter = ([first, ...rest], locale = navigator.language) => first
  .toLocaleUpperCase(locale) + rest.join('')

/**
 * Creates a copy of {@param Component} with props {@param props} through React's
 * {@link cloneElement}.
 *
 * @param {React.ReactHTMLElement} Component  the component to clone
 * @return {function(*): React.DetailedReactHTMLElement<*, HTMLElement>}
 */
const clone = Component => props => cloneElement(Component, { ...props, ...Component.props })

/**
 * Traverses the {@param children} array in search of 'type' properties. Returns an object the name
 * of whose properties is the value of 'type' for each object and its value is the object itself.
 *
 * @param {Object[]} children the children to map
 * @return {Object}
 */
const childrenTypesObject = children => children
  .filter(child => child.props.type)
  .reduce((types, child) => {
    setKey(clone(child), capitalizeFirstLetter(child.props.type), types)
    return types
  }, {})

/**
 * Returns the LabeledText element's current theme styles.
 *
 * @param {Object} theme        the application's current theme
 * @param {String} [background] optional background color of the paper component
 * @param {String} [color]      optional text color for the label
 *
 * @returns {Object}            the LabeledText component's styles
 */
const styles = (theme, { background, color }) => ({
  root: {
    background: background && `${background}!important`,
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 500,
    color: color && getThemeColor(theme, color),
  },
  value: {
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  center: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  medium: {
    fontSize: theme.typography.pxToRem(theme.typography.fontSize),
  },
  error: {
    color: theme.palette.error.main,
  },
  ...theme.custom.labeledText,
})

/**
 * LabeledText UI element.
 *
 * Two {@link Text} elements, representing a pair Label - Value.
 *
 * @param {Object} forwardedRef       forwarded reference to the root element
 * @param {string} value              the string to set as value
 * @param {string} label              the string to set as label
 * @param {Object} children           element's contents
 * @param {Object} props              additional props
 * @param {String} [props.color]      optional color for the label. Default is {@code secondary}
 * @param {String} [props.background] optional background color for the label
 * @param {String} [props.decorators] optional object whose properties have names of styles to apply
 * @param {Object} [props.classes]    classes to apply to the component
 * @param {Object} [props.autoHide]   whether the element should not be displayed if it has no value
 * @param {boolean} [props.hidden]    whether the element should not be displayed
 * @returns {JSX.Element}
 * @constructor
 */
const LabeledText = ({ forwardedRef, value, label, children, ...props }) => {
  const { color, background, decorators, autoHide = true } = props
  const { Label = Text, Value = Text } = childrenTypesObject(children)
  const classes = { ...useStyles(styles, { background, color })(), ...props.classes }

  const _decorate = (x, classList) => {
    Object.keys(x).reduce((acc, key) => {
      const decorator = x[key]
      return decorator ? acc.push(classes[key]) && acc : acc
    }, classList)
  }

  // filling classNames by mapping decorators to css properties
  const labelClasses = []
  const valueClasses = []
  _decorate(decorators?.label || {}, labelClasses)
  _decorate(decorators?.value || {}, valueClasses)

  return (!value && autoHide) || props.hidden ? null : (
    <Grid
      ref={forwardedRef}
      className={classes.root}
      item
    >
      <Label
        className={`${classes.label} ${labelClasses}`}
        value={label}
      />
      <Value
        className={`${classes.value} ${valueClasses}`}
        value={value}
      />
    </Grid>
  )
}

export default useMemoRef(LabeledText, props => [props.value, props.children])
