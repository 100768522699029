/* global G */
/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { Divider } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import ApplicationContext from '@platform/react/context/application'
import { useContext } from 'react'

const styles = (theme, { children, variant, uppercase, align, isDrawer }) => ({
  root: {
    ...variant === 'fullWidth' && {
      [theme.breakpoints.up('xs')]: {
        width: '100%',
      },
      [theme.breakpoints.up('md')]: {
        width: isDrawer ? theme.layout.drawerWidth : '100%',
      },
    },
    ...variant === 'middle' && { height: '100%' },

    '& span': {
      fontWeight: 700,
      color: theme.palette.common.black,
      ...uppercase && { textTransform: 'uppercase' },
      ...children && {
        display: 'flex',
        gap: 10,
      },
    },
    '&::before': {
      // '8px * 1.2' is the internal padding set by MUI, we substract it from the drawer
      // padding. That way the edge of the label lines up with the input fields
      ...align === 'left' && isDrawer && {
        width: `calc(${theme.layout.drawerPadding} - 8px * 1.2)`,
      },
    },
    '&::after': {
      ...align === 'right' && isDrawer && {
        width: `calc(${theme.layout.drawerPadding} - 8px * 1.2)`,
      },
    },
    ...isDrawer && {
      marginLeft: `-${theme.layout.drawerPadding}`,
      marginRight: `-${theme.layout.drawerPadding}`,
    },
    marginTop: '10px',
  },
  ...theme.custom.divider,
})

/**
 * Divider UI element
 *
 * Simple divider that takes a {@param value} as a label, or optionally {@param children} instead.
 * (Like a {@link Chip} for example). Can be used in horizontal and vertical positioning.
 * Label can be display left, right and center
 *
 * @param {Object} forwardedRef a reference to the root element
 * @param {string} [value]      a string to be used as value
 * @param {any} [children]      a list of children elements to be used as content
 * @returns {JSX.Element}       the new Text element
 * @constructor
 */
const SectionDivider = ({ forwardedRef, label, children, ...props }) => {
  const {
    variant,
    orientation,
    flexItem,
    light = false,
    align = 'left',
    uppercase = false,
  } = props

  const { session: { [G.MODULE]: currentModule } } = useContext(ApplicationContext)
  const isDrawer = currentModule[G.STATE][G.ACTION][G.UI]?.drawer

  const classes = useStyles(styles, { children, variant, uppercase, align, isDrawer })()

  return (
    <Divider
      light={light}
      variant={variant}
      textAlign={align}
      ref={forwardedRef}
      flexItem={flexItem}
      className={classes.root}
      orientation={orientation}
    >
      {label}
      {children}
    </Divider>
  )
}

export default useMemoRef(SectionDivider, props => [props.label, props.children])
