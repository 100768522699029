/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { useMemoRef, useStyles } from '@platform/react/hook'
import { Grid, Icon, Paper } from '@mui/material'
import Text from 'ui/Element/Text/Simple'

const styles = theme => ({
  root: {
    padding: '0.8rem 0.5rem',
    borderRadius: '10px',
    border: '1px solid rgb(0 0 0 / 5%)',
    boxShadow: '0px 4px 6px 0px rgb(0 0 0 / 5%)',
  },
  icon: {},
  text: {
    margin: 0,
    fontWeight: 500,
  },
  title: {
    margin: 0,
    fontWeight: 500,
  },
  ...theme.custom.bannerIconText,
})

/**
 * Text UI Element.
 * Simple element used to display dynamic text, which can also be given variant, color, className
 * and children properties.
 *
 * @param {Object} forwardedRef a reference to the root element
 * @param {string} [value]      a string to be used as value
 * @param {string} [title]
 * @param {string} [icon]
 * @returns {JSX.Element}       the new Text element
 * @constructor
 */
const BannerIconText = ({ forwardedRef, value, title, icon }) => {
  const classes = useStyles(styles)()
  return (
    <Grid
      ref={forwardedRef}
      component={Paper}
      className={classes.root}
      container
      item
    >
      <Grid
        ref={forwardedRef}
        alignItems={'center'}
        justifyContent={'center'}
        container
        item
        xs={3}
        sm={3}
        md={3}
        lg={3}
        xl={3}
      >
        <Icon
          className={classes.icon}
          fontSize={'large'}
          color={'primary'}
        >
          {icon}
        </Icon>
      </Grid>
      <Grid
        direction={'column'}
        container
        item
        xs={9}
        sm={9}
        md={9}
        lg={9}
        xl={9}
      >
        <Text
          className={classes.text}
          variant={'h5'}
        >
          {value}
        </Text>
        <Text
          className={classes.title}
          variant={'caption'}
        >
          {title.toUpperCase()}
        </Text>
      </Grid>
    </Grid>
  )
}

export default useMemoRef(BannerIconText, props =>
  [props.value, props.color, props.variant, props.className])
