/* global React */
import { Box, Grid, lighten } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import Text from 'ui/Element/Text/Simple'
import { capitalizeFirstLetter } from '@platform/react/hoc/children'
import IconSvg from 'ui/Element/Icon/Svg'
import DecoratedLink from 'ui/Element/Button/DecoratedLink'

/**
 * Returns the NoRequest element's current theme styles.
 *
 * @param {Object} theme  the application's current theme
 * @returns {Object}      the Date element's styles
 */
const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(10),
  },
  label: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '16px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '80px',
    height: '80px',
    backgroundColor: lighten(theme.palette.primary.main, 0.9),
    borderRadius: '50%',
    marginBottom: '24px',
  },
  channel: {
    display: 'flex',
  },
  channelType: {
    fontWeight: 400,
    whiteSpace: 'nowrap!important',
    marginRight: '10px',
  },
  channelValue: {
    color: theme.palette.primary.main,
  },
  ...theme.custom.noRequest,
})

/**
 * Creating an entry for each contact channel to display.
 *
 * @param {Object} classes        styles
 * @param {Object} prefixes       prefixes to use for linking the contact channels
 * @param {Object} channelLabels  translated contact channels
 * @param {Object} decorators     decorators for the contact channel links
 * @param {Object[]} channels     contact channels
 * @return {*}
 * @private
 */
const _contactChannels = ({
  classes,
  prefixes,
  decorators,
  channelLabels,
  channels,
}) => channels.map((channel, index) => {
  const channelType = `${channelLabels.find(x => x.key === channel.type)?.value}:`
      || `${capitalizeFirstLetter(channel.type)}:`

  return (
    <Box
      key={index}
      className={classes.channel}
    >
      <Text
        variant={'subtitle2'}
        className={classes.channelType}
      >
        {channelType}
      </Text>
      <DecoratedLink
        decorators={decorators}
        prefix={prefixes[channel.type]}
        value={channel.value}
      />
    </Box>
  )
})

/**
 * No Request Item.
 *
 * Item used to display in case there is no request for the current ticket.
 *
 * @param {Object} content        information about the requester
 * @param {Object} prefixes       prefixes to use for linking the contact channels
 * @param {String} label          label to display to the user
 * @param {Object} channelLabels  translated contact channels
 * @param {Object} decorators     decorators for the contact channel links
 *
 * @returns {JSX.Element}         the new NoRequest element
 * @constructor
 */
const NoRequest = ({ content, prefixes, label, channelLabels, decorators }) => {
  const classes = useStyles(styles)()

  return (
    <Grid
      item
      className={classes.root}
    >
      <Box className={classes.iconContainer}>
        <IconSvg
          width={40}
          height={40}
          raw={false}
          icon={'phone'}
          variant={'filled'}
          color={'primary.main'}
        />
      </Box>
      <Text
        variant={'subtitle1'}
        className={classes.label}
      >
        {label}
      </Text>
      {!content?.contactChannels?.length
        ? null
        : _contactChannels({ classes,
          prefixes,
          decorators,
          channelLabels,
          channels: content.contactChannels })
      }
    </Grid>
  )
}

export default useMemoRef(NoRequest, props => [props.content])
