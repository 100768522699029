/* eslint-disable object-curly-newline */
/* global React */
import { Grid, List, Typography } from '@mui/material'
import ErrorBoundary from 'ui/Error'
import useMemoRef from '@platform/react/hook/useMemoRef'
import { useStyles, useNoteAPI } from '@platform/react/hook'
import { attachToGroup } from 'ui/Component/Message/List'

export const styles = theme => ({
  root: {
    display: 'block',
    overflowY: 'auto',
    paddingTop: 0,
  },
  ...theme.custom.noteList,
})

/**
 * Returns the elements to be displayed in case there is no data to show.
 *
 * @param {Object} props    the elements' properties
 * @returns {JSX.Element[]} the returned elements' array
 * @constructor
 */
export const NoData = props => (
  <Grid
    item
    container
    component={'li'}
    key={'noData'}
    justifyContent={'center'}
    xs={props.xs}
    md={props.md}
  >
    <Typography
      variant={'subtitle1'}
      color={'textPrimary'}
      align={'center'}
    >
      {props.label}
    </Typography>
  </Grid>
)

/**
 * NoteList component.
 *
 * Listens to notes, processes them and displays them as a list.
 *
 * @param {Object} forwardedRef         a reference to the root element
 * @param {Object} Note                 the item to be used as message
 * @param {number} xs                   the number of grids used by the list from xs width
 * @param {number} md                   the number of grids used by the list from md width
 * @param {Object} types                the possible note types
 * @param {string} value                the given user id
 * @param {Object} events               events attached to the note
 * @param {string} meLabel              translation of english 'me'
 * @param {string} noDataLabel          translation of english 'No notes'
 * @param {string} [attachmentsGroup]   group to attach the attachments to
 // * @param onItemDelete
 * @returns {JSX.Element}
 * @constructor
 */
// eslint-disable-next-line max-len
const NoteList = ({
  forwardedRef,
  Note,
  xs,
  md,
  types,
  value: userId = {},
  events,
  meLabel,
  unknownLabel,
  noDataLabel,
  download = true,
  attachmentsGroup = null,
}) => {
  const classes = useStyles(styles)()
  const { state } = useNoteAPI()
  const { notes } = state

  attachmentsGroup
  && attachToGroup({ messages: notes }, attachmentsGroup)

  return (
    <ErrorBoundary>
      <Grid
        ref={forwardedRef}
        component={List}
        className={classes.root}
        container
        item
        xs={xs}
        md={md}
      >
        {notes?.length
          ? notes.map((note, index) => (
            <Note
              key={note.key || index}
              note={note}
              types={types}
              userId={userId}
              events={events}
              meLabel={meLabel}
              unknownLabel={unknownLabel}
              download={download}
              attachmentsGroup={attachmentsGroup}
            />
          )) : (
            <NoData
              label={noDataLabel}
              classes={classes}
              xs={xs}
              md={md}
            />
          )}
      </Grid>
    </ErrorBoundary>
  )
}
export default useMemoRef(NoteList, props => [props.children])
