/* eslint-disable object-curly-newline,no-param-reassign */
/* global React */
import { useEffect, useState, useTransition } from 'react'
import { FormControl, FormLabel, Grid, FormHelperText } from '@mui/material'
import { withStyles } from '@mui/styles'
import PlatformEvent from 'lib/util/event'
import { useMemoRef } from '@platform/react/hook'
import InputField from 'ui/Element/Field/Input'

const styles = () => ({
  root: {
    '& .MuiOutlinedInput-root': {
      marginTop: 12,
    },
    '& .MuiOutlinedInput-input': {
      padding: 10,
    },
  },
  helperText: {
    marginLeft: 0,
  },
})

/**
 * Input Group Field component
 *
 * Displays a group of {@link InputField} elements according to the {@param props.fields}
 * configuration. If no fields configuration is passed, this element behaves exactly like a single
 * {@link InputField} element.
 *
 * Whenever an inner field changes, this component concatenates all values of each one of its inner
 * {@link InputField} elements and passes the result to the {@param props.events.onChange} event
 * handler.
 *
 * Each element in {@param props.fields} can have a regular expression defined in its condition
 * property. In that case, for the field to be valid, its condition must match its value at all
 * times. An {@link InputGroupField} with any invalid field will pass an empty string instead of
 * their values to the {@param props.events.onChange} event handler.
 *
 * @param {Object} forwardedRef                     a reference object to the root element changes
 * @param {Object} classes                          the component's style classes
 * @param {Object} props                            additional component's properties
 * @param {Object} props.events                     an object containing an onChange event handler
 * @param {function} props.events.onChange          the handler to be called whenever the component
 *                                                  state
 * @param {function} [props.events.getFieldOptions] a handler with which to alternatively get the
 *                                                  field options
 * @param {Object[]} [props.fields]                 an array of field options to configure each
 *                                                  field with it
 * @param {string} [props.fields[].key]             a string to be used as the field key
 * @param {string} [props.fields[].label]           a string to be used as the field label
 * @param {string} [props.fields[].value]           the field's value
 * @param {string} [props.fields[].condition]       a regular expression used to locally validate
 *                                                  the field's value
 * @param {string} [props.fields[].variant]         the field style variant
 * @param {boolean} [props.fields[].disabled]       whether the field is disabled
 * @param {string} [props.fields[].helperText]      an informative string to be displayed below the
 *                                                  field
 * @param {string} [props.label]                    a string to be used as the component's label
 * @param {boolean} [props.disabled]                whether to disable all fields
 * @param {boolean} [props.error]                   whether to display the component in error state
 * @param {string} [props.helperText]               an informative string to be displayed below the
 *                                                  component
 * @returns {JSX.Element}                           the new component
 * @constructor
 */
const InputGroupField = ({ forwardedRef, classes, ...props }) => {
  const [, startTransition] = useTransition()
  const [fields, setFields] = useState(props.fields)

  /**
   * Checks whether the {@param field} is valid by checking that its value passes its condition.
   *
   * @param {Object} field  the options of the inner field that fired the event handler
   * @returns {boolean}
   */
  const checkFieldValue = (field) => {
    const { value, condition } = field
    return !condition || new RegExp(condition).test(value)
  }

  /**
   * Triggered whenever a field value changes.
   *
   * Stores the new field's value inside its options and sets its error state depending on whether
   * its value is valid according to its condition option. If all fields have valid values,
   * concatenates them and passes the resulting value to the {@link events.onChange} event handler,
   * otherwise it passes an empty string.
   *
   * @param {Object} field              the options of the inner field that fired the event handler
   * @returns {(function(Event): void)|*}
   */
  const handleFieldChange = field => (event) => {
    const { value } = event.target
    let newValue = ''

    field.value = value

    if (checkFieldValue(field)) {
      field.error = false
      if (fields.every(checkFieldValue)) {
        newValue = fields.map(item => item.value).join('')
      }
    } else {
      field.error = !field.disabled
    }
    startTransition(() => {
      props.events?.onChange(new PlatformEvent(event, { value: newValue, fields }))
    })
    setFields([...fields])
  }

  // Allowing options to be set through a getFieldOptions handler
  useEffect(() => {
    (async () => {
      const newFields = await props.events?.getFieldOptions?.(null)
      setFields(newFields?.length ? newFields : fields)
    })()
  }, [])

  useEffect(() => {
    setFields(props.fields)
  }, [props.fields])

  return fields ? (
    <FormControl
      ref={forwardedRef}
      className={classes.root}
      component='fieldset'
      error={props.error}
      fullWidth
    >
      <Grid
        item
        container
        direction={'row'}
        columnSpacing={1}
        xs
      >
        {!props.label ? null : (
          <Grid
            item
            xs={12}
          >
            <FormLabel
              component='legend'
            >
              {props.label}
            </FormLabel>
          </Grid>
        )}
        {fields.map((field, index) => (
          <Grid
            item
            key={field.key || index}
            xs={field.xs}
            sm={field.sm}
            md={field.md}
            lg={field.lg}
            xl={field.xl}
          >
            <InputField
              {...field}
              value={field.value || ''}
              disabled={field.disabled || props.disabled}
              events={{ onChange: handleFieldChange(field) }}
              autoComplete={field.autoComplete || 'off'}
              inputProps={{
                style: {
                  textAlign: field.align,
                },
              }}
            />
          </Grid>
        ))}
      </Grid>
      <FormHelperText
        className={classes.helperText}
      >
        {props.helperText || '\u00A0'}
      </FormHelperText>
    </FormControl>
  ) : (
    <InputField
      forwardedRef={forwardedRef}
      {...props}
    />
  )
}

export default useMemoRef(withStyles(styles)(InputGroupField), props => [
  props.value, props.fields, props.disabled, props.error, props.helperText,
])
