/* eslint-disable object-curly-newline */
import { Grid, Icon, Button } from '@mui/material'
import { withStyles } from '@mui/styles'

/**
 * Returns the AttachmentUploadAddMoreButton element's current theme styles.
 *
 * @param {Object} theme  the application's current theme
 * @returns {Object}      the AttachmentUploadAddMoreButton element's styles
 */
const styles = theme => ({
  root: {

  },
  button: {
    borderRadius: 4,
    justifyContent: 'flex-start',
  },
  ...theme.custom.attachmentUploadAddMoreButton,
})
/**
 * AttachmentUploadAddMoreButton element
 *
 * Intended to be used to add more files to upload.
 *
 * @param {string} text       a string to be used as the button's text
 * @param {function} onClick  a function to be called whenever the button is clicked
 * @param {boolean} hidden    whether an empty string should be returned instead of the button
 * @param {Object} classes    an object containing the element's css classes
 * @returns {JSX.Element}
 * @constructor
 */
const AttachmentUploadAddMoreButton = ({ text, onClick, hidden, classes }) => (hidden ? null : (
  <Grid
    item
    container
    alignItems={'flex-start'}
    className={classes.root}
    xs={12}
    md={12}
    xl={12}
  >
    <Button
      fullWidth
      onClick={onClick}
      color={'secondary'}
      startIcon={<Icon>add</Icon>}
      className={classes.button}
    >
      {text}
    </Button>
  </Grid>
))

export default withStyles(styles)(AttachmentUploadAddMoreButton)
