/* global React */
import { Box, Grid, MenuItem, Typography, useTheme } from '@mui/material'
import SvgIcon from 'ui/Element/Icon/Svg'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { useMemoRef, useStyles } from 'platform/react/hook'
import { useCallback, useEffect } from 'react'
import { PlatformEvent } from 'lib/util'

const styles = (theme, { icon }) => ({
  item: {
    gap: theme.spacing(1),
    alignItems: 'flex-start',
    maxWidth: '100%',
    padding: '0.5rem',
    borderRadius: '0.5rem',
  },
  text: {
    maxWidth: `calc(100% - ${icon ? '2' : '0'}rem)!important`,
  },
})

/**
 * Action Item Element
 *
 * Displays a {@link MenuItem} that displays a {@param label} and optionally a {@param text} and
 * {@param icon}. If clicked, it will redirect to the route defined in the component configuration.
 *
 * @param {React.Ref} forwardedRef  ref to the element
 * @param {boolean} hidden          whether the menu item is hidden
 * @param {string} label            label for the item
 * @param {string} text             text for the item
 * @param {Object} icon             icon for the item
 * @param {Object} events           events for the item
 * @param {Object} props            additional props
 * @returns {null|JSX.Element}
 * @constructor
 */
const MenuAction = ({ forwardedRef, hidden, label, text, icon, events, ...props }) => {
  const { closeAfterClick = false, parentAnchorState } = props

  useEffect(() => {
    events?.onOpen && events?.onOpen()
  }, [])

  const handleClick = useCallback((e) => {
    events?.onClick(new PlatformEvent(e))
    closeAfterClick && parentAnchorState(null)
  }, [events?.onClick])

  const theme = useTheme()
  const classes = useStyles(styles, { icon })()

  return hidden ? null : (
    <MenuItem
      ref={forwardedRef}
      className={classes.item}
      onClick={handleClick}
      disabled={props?.disabled}
    >
      {icon && (
        <Grid
          item
          xs={'auto'}
          component={Box}
        >
          <SvgIcon
            icon={icon.name}
            {...props.iconColor && { color: props.iconColor }}
            {...icon}
          />
        </Grid>
      )}
      <Grid
        item
        xs={'auto'}
        className={classes.text}
      >
        <OverflowTooltip
          variant={'14/bold'}
          component={Typography}
          color={props.labelColor || theme.palette.black.main}
        >
          {label}
        </OverflowTooltip>
        {text && (
          <Typography
            variant={'14/regular'}
            sx={{ textWrap: 'wrap' }}
            color={props.textColor || theme.palette.gray[400]}
          >
            {text}
          </Typography>
        )}
      </Grid>
    </MenuItem>
  )
}

export default useMemoRef(MenuAction, props => [
  props.label,
  props.text,
  props.icon,
  props.disabled,
])
