/* global G */
import { Box, useTheme } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'
import { useContext } from 'react'
import ApplicationContext from 'platform/react/context/application'

const styles = theme => ({
  label: {
    maxWidth: 'calc(100%)',
  },
  equipment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

/**
 * Displays an equipment name.
 *
 * @param {Object} classes  element's style classes
 * @param {Object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListemItemCellFavoriteEquipmentName = (classes, props = {}) => {
  const theme = useTheme()
  const { label, serialLabel: subLabel, serialLong } = props

  return (
    <Box className={classes.equipment}>
      <Box className={classes.label}>
        <OverflowTooltip
          variant={'14/bold'}
          classes={{ label: classes.row }}
          color={theme.palette.black.main}
        >
          {label}
        </OverflowTooltip>
        <OverflowTooltip
          variant={'12/regular'}
          color={theme.palette.gray[400]}
          classes={{ label: classes.row }}
        >
          {subLabel} • <span style={{ fontWeight: 500 }}>{serialLong || '-'}</span>
        </OverflowTooltip>
      </Box>
    </Box>
  )
}

export default CellHOC(ListemItemCellFavoriteEquipmentName, styles)
