/* eslint-disable object-curly-newline */
/* global React */
import { Button as MuiButton, Icon, useTheme } from '@mui/material'
import { useMemoRef } from '@platform/react/hook'
import { useCallback } from 'react'
import { PlatformEvent } from 'lib/util'
import SvgIcon from 'ui/Element/Icon/Svg'

/**
 * Retrieves the button variant which matches {@param size}.
 *
 * @param {Object} theme  the application theme
 * @param {string} size   the size to choose
 * @returns {{props: Partial<ComponentsPropsList["MuiButton"]>, style: Interpolation<{theme: Theme}>}|*}
 * @private
 */
const _getButtonVariant = (theme, size) => {
  const buttonVariants = theme.components.MuiButton.variants
  return buttonVariants.find(x => x.props.size === size)
}

/**
 *
 * Retrieves the {@code minHeight} prop of the button variant which matches {@param size}.
 *
 * @param {Object} theme  the application theme
 * @param {string} size   the size to choose
 * @returns {*|string}
 * @private
 */
const _getButtonSize = (theme, size) => _getButtonVariant(theme, size)?.style?.minHeight || '2.5rem'

/**
 *
 * Retrieves the {@code paddingTop} prop of the button variant which matches {@param size}.
 *
 * @param {Object} theme  the application theme
 * @param {string} size   the size to choose
 * @returns {*|string}
 * @private
 */
const _getButtonPadding = (theme, size) => _getButtonVariant(theme, size)?.style?.paddingTop || '1rem'

/**
 * Maps the name of the {@code size} prop to a value.
 * @type {{small: string, large: string, tiny: string, huge: string, medium: string}}
 */
const iconSizeMapping = {
  tiny: '1rem',
  small: '1.25rem',
  medium: '1.5rem',
  large: '1.5rem',
  huge: '1.5rem',
}

/**
 * Return the proper component for the icon.
 *
 * @param {JSX.Element|String} icon   the icon to be displayed
 * @param {Object} props              props for the icon
 * @param {string} [size=medium]      size of the icon
 * @returns {JSX.Element|null}
 * @private
 */
const _getIcon = (icon, props, size = 'medium') => {
  if (props) {
    return (
      <SvgIcon
        icon={icon}
        size={iconSizeMapping[size]}
        {...props}
      />
    )
  }
  if (typeof icon === 'object') return icon
  if (typeof icon === 'string') return <Icon>{icon}</Icon>

  return null
}

/**
 * A Button element with some options to configure it.
 *
 * @param {Object} forwardedRef           a reference to the root Button element
 * @param {Object} [events]               an object containing the element's event handlers
 * @param {Function} [events.onClick]     an event handler function called whenever
 *                                        the button is clicked
 * @param {String} value                  the button's text TODO: rename to label
 * @param {Boolean} [fullWidth=true]      whether the button should take parent's whole width
 * @param {Object} [props]                additional button's properties
 * @param {String} [props.startIcon]      the name of an icon to be displayed before the label
 * @param {String} [props.endIcon]        the name of an icon to be displayed after the label
 * @param {Object} [props.startIconProps] additional props for the start icon
 * @param {Object} [props.endIconProps]   additional props for the end icon
 *
 * @returns {JSX.Element}               a new simple button element
 * @constructor
 */
const Button = ({ forwardedRef, events, value, fullWidth = true, ...props }) => {
  const {
    icon,
    startIcon,
    endIcon,
    iconProps = null,
    startIconProps = null,
    endIconProps = null,
    bold = true,
    size = 'medium',
    refName,
    ...buttonProps
  } = props
  const { onClick } = events || {}
  const theme = useTheme()
  const color = theme.palette[buttonProps.color] || {}
  const contained = buttonProps.variant === 'contained'
  const hasIcon = icon || startIcon || endIcon

  const handleClick = useCallback((event) => {
    onClick?.(new PlatformEvent(event))
  }, [onClick])

  const horizontalPadding = _getButtonPadding(theme, size)
  const buttonHeight = _getButtonSize(theme, size)

  const iconDefaultColor = contained ? color.contrastText : color.main

  return (
    <MuiButton
      ref={forwardedRef}
      href={null}
      sx={{
        lineHeight: 'inherit',
        fontWeight: bold ? 700 : 400,
        boxShadow: 'none',
        height: buttonHeight,
        minHeight: buttonHeight,
        ...hasIcon && {
          paddingTop: horizontalPadding,
          paddingBottom: horizontalPadding,
          '& > .MuiButton-startIcon > div': {
            backgroundColor: contained ? color.contrastText : color.main,
          },
          ...!value && {
            // if we only display an icon and no value, we need to overwrite
            // MUI's padding and minWidth
            padding: `${horizontalPadding}!important`,
            minWidth: _getButtonSize(theme, size),
            '& > .MuiButton-startIcon': {
              margin: 0,
            },
          },
        },
        ...contained && {
          ...color.contrastText && {
            color: `${color.contrastText}!important`,
          },
          ...color.dark && {
            '&:hover': {
              backgroundColor: `${color.dark}!important`,
            },
          },
          '&[disabled]': {
            opacity: 0.6,
            ...color.main && {
              backgroundColor: `${color.main}!important`,
            },
          },
        },
      }}
      {
        ...!icon ? {
          ...startIcon && {
            startIcon: _getIcon(startIcon, { color: iconDefaultColor, ...startIconProps }),
          },
          ...endIcon && {
            endIcon: _getIcon(endIcon, { color: iconDefaultColor, ...endIconProps }),
          },
        } : {
          startIcon: _getIcon(icon, { color: iconDefaultColor, ...iconProps }, size),
        }}
      onClick={handleClick}
      fullWidth={fullWidth}
      disableElevation={true}
      {...buttonProps}
    >
      {value}
    </MuiButton>
  )
}

export default useMemoRef(Button, props => [
  props.value,
  props.disabled,
  props.events,
  props.iconProps,
  props.startIconProps,
  props.endIconProps,
])
