/* eslint-disable no-unused-vars */
/* global G */
import { useEventCallback, useEventHandler, useMemoRef, useStyles } from 'platform/react/hook'
import ReactPlayer from 'react-player'
import { useContext, useEffect, useState } from 'react'
import ApplicationContext from 'platform/react/context/application'
import { Grid } from '@mui/material'
import Toolbar from 'platform/react/hoc/attachment/toolbar'
import Text from 'ui/Element/Text/Simple'
import SvgIcon from 'ui/Element/Icon/Svg'

const styles = (theme, { error }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    backgroundColor: !error
      ? theme.palette.common.black
      : theme.palette.common.white,
  },
  error: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  errorIcon: {
    marginBottom: theme.spacing(1),
  },
  errorTitle: {
    marginBottom: theme.spacing(1),
  },
})

/**
 * Error Component
 *
 * Shows an error message, similar to {@link noData} in style, if the video couldn't be played.
 *
 * @param {Object} classes      styling for the component
 * @param {string} label        error label
 * @param {string} description  error description
 * @param {Object} icon         error icon
 * @returns {JSX.Element}
 * @constructor
 */
const Error = ({ classes, label, description, icon }) => (
  <Grid className={classes.root}>
    <SvgIcon
      icon={icon.name}
      variant={icon.variant}
      color={icon.color}
      width={'5rem'}
      height={'5rem'}
      className={classes.icon}
    />
    <Text
      value={label}
      color={'black.main'}
      variant={'16/bold'}
      className={classes.title}
    />
    <Text
      color={'black.main'}
      value={description}
      variant={'16/regular'}
      align={'center'}
    />
  </Grid>
)

/**
 * Simple Video
 *
 * Uses {@link ReactPlayer} to display {@param attachment}s video. Can work with both external
 * attachments (having {@param attachment.value.url} set) or internal attachments. In the latter
 * case, it will fetch its URL using {@code G.ATTACHMENT, G.HTTP, attachment.key} event, so that
 * react player can buffer the video, and we don't download the full binary.
 *
 * @param {Object} attachment   the video to play
 * @param {boolean} controls    whether or not to show controls in the player
 * @param {string} [group]      attachment group
 * @param {Object} props        additional props
 * @returns {JSX.Element}
 * @constructor
 */
const SimpleVideo = ({ attachment, controls = true, group, ...props }) => {
  const { eventBus } = useContext(ApplicationContext)
  const isInternal = !!attachment?.key
  const currentAttachment = attachment?.value?.name
    ? attachment
    : { value: { name: '' } }

  const [url, setUrl] = useState(false)
  const [hasError, setHasError] = useState(false)

  const classes = useStyles(styles, { error: hasError })()

  // In case we are displaying an internal video, dispatch an event to get its URL and let
  // ReactPlayer buffer it instead of downloading the full binary.
  useEffect(() => {
    isInternal
    && eventBus.dispatch(eventBus.type(G.ATTACHMENT, G.HTTP), { [G.DATA]: currentAttachment })
  }, [currentAttachment])

  const urlEventName = eventBus.type(G.ATTACHMENT, G.HTTP, attachment.key)
  const urlEventHandler = useEventCallback(({ detail }) => {
    detail.url && setUrl(detail.url)
  })
  useEventHandler(eventBus, urlEventName, urlEventHandler)

  useEffect(() => {
    attachment?.value?.url && setUrl(attachment.value.url)
  }, [attachment])

  return (
    <Grid
      item
      xs={12}
      container
      className={classes.root}
    >
      {!hasError ? null : (
        <Error
          label={props.errorLabel}
          description={props.errorDescription}
          icon={props.errorIcon}
          classes={{
            root: classes.error,
            title: classes.errorTitle,
            icon: classes.errorIcon,
          }}
        />
      )}
      {!url || hasError ? null : (
        <ReactPlayer
          url={url}
          width={'100%'}
          height={'100%'}
          onError={() => { setHasError(true) }}
          controls={controls}
        />
      )}
    </Grid>
  )
}

export default useMemoRef(Toolbar(SimpleVideo), props => [props.attachment, props.group])
