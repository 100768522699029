import { useStyles } from 'platform/react/hook'
import { Box, ButtonBase, Grid, InputBase, MenuItem, Select, useTheme } from '@mui/material'
import SvgIcon from 'ui/Element/Icon/Svg'
import {
  Add as PlusIcon,
  Chat as ChatIcon,
  Download as DownloadIcon,
  ExpandLess as DownIcon,
  ExpandMore as UpIcon,
  Favorite as FavoriteIcon,
  FavoriteBorder as FavoriteBorderIcon,
  Print as PrintIcon,
  Remove as MinusIcon,
  Search as SearchIcon,
  VerticalSplit as ThumbnailBarIcon,
} from '@mui/icons-material'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ErrorBoundary from 'ui/Error'
import { PlatformEvent } from 'lib/util'
import Search from 'ui/Element/Attachment/Item/Pdf/Simple/Toolbar/Search'
import { formatFilesize } from 'lib/util/attachment'
import AttachmentListTemplateButton from 'ui/Component/Attachment/List/Template/Button'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

/**
 * Common styes for the buttons.
 *
 * @param {Object} theme
 * @returns {Object}
 */
const buttonStyles = theme => ({
  minWidth: '2rem',
  width: '2rem!important',
  height: '2rem!important',
  backgroundColor: theme.palette.background.content,
  borderRadius: '0.25rem',
  '&:disabled': {
    backgroundColor: theme.palette.common.white,
    '& > *': {
      backgroundColor: theme.palette.border.main,
    },
  },
  '& > *': {
    margin: 0,
  },
  padding: 0,
  minHeight: 0,
})

/**
 * Common styles for the input fields.
 *
 * @param {Object} theme
 * @returns {Object}
 */
const inputStyles = theme => ({
  color: theme.palette.common.black,
  backgroundColor: theme.palette.background.content,
  borderRadius: '0.25rem',
  '& .MuiInputBase-input': {
    textAlign: 'right',
    padding: [['0.25rem', '0.5rem']],
  },
})

const styles = (theme, { showSearch, showThumbnails }) => ({
  toolBar: {
    flexDirection: 'row',
    color: theme.palette.common.black,
    backgroundColor: theme.palette.common.white,
    borderRadius: '0.5rem',
    padding: '0.5rem',
    paddingTop: 0,
    alignItems: 'center',
    gap: '0.5rem',
  },
  information: {
    display: 'flex',
    alignItems: 'center',
    height: '2.5rem',
    justifyContent: 'space-around',
    borderBottom: `1px solid ${theme.palette.divider}`,
    '& > *': {
      display: 'flex',
      gap: '0.5rem',
    },
  },
  options: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      gap: '0.5rem',
      flexWrap: 'wrap',
    },
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-around',
      },
    },
  },
  button: {
    ...buttonStyles(theme),
  },
  searchButton: {
    ...buttonStyles(theme),
    backgroundColor: !showSearch
      ? theme.palette.background.content
      : theme.palette.common.black,
  },
  thumbnailButton: {
    ...buttonStyles(theme),
    backgroundColor: !showThumbnails
      ? theme.palette.background.content
      : theme.palette.common.black,
  },
  textButton: {
    height: '2rem',
    padding: '0.5rem',
    borderRadius: '0.25rem',
    color: theme.palette.white.main,
    backgroundColor: theme.palette.common.black,
  },
  upButton: {
    '& > *': { // rotating arrow icon
      transform: 'rotate(-90deg)',
    },
  },
  downButton: {
    '& > *': { // rotating arrow icon
      transform: 'rotate(90deg)',
    },
  },
  searchInput: {
    ...inputStyles(theme),
    width: '20rem',
    [theme.breakpoints.up('md')]: {
      minWidth: '12rem',
    },
    '& .MuiInputBase-input': {
      textAlign: 'left',
      padding: [['0.25rem', '0.5rem']],
    },
  },
  pageNumberInput: {
    ...inputStyles(theme),
    width: '3rem',
  },
  select: {
    borderColor: 'transparent',
    height: '2rem',
    width: '12rem',
    marginTop: '0!important',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.375rem',
  },
})

/**
 * Helper function to create an icon node.
 *
 * @param {Object} theme  the theme
 * @returns {function(*, boolean=, null=): null|JSX.Element}
 * @private
 */
const _createIcon = theme => (button, inverse = false, color = null) => (!button ? null : (
  <SvgIcon
    width={'1.5rem'}
    height={'1.5rem'}
    icon={button.name}
    {...color
      ? { color }
      : { color: !inverse ? theme.palette.black.main : theme.palette.white.main }
    }
    variant={button.variant}
  />
))

/**
 * Simple Pdf Toolbar Component
 *
 * Shows a toolbar with various functions to mutate the currently viewed pdf.
 *
 * @param {Object} toolBar                                    props of the component
 * @param {React.Ref} toolBar.forwardedRef                    ref to the DOM node
 * @param {Object} toolBar.classes                            styles from parent component
 * @param {Object} toolBar.events                             events for the component
 * @param {boolean} toolBar.disabled                          whether the element is not interactive
 * @param {Function} toolBar.events.onThumbnail               handler for clicking on the thumbnail
 *                                                            icon
 * @param {Function} toolBar.events.onPage                    handler when the page changes
 * @param {Function} toolBar.events.onPageNumber              handler when the page number changes
 * @param {Function} toolBar.events.onPageScale               handler when the page scale changes
 * @param {Function} toolBar.events.onSearch                  handler when the search term changes
 * @param {Function} toolBar.events.onSearchResults           handler when the search results change
 * @param {Function} toolBar.events.onHighlightedSearchResult handler when the current search result
 *                                                            changes
 * @param {Function} toolBar.events.onDownload                handler when clicking on DL button
 * @param {boolean} toolBar.showThumbnails                    indicator whether the thumbnails are
 *                                                            shown
 * @param {Object} toolBar.labels                             labels for the component
 * @param {string} toolBar.labels.ofLabel                     'of' label
 * @param {string} toolBar.labels.originalSizeLabel           'Currently' label
 * @param {string} toolBar.labels.currentlyLabel              'Original size' label
 * @param {Object} toolBar.icons                              icons for the component
 * @param {number} toolBar.currentPage                        the current page
 * @param {number} toolBar.pages                              total number of pages
 * @param {number} toolBar.pageScale                          current page scale
 * @param {React.Ref} toolBar.frameRef                        ref to the iframe
 * @param {String|null} toolBar.searchTerm                    the current search term
 * @param {Object[]} toolBar.searchResults                    list of search results
 * @param {Object} toolBar.highlightedSearchResult            currently highlighted search result
 * @returns {JSX.Element}
 * @constructor
 */
const PdfToolbar = ({
  forwardedRef,
  classes: parentClasses,
  events,
  showThumbnails,
  showDownloadButton,
  labels,
  disabled,
  ...props
}) => {
  const {
    onThumbnail = null,
    onPage = null,
    onPageNumber = null,
    onPageScale = null,
    onSearch = null,
    onSearchResults = null,
    onHighlightedSearchResult = null,
    onDownload = null,
    onFeedbackOpen = null,
    onFavoriteOpen = null,
    onFavoriteClick = null,
  } = events || {}

  const {
    icons,

    currentPage,
    pages,
    pageScale,

    frameRef,

    searchTerm,
    searchResults,
    highlightedSearchResult,
    searchBannerOnTop = true,
    showFeedback = true,
    showFavorite = true,
    document = null,
    filename = null,
  } = props

  const { ofLabel, currentlyLabel } = labels || {}
  const theme = useTheme()
  const informationRef = useRef()

  const [showSearch, setShowSearch] = useState(!!searchTerm)
  const [selectOpen, setSelectOpen] = useState(false)
  const [informationWidth, setInformationWidth] = useState(null)
  const [inputValue, setInputValue] = useState(searchTerm || '')

  const icon = _createIcon(theme)
  const baseClasses = useStyles(styles, { showSearch, showThumbnails })()
  const classes = useMemo(() => ({ ...parentClasses, ...baseClasses }), [baseClasses])

  // Dynamically calculating the width based on the parents height
  const resizeHandler = () => { setInformationWidth(informationRef?.current?.clientWidth) }

  // Recalculating height if we resize the window
  useEffect(() => {
    window.addEventListener('resize', resizeHandler)
    return () => { window.removeEventListener('resize', resizeHandler) }
  }, [])

  useEffect(() => {
    informationRef?.current && setInformationWidth(informationRef?.current.clientWidth)
  }, [informationRef])

  // Icons
  const thumbnailIcon = icon(icons.thumbnailIcon, showThumbnails) ?? <ThumbnailBarIcon/>
  const searchIcon = icon(icons.searchIcon, showSearch) ?? <SearchIcon/>
  const upIcon = icon(icons.upIcon) ?? <UpIcon/>
  const downIcon = icon(icons.downIcon) ?? <DownIcon/>
  const minusIcon = icon(icons.minusIcon) ?? <MinusIcon/>
  const plusIcon = icon(icons.plusIcon) ?? <PlusIcon/>
  const printIcon = icon(icons.printIcon) ?? <PrintIcon/>
  const feedbackIcon = icon(icons.feedbackIcon) ?? <ChatIcon/>
  const favoriteIcon = icon(icons.favoriteIcon, false, 'signal.main') ?? <FavoriteBorderIcon/>
  const noFavoriteIcon = icon(icons.noFavoriteIcon) ?? <FavoriteIcon/>

  const favoriteId = document?.documentationId || document?.key || null
  const isFavorite = onFavoriteOpen?.(new PlatformEvent('open', { value: favoriteId }))
  const favoriteEvent = event => new PlatformEvent(event, { payload: favoriteId, options: { delete: !!isFavorite, type: 'documentation' } })

  // Handler for when the page input field changes
  const onPageInput = (e) => {
    if (e.target.value === '') {
      onPageNumber?.('')
    } else {
      const newPage = parseInt(e.target.value, 10)

      if (!Number.isNaN(newPage)) {
        onPageNumber?.((newPage > pages && pages) || (newPage < 1 && 1) || newPage)
      }
    }
  }

  // memoization of select down icon
  const renderSelectIcon = useCallback(({ className, ...iconProps }) => (
    <Box
      {...iconProps}
      sx={{
        cursor: 'pointer',
        marginRight: '0.25rem',
        transform: selectOpen ? 'rotate(-90deg)' : 'rotate(90deg)',
        opacity: disabled ? 0.4 : 1,
      }}
    >
      {downIcon}
    </Box>
  ), [selectOpen, disabled])

  // handlers for going back/forward a page
  const previousPage = () => { onPage?.(-1) }
  const nextPage = () => { onPage?.(1) }

  const handleFeedbackOpen = (e) => {
    onFeedbackOpen?.(new PlatformEvent(e, { payload: document?.documentationId || document?.key }))
  }

  // Events for the search bar
  const searchEvents = {
    onHighlightedSearchResult,
    setInputValue,
    setShowSearch,
    onSearchResults,
    onSearch,
  }

  return (
    <ErrorBoundary>
      <Grid
        item
        container
        columnGap={1}
        ref={forwardedRef}
        className={classes.toolBar}
      >
        <Grid
          item
          xs={12}
          ref={informationRef}
          className={classes.information}
        >
          <Box sx={{ ...informationWidth && { maxWidth: informationWidth } }}>
            <OverflowTooltip
              variant={'14/bold'}
              sx={{ color: 'common.black' }}
            >
              {filename || document?.value?.name || ''}
            </OverflowTooltip>
            {document?.value?.size && (
              <OverflowTooltip
                variant={'14/medium'}
                sx={{ color: theme.palette.grey['400'] }}
              >
                {formatFilesize(document?.value?.size)}
              </OverflowTooltip>
            )}
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          className={classes.options}
        >
          <Grid
            item
            md={5}
            sm={5}
            lg={4}
            xs={12}
          >
            <ButtonBase
              className={`${classes.button} ${classes.thumbnailButton}`}
              onClick={() => { onThumbnail?.(prevState => !prevState) }}
            >
              {thumbnailIcon}
            </ButtonBase>
            <ButtonBase
              onClick={previousPage}
              disabled={disabled || currentPage <= 1}
              className={`${classes.button} ${classes.upButton}`}
            >
              {upIcon}
            </ButtonBase>
            <ButtonBase
              onClick={nextPage}
              disabled={disabled || currentPage >= pages}
              className={`${classes.button} ${classes.downButton}`}
            >
              {downIcon}
            </ButtonBase>
            <InputBase
              onChange={onPageInput}
              disabled={disabled}
              value={currentPage || ''}
              className={classes.pageNumberInput}
            />
            {` ${ofLabel} ${pages || '--'}`}
          </Grid>
          <Grid
            item
            md={6}
            sm={6}
            lg={4}
            xs={12}
            sx={{ justifyContent: 'center' }}
          >
            {!showSearch && (
              <ButtonBase
                className={`${classes.button} ${classes.searchButton}`}
                onClick={() => { setShowSearch(prevState => !prevState) }}
              >
                {searchIcon}
              </ButtonBase>
            )}
            <Search
              classes={classes}
              events={searchEvents}
              inputValue={inputValue}
              searchTerm={searchTerm}
              showSearch={showSearch}
              searchResults={searchResults}
              searchBannerOnTop={searchBannerOnTop}
              icons={{ up: upIcon, down: downIcon }}
              disabled={disabled}
              highlightedSearchResult={highlightedSearchResult}
              labels={{
                close: labels.closeLabel,
                search: labels.searchLabel,
              }}
            />
            <ButtonBase
              className={classes.button}
              disabled={disabled || pageScale <= 0.25}
              onClick={() => {
                onPageScale?.(prevState => (prevState > 0.25 ? prevState - 0.25 : 1))
              }}
            >
              {minusIcon}
            </ButtonBase>
            <ButtonBase
              className={classes.button}
              disabled={disabled}
              onClick={() => { onPageScale?.(prevState => prevState + 0.25) }}
            >
              {plusIcon}
            </ButtonBase>
            <Select
              size={'small'}
              defaultValue={1}
              value={pageScale}
              open={selectOpen}
              variant={'standard'}
              disableUnderline={true}
              disabled={disabled}
              onClick={() => setSelectOpen(prevState => !disabled && !prevState)}
              IconComponent={renderSelectIcon}
              onChange={e => onPageScale?.(e.target.value)}
              className={`${classes.select} ${classes.searchInput}`}
            >
              <MenuItem value={0.25}>
                25%
              </MenuItem>
              <MenuItem value={0.5}>
                50%
              </MenuItem>
              <MenuItem value={1}>
                100%
              </MenuItem>
              <MenuItem value={1.5}>
                150%
              </MenuItem>
              <MenuItem value={2}>
                200%
              </MenuItem>
              <MenuItem
                value={pageScale}
                disabled
              >
                {currentlyLabel}: {Math.trunc(pageScale * 100)}%
              </MenuItem>
            </Select>
          </Grid>
          <Grid
            item
            md={1}
            sm={1}
            lg={4}
            xs={12}
            sx={{ justifyContent: 'flex-end' }}
          >
            <ButtonBase
              className={classes.button}
              onClick={() => { frameRef?.current?.contentWindow.print() }}
            >
              {printIcon}
            </ButtonBase>
            {showDownloadButton && (
              <AttachmentListTemplateButton
                value={null}
                onClick={onDownload}
                attachment={document}
                icon={icons.downloadIcon.name}
                iconProps={icons.downloadIcon}
                className={classes.button}
              />
            )}
            {showFeedback && (
              <ButtonBase
                onClick={handleFeedbackOpen}
                className={classes.button}
              >
                {feedbackIcon}
              </ButtonBase>
            )}
            {showFavorite && (
              <ButtonBase
                onClick={e => onFavoriteClick?.(favoriteEvent(e))}
                className={classes.button}
              >
                {isFavorite ? favoriteIcon : noFavoriteIcon}
              </ButtonBase>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}

export default PdfToolbar
