/* global React */
// eslint-disable-next-line import/no-extraneous-dependencies
import { Icon as MuiIcon, Typography } from '@mui/material'
import { useMemoRef } from '@platform/react/hook'

/**
 * Simple Icon
 * Wrapper around MUI's <Icon>
 *
 * @param {Object} forwardedRef   the ref from parent
 * @param {String} icon           the icon to display
 * @param {String} variant        the variant to display
 * @param [String] color          the icon color
 * @param [String] className      optional class name for styling
 * @returns {JSX.Element}
 * @constructor
 */
const Icon = ({
  forwardedRef,
  icon,
  variant,
  color,
  className,
}) => (
  <Typography
    ref={forwardedRef}
    variant={variant || '24/regular'}
    className={className}
    sx={{ display: 'flex' }}
  >
    <MuiIcon
      sx={{
        color: color || 'text.inverted',
        fontSize: 'inherit!important',
      }}
      // fontSize={'inherit'} // doesn't work
    >
      {icon}
    </MuiIcon>
  </Typography>
)

export default useMemoRef(Icon, [])
