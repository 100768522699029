/* eslint-disable implicit-arrow-linebreak */
import { useMemoRef, useStyles } from '@platform/react/hook'
import Text from 'ui/Element/Text/Simple'

/**
 * Returns the DecoratedText element's current theme styles.
 *
 * @param {Object} theme  the application's current theme
 * @returns {Object}      the DecoratedText component's styles
 */
const styles = theme => ({
  // styles for our className string concatenation, @see ui/Element/Text/Generic.jsx
  // the decorator types
  label: {
    textTransform: 'uppercase',
    fontWeight: '500',
    whiteSpace: 'pre-line', // ref: https://github.com/mui-org/material-ui/issues/9189
    fontSize: '0.8rem',
  },
  list: {
    display: 'list-item',
    padding: 0,
  },
  normal: {
    fontWeight: 'normal',
  },
  bold: {
    fontWeight: 500,
  },
  bolder: {
    fontWeight: 700,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  uppercaseFirst: {
    textTransform: 'lowercase',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
  // Colors
  info: {
    color: theme.palette.info.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  signal: {
    color: theme.palette.signal.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  secondary: {
    color: theme.palette.secondary.main,
  },
  pending: {
    color: theme.palette.pending.main,
  },
  active: {
    color: theme.palette.active.main,
  },
  inactive: {
    color: theme.palette.inactive.main,
  },
  closed: {
    color: theme.palette.border.main,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  textDescription: {
    color: theme.palette.text.description,
    fontWeight: 'normal',
  },
  textInverted: {
    color: theme.palette.text.inverted,
  },
  textLight: {
    color: theme.palette.text.light,
  },
  textDark: {
    color: theme.palette.text.dark,
  },
  textBlack: {
    color: theme.palette.common.black,
  },
  // Sizes
  large: {
    fontSize: '64px',
    lineHeight: 'normal',
  },
  big: {
    fontSize: '24px',
  },
  medium: {
    fontSize: '20px',
  },
  intermediate: {
    fontSize: '18px',
  },
  small: {
    fontSize: '14px',
  },
  extraSmall: {
    fontSize: '12px',
  },
  link: {
    textDecoration: 'underline',
  },
  heading: {
    marginBottom: '30px',
  },
  badge: {
    backgroundColor: theme.palette.background.dark,
    borderRadius: '6px',
    padding: 4,
  },
  ...theme.custom.decoratedText,
})

/**
 * DecoratedText UI element.
 *
 * A {@link Text} element that can be given color and variant properties, and a list of
 * truthy decorators, whose name has to correspond to one of the css properties of the element.
 *
 * @param {string} [value]                    a string to be used as content
 * @param {string} [variant]                  a text style variant to set for the element
 * @param {string} [color]                    a color style to set for the element
 * @param {string} [className]                additional classNames to set for the element
 * @param {Object} [decorators]               an object containing truthy properties, each
 *                                            corresponding to one css property to be applied
 * @param {Object} [props]                    additional element's properties
 * @param {Object[]|string} [props.children]  a list of children elements to be used as content
 * @param {number} [props.maxLines]           the maximum number of lines to display
 * @param {Boolean} [props.noWrap]            whether or not to wrap the text
 * @param {boolean} [hidden]                  whether to display the element or not
 * @returns {JSX.Element}                     the new DecoratedText element
 * @constructor
 */
const DecoratedText = ({ value, variant, color, className, decorators, hidden, ...props }) => {
  const classes = useStyles(styles)()

  const classNames = []
  className && classNames.push(className)
  // filling classNames by mapping decorators to css properties
  decorators && Object.keys(decorators).reduce((acc, key) => {
    const decorator = decorators[key]
    return decorator ? acc.push(classes[key]) && acc : acc
  }, classNames)

  return hidden ? null : (
    <Text
      className={classNames.join(' ')}
      variant={variant}
      color={color}
      maxLines={props.maxLines}
      noWrap={props.noWrap}
    >
      {value}
      {props.children}
    </Text>
  )
}

export default useMemoRef(DecoratedText, props =>
  [props.value, props.color, props.variant, props.decorators, props.children, props.className])
