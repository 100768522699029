import PersonIcon from 'ui/Element/List/Item/Cell/Person/Icon'
import RequestIcon from 'ui/Element/List/Item/Cell/Request/Icon'
import ServiceItemIcon from 'ui/Element/List/Item/Cell/ServiceItem/Image'
import OrganisationIcon from 'ui/Element/List/Item/Cell/Organisation/Icon'

/**
 * Displays the appropriate list item based on the incoming {@code type}.
 *
 * @param {object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellOrganisationMultiIcon = (props) => {
  const { docType } = props

  return (
    <>
      {docType === 'person' && (<PersonIcon {...props} />)}
      {docType === 'request' && (<RequestIcon {...props} />)}
      {docType === 'serviceitem' && (<ServiceItemIcon {...props} />)}
      {docType === 'organisation' && (<OrganisationIcon {...props} />)}
    </>
  )
}

export default ListItemCellOrganisationMultiIcon
