import { Grid, ListSubheader } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'

const style = () => ({
  item: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
})

/**
 * Displays a list header according to the {@param label} variable.
 *
 * @param {string[]} label          a list of labels, each for each column
 * @param {string} [className]      the styles to set to the root component
 * @param {number[]} [size]         a list of sizes, each for each column
 * @param {boolean} [hidden]        whether the element should be hidden
 * @param {Object} [props]          additional element properties
 * @param {string} [props.justify]  element justification setting
 * @param {number} [props.spacing]  amount of distance between each column
 * @returns {JSX.Element}
 * @constructor
 */
const ListSubHeader = useMemoRef(({ label, sx, size, hidden, ...props }) => {
  const classes = useStyles(style)()
  return !hidden && (
    <Grid
      key={'header'}
      item
      container
      component={ListSubheader}
      justifyContent={props.justify || 'space-around'}
      spacing={props.spacing}
      sx={sx}
    >
      {(Array.isArray(label) && label.map((item, i) => (
        <Grid
          key={item}
          item
          xs={size[i]}
        >
          <Grid
            item
            title={item}
            className={classes.item}
          >
            {item}
          </Grid>
        </Grid>
      ))) || label}
    </Grid>
  )
}, props => [props.hidden])

export default ListSubHeader
