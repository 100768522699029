/* eslint-disable object-curly-newline */
/* global React */
import { Box, Grid, Typography } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { format, parseISO } from 'date-fns'
import GaiaImageList, { filterItems } from 'ui/Component/Attachment/List'
import fileConfig from 'configPlatform/ui/_shared/file.json5'
import MuiListTemplateSmall from 'ui/Component/Attachment/List/Template/Small'
import MuiListTemplateAction from 'ui/Component/Attachment/List/Template/Action'
import DecoratedText from 'ui/Element/Text/Hyper/Decorated'
import { debounce, getFirstItem, PlatformEvent } from 'lib/util'
import { useCallback, useMemo, useRef, useState } from 'react'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

/**
 * Returns the Message element's current theme styles.
 *
 * @param {Object} theme  the application's current theme
 * @returns {Object}      the Message element's styles
 */
const variantsStyles = {
  base: (theme, { hasOptions, isHovered, isDeleted }) => ({
    row: {
      display: 'flex',
      width: '100%',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(0.2),
      marginBottom: theme.spacing(0.2),
      maxWidth: '100%',
      flex: '0 1 auto',
      ...isDeleted && { minWidth: '50%' },
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      ...hasOptions && { gap: '1.5rem' },
    },
    messageOptions: {
      display: 'flex',
      flexDirection: 'row',
      gap: '0.5rem',
      opacity: isHovered ? '100%' : '0%',
    },
    messageContent: {
      flex: 1,
      maxWidth: '100%',
      display: 'flex',
      gap: '0.25rem',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    message: {
      padding: theme.spacing(1),
      borderStyle: 'none',
      borderRadius: '8px',
      boxShadow: [[0, 0, 1, 0, 'white', 'inset']],
      wordBreak: 'break-word',
    },
    messageEmoji: {
      fontSize: '2rem',
    },
    attachments: {
      width: '100%',
      marginTop: theme.spacing(0.4),
    },
    attachedImages: {
      display: 'grid!important',
      gridTemplateColumns: 'repeat(2, auto)!important',
      justifyContent: 'flex-end',
    },
    multipleAttachments: {
      gap: '0.5rem!important',
    },
    singleAttachment: {
      gap: '0!important',
    },
    genericAttachment: {
      marginBottom: '0!important',
    },
    attachedImage: {
      padding: '0!important',
      minHeight: '9rem!important',
      minWidth: '9.375rem!important',
      maxWidth: '9.375rem!important',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      borderRadius: 8,
      paddingBottom: '100%',
    },
    footer: {
      // color: '#afb1b3',
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
    ...theme.custom.message,
  }),
  primary: theme => ({
    row: {
      flexDirection: 'row-reverse',
    },
    messageContent: {
      // flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    message: {
      alignSelf: 'flex-end',
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.text.inverted,
      '& a': {
        color: [[theme.palette.text.inverted], '!important'],
        textDecoration: 'underline!important',
        '&:hover': {
          textDecoration: 'none!important',
        },
      },
    },
    footer: {
      justifyContent: 'flex-end',
    },
    ...theme.custom.messagePrimary,
  }),
  secondary: theme => ({
    row: {
      flexDirection: 'row',
    },
    content: {
      flexDirection: 'row-reverse',
    },
    messageContent: {
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
    },
    message: {
      alignSelf: 'flex-start',
      backgroundColor: '#EEE',
      color: '#000',
      '& a': {
        color: '#000!important',
        textDecoration: 'underline!important',
        '&:hover': {
          textDecoration: 'none!important',
        },
      },
    },
    attachedImages: {
      justifyContent: 'flex-start',
    },
    footer: {
      flexDirection: 'row',
    },
    ...theme.custom.messageSecondary,
  }),
}

/**
 * Splits the list of attachment objects passed as {@param data} into image and non-image files and
 * uses a different template to display each of them.
 *
 * @param {Object[]} data     a list of attachment objects
 * @param {Object} classes    an object containing the element's css classes
 * @param {boolean} hidden    whether to return null instead of the element
 * @param {Object} events     events for the component
 * @param {Object} props      additional element's properties
 * @return {null|JSX.Element}
 * @constructor
 */
const Attachments = ({ data = [], classes, hidden, events, attachmentsGroup, ...props }) => {
  const { imageCount, restCount, ...restProps } = props

  return hidden ? null : (
    <Grid
      {...restProps}
      item
      key={'attachments'}
      className={classes.attachments}
    >
      <GaiaImageList
        gap={0}
        size={true}
        space={1}
        spacing={0}
        data={data}
        attachmentsGroup={attachmentsGroup}
        download={false}
        types={fileConfig.types}
        filter={{ type: ['image'] }}
        ListItem={MuiListTemplateSmall}
        events={{ onClick: events?.onClick }}
        classes={{ vertical: `${classes.attachedImages} ${imageCount > 1
          ? classes.multipleAttachments
          : classes.singleAttachment}` }}
        itemClasses={{ image: classes.attachedImage }}
      />
      <GaiaImageList
        data={data}
        download={false}
        types={fileConfig.types}
        filter={{ type: ['!image'] }}
        attachmentsGroup={attachmentsGroup}
        ListItem={MuiListTemplateAction}
        events={{ onClick: events?.onClick }}
        classes={{ vertical: `${classes.attachedImages} ${restCount > 1
          ? classes.multipleAttachments
          : classes.singleAttachment}` }}
        itemClasses={{ root: classes.genericAttachment }}
      />
    </Grid>
  )
}

/**
 * Displays the name of the author the creation date of the message.
 *
 * @param {string} authorName     a person's name
 * @param {string} timestamp      an ISO date string
 * @param {string} editTimestamp  timestamp when last edited
 * @param {string} className      a css class to set for the root element
 * @param {string} sendingLabel   text to be displayed while a message is being sent
 * @param {string} editedLabel    translation for 'Edited'
 * @param {boolean} [hidden]      whether to return null instead of the element
 * @return {null|JSX.Element}
 * @constructor
 */
const Footer = ({
  authorName,
  timestamp,
  editTimestamp,
  className,
  sendingLabel,
  editedLabel,
  hidden,
}) => (hidden ? null : (
  <Grid component={Box} className={className}>
    <Typography
      variant={'14/regular'}
      color={'gray.600'}
    >
      {authorName}
    </Typography>
    <Typography
      variant={'14/regular'}
      color={'gray.600'}
    >
      &nbsp;{timestamp ? `- ${format(parseISO(timestamp), 'HH:mm')}` : `${sendingLabel}`}
    </Typography>
    {editTimestamp && (
      <Typography
        variant={'14/regular'}
        color={'gray.700'}
        sx={{
          fontStyle: 'italic',
          paddingRight: '2px',
        }}
      >
        {`, ${editedLabel} ${format(parseISO(editTimestamp), 'HH:mm')}`}
      </Typography>
    )}
  </Grid>
))

const _classes = (variant, options = {}) => {
  const variantsClasses = Object.keys(variantsStyles).reduce((acc, variantName) => {
    acc[variantName] = useStyles(variantsStyles[variantName], options)()
    return acc
  }, {})

  return Object.keys(variantsClasses.base).reduce((acc, key) => {
    const baseValue = variantsClasses.base[key]
    const variantValue = variantsClasses[variant][key]
    const values = [baseValue]
    variantValue && values.push(variantValue)
    acc[key] = values.join(' ')
    return acc
  }, {})
}

/**
 * Message Item.
 *
 * Item used to display any kind of message sent by a user.
 *
 * @param {Gaia.Model.Message} message      the message object
 * @param {('primary'|'secondary')} variant the style variant
 * @param {string} currentUserId            the ID of the current user
 * @param {boolean} showFooter              whether to display the footer
 * @param {Object} props                    additional element's properties
 * @param {Object} labels                   translated information labels
 * @param {Object} events
 * @param {string} labels.meLabel           translation of english 'me'
 * @param {string} labels.sendingLabel      text to be displayed while a message is being sent
 * @returns {JSX.Element}                   the new Message element
 * @constructor
 */
const Message = ({ message, variant = 'secondary', currentUserId, showFooter, labels, events, attachmentsGroup, ...props }) => {
  const { icons, acl = [] } = props
  const { remove, edit } = icons
  const { meLabel, sendingLabel, deletedLabel, editedLabel } = labels
  const { value, refs } = message
  const {
    text,
    submitTimestamp,
    editTimestamp = null,
    attachments = [],
    status = null,
  } = value
  const submitter = refs.submitter?.[0]
  const {
    person: [submitterPerson] = [],
  } = submitter?.refs || {}

  const hoveredState = useRef(false)
  const [isHovered, setIsHovered] = useState(false)
  const submitterName = (submitterPerson?.value?.firstName && submitterPerson?.value?.lastName && `${submitterPerson?.value?.firstName} ${submitterPerson?.value?.lastName}`)
      || (submitterPerson?.value?.name && submitterPerson?.value?.name)
      || value.sourcePartner
      || ''
  const authorName = getFirstItem(refs.submitter)?.key === currentUserId ? meLabel : submitterName
  const emojiOnly = /^[\p{Emoji}\s]{1,5}$/u.test(text)

  const canDelete = acl.includes(40) || message?.acl?.includes?.(40)
  const canEdit = acl.includes(30) || message?.acl?.includes?.(30)

  const isDeleted = message?.value?.status === 90
  const hasOptions = (canDelete || canEdit) && !isDeleted

  const classes = _classes(variant, { hasOptions, isHovered, isDeleted })

  const handleDelete = useCallback((e) => {
    const deleteEvent = new PlatformEvent(e, message)
    events?.onDelete?.(deleteEvent)
  }, [events?.onDelete, message])

  const handleChange = useCallback((e) => {
    const changeEvent = new PlatformEvent(e, message)
    events?.onChange?.(changeEvent)
  }, [events?.onChange, message])

  const emojiClassName = emojiOnly ? classes.messageEmoji : ''

  const setHovered = useCallback(() => {
    setIsHovered(!!hoveredState?.current)
  }, [])
  const debouncedSetHovered = debounce(setHovered)

  const handleHovered = useCallback((e) => {
    if (e.type === 'mouseenter') hoveredState.current = true
    if (e.type === 'mouseleave') hoveredState.current = false

    debouncedSetHovered()
  }, [])

  const imageAttachments = useMemo(() => filterItems(attachments, null, { type: ['image'] }), [attachments])
  const restAttachments = useMemo(() => filterItems(attachments, null, { type: ['!image'] }), [attachments])

  return (
    <Grid
      item
      className={classes.row}
    >
      <Grid
        item
        className={classes.wrapper}
      >
        <Box
          className={classes.content}
          onMouseEnter={handleHovered}
          onMouseLeave={handleHovered}
        >
          {!isDeleted && (
            <Box className={classes.messageOptions}>
              {canEdit && (
                <Box
                  onClick={handleChange}
                  sx={{ cursor: 'pointer' }}
                >
                  {edit}
                </Box>
              )}
              {canDelete && (
                <Box
                  onClick={handleDelete}
                  sx={{ cursor: 'pointer' }}
                >
                  {remove}
                </Box>
              )}
            </Box>
          )}
          <Box className={classes.messageContent}>
            {!isDeleted ? (
              <>
                <Attachments
                  data={attachments}
                  classes={classes}
                  events={events}
                  attachmentsGroup={attachmentsGroup}
                  imageCount={imageAttachments.length}
                  restCount={restAttachments.length}
                  hidden={!attachments || !attachments.length}
                  xs={12}
                />
                {!text ? null : (
                  <Grid
                    item
                    className={`${classes.message} ${emojiClassName}`}
                  >
                    <DecoratedText
                      value={text}
                    />
                  </Grid>
                )}
              </>
            ) : (
              <OverflowTooltip
                variant={'16/regular'}
                fontStyle={'italic'}
                color={'gray.700'}
                component={Typography}
                // prevents text being cut off
                sx={{ paddingRight: '3px' }}
              >
                {deletedLabel}
              </OverflowTooltip>
            )}
          </Box>
        </Box>
        <Footer
          authorName={authorName}
          timestamp={submitTimestamp}
          editTimestamp={editTimestamp}
          sendingLabel={sendingLabel}
          editedLabel={editedLabel}
          className={classes.footer}
          hidden={!showFooter}
        />
      </Grid>
    </Grid>
  )
}

export default useMemoRef(Message, props => [
  props.value,
  props.value?.status,
  props.showFooter,
])
