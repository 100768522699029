/* eslint-disable object-curly-newline */
import { Grid, lighten } from '@mui/material'
import { withStyles } from '@mui/styles'
import { useMemoRef } from '@platform/react/hook'
import AvatarImage from 'ui/Element/Image/Avatar'

/**
 * Returns the AvatarEdit element's current theme styles.
 *
 * @param {Object} theme  the application's current theme
 * @returns {Object}      the AvatarEdit element's styles
 */
const styles = theme => ({
  root: {
    position: 'relative',
    aspectRatio: '1 / 1',
  },
  button: {
    position: 'absolute',
    cursor: 'pointer',
    transition: 'background-color 250ms ease',
    borderRadius: '50%',
    borderColor: 'transparent',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: 'none',
    minWidth: 0,
    width: 42,
    height: 42,
    right: 0,
    bottom: 0,
    '&:hover': {
      backgroundColor: lighten(theme.palette.secondary.main, 0.3),
      textDecoration: 'none',
      '@media (hover: none)': {
        backgroundColor: lighten(theme.palette.secondary.main, 0.3),
      },
    },
    '&:active': {
      boxShadow: 'none',
    },
  },
  buttonIcon: {
    color: theme.palette.text.inverted,
  },
  ...theme.custom.avatarEditImage,
})

/**
 * AvatarEdit Image Presenter
 *
 * Displays an image contained in a circle together with a button intended to edit it.
 *
 * @param {Object} forwardedRef         a reference to the avatar element
 * @param {string} [src]                image path
 * @param {string} value                the id of the avatar's owner
 * @param {Object} props                additional properties
 * @param {function} [props.events]     to be executed whenever the edit button is clicked
 * @param {Object} props.api            additional api values used for the attachment's creation
 * @param {string} props.group          attachment group to be used by the element
 * @param {Object} props.classes        an object containing the element classes
 * @param {number|string} [props.width] element's width (defaults to 100%)
 * @returns {JSX.Element}               the new avatar element
 * @constructor
 */
const AvatarEditImage = ({ forwardedRef, value, ...props }) => {
  const { /* events, */ api, /* group, */ classes, width } = props
  // const { eventBus } = useContext(ApplicationContext)

  // TODO: WIP. With our current implementation, if the user sets, changes or deletes the avatar on
  //       the profile page, they won't see any change until they navigate or refresh. This is part
  //       of an attempt of making it work by adding the new image to an attachment group.
  // const [key, setKey] = useState(value)
  // const [initialized, setInitialized] = useState(false)
  //
  // if (group) {
  //   const _doneEventName = eventBus.type(G.ATTACHMENT, G.DONE, key)
  //   const _doneEventHandler = useEventCallback(({ detail }) => {
  //     const eventType = eventBus.type(G.ATTACHMENT, G.CACHE, group)
  //     const data = { [G.DATA]: detail }
  //     eventBus.dispatch(eventType, { [G.DATA]: data })
  //   })
  //   useEventHandler(eventBus, _doneEventName, _doneEventHandler)
  //
  //   const _eventName = useRef(eventBus.type(G.ATTACHMENT, G.DONE, group))
  //   const _eventHandler = useEventCallback(({ detail }) => {
  //     const data = detail[G.DATA]
  //     const item = data.find(element => element.key === key)
  //     setKey(item ? key : null)
  //     setInitialized(true)
  //   })
  //   useEventHandler(eventBus, _eventName.current, _eventHandler)
  // }
  //
  // useEffect(() => {
  //   !initialized && setKey(value)
  // }, [value])

  return (
    <Grid
      item
      ref={forwardedRef}
      className={classes.root}
      sx={{ width }}
    >
      <AvatarImage
        value={value}
        api={api}
        fallback={{
          size: '70%',
        }}
      />
      {/* TODO: commented out until we make it work (SP-1509) */}
      {/* <FabButton */}
      {/*   events={events} */}
      {/*   color={'common.white'} */}
      {/*   icon={{ name: 'edit', variant: 'filled' }} */}
      {/*   classes={{ */}
      {/*     root: classes.button, */}
      {/*     icon: classes.buttonIcon, */}
      {/*   }} */}
      {/* /> */}
    </Grid>
  )
}

export default useMemoRef(withStyles(styles)(AvatarEditImage), props => [props.src, props.value])
