/* global G */
import { forwardRef, Suspense, useContext, useImperativeHandle, useRef, useState } from 'react'
import ErrorBoundary from '@ui/Error'
import ApplicationContext from '@platform/react/context/application'
import ScrollGrid from 'ui/Component/Grid/ScrollGrid'
import { useStyles } from 'platform/react/hook'

const styles = (theme, {
  fullScreen,
  appBar = true,
  backgroundColor,
  padding,
  paddingTop = padding,
  paddingRight = padding,
  paddingLeft = padding,
  paddingBottom = padding,
}) => {
  const marginTop = appBar
    ? (fullScreen && theme.layout.actionBarHeight)
      || (!fullScreen && theme.layout.appBarHeight)
    : 0

  return {
    root: {
      marginTop,
      height: `calc(100% - ${marginTop})`,
      minWidth: 0,
    },
    content: {
      // Default paddings, optionally overwritten by module
      padding: padding ?? 0,
      paddingTop: paddingTop ?? theme.layout.contentSpace,
      paddingRight: paddingRight ?? theme.layout.contentGap,
      paddingLeft: paddingLeft ?? theme.layout.contentGap,
      paddingBottom: paddingBottom ?? 0,
      // Background color, optionally set by module
      ...backgroundColor && {
        backgroundColor: theme.palette.background[backgroundColor],
      },
    },
  }
}

/**
 * Content Grid component.
 *
 * Container component to be used as parent of the content of each module's action.
 *
 * @param {Object} classes
 * @param {Object} props
 * @param {Object[]} props.children
 * @param {Object} ref
 */
const ContentGrid = forwardRef((props, ref) => {
  const { session } = useContext(ApplicationContext)
  const [state, setState] = useState({})
  const [scrollPosition, setScrollPosition] = useState({ y: 0 })
  const { children, scroll: enableScroll, fullScreen, ...actionStyle } = state
  const classes = useStyles(styles, { fullScreen, ...actionStyle })()

  const containerRef = useRef()

  useImperativeHandle(ref, () => ({
    setState: (newState = {}) => {
      const [child] = children || []
      const [newChild] = newState.children || []
      const changed = child?.ref !== newChild?.ref

      const action = session[G.MODULE][G.STATE][G.ACTION]
      const scrollY = action?.[G.CACHE].scroll?._cache

      scrollY && delete action[G.CACHE].scroll
      setScrollPosition(changed ? { y: scrollY || 0 } : scrollPosition)
      setState({
        appBar: true,
        ...newState,
      })
    },
  }))

  const onScroll = (event) => {
    const actionData = session[G.MODULE][G.STATE][G.ACTION][G.DATA]
    actionData.scroll = actionData.scroll || {}
    actionData.scroll._cache = event.detail.value
  }

  return (
    <ErrorBoundary>
      <ScrollGrid
        ref={containerRef}
        container
        scrollProps={{
          onScroll,
          position: scrollPosition,
          enable: enableScroll,
        }}
        component={'main'}
        classes={classes}
        justifyContent={'flex-start'}
        alignItems={'stretch'}
      >
        <Suspense>
          {children}
        </Suspense>
      </ScrollGrid>
    </ErrorBoundary>
  )
})

export default ContentGrid
