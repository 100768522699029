import AvatarImage from 'ui/Element/Image/Avatar'
import { Box } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * FabLike Styles
 *
 * the styles were copied from node_modules/@mui/material/Fab/Fab.js
 * be wary if MUI gets updated, and styles dont fit
 *
 * @param theme - mui theme
 * @return {Object} styles - themed styles
 */
const styles = theme => ({
  root: {
    width: theme.typography.pxToRem(theme.typography.fontSize * 3),
    height: theme.typography.pxToRem(theme.typography.fontSize * 3),
    display: 'flex',
  },
  image: {
    maxWidth: '40px',
    width: '100%',
    height: '100%',
  },
})

/**
 * Intended to display a person's icon.
 *
 * @param classes
 * @param {Object} props  props for the component
 * @return {JSX.Element}
 * @constructor
 */
const ListItemCellPersonIcon = (classes, props = {}) => {
  const { id } = props

  return (
    <Box className={classes.root}>
      <Box className={classes.image}>
        <AvatarImage value={id || props?.attachments?.[0]} />
      </Box>
    </Box>
  )
}

export default CellHOC(ListItemCellPersonIcon, styles)
