/* global G, React */
import { forwardRef, useContext, createRef, useRef, useState, useTransition } from 'react'
import ApplicationContext from '@platform/react/context/application'
import AlertDialog from 'ui/Component/Layout/Alert'
import Button from '@ui/Element/Button/Simple'
import useEventCallback from '@platform//react/hook/useEventCallback'
import useEventHandler from '@platform/react/hook/useEventHandler'
import { flushSync } from 'react-dom'

const Alert = forwardRef(AlertDialog)

const child = (props, onClick, defaultProps) => (
  <Button
    variant={defaultProps.variant}
    fullWidth={false}
    color={defaultProps.color}
    {...props}
    events={{ onClick }}
    xs={'auto'}
  />
)

const _defaultState = {
  open: false,
}

/**
 * Confirmation Dialog component.
 *
 * It listens to the following events:
 * - [G.DATA].[G.UNDO]
 *
 * It displays two actions:
 * - OK
 * - Cancel
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Confirm = () => {
  const [, startTransition] = useTransition()
  const { eventBus } = useContext(ApplicationContext)
  const [state, setState] = useState(_defaultState)
  const ref = createRef()
  const onOk = useEventCallback(callback => () => {
    startTransition(() => setState(_defaultState))
    callback?.()
  })
  const onCancel = useEventCallback(callback => () => {
    startTransition(() => setState(_defaultState))
    callback?.()
  })
  // initialization events handler
  const initEventName = useRef(eventBus.type(G.DATA, G.UNDO))
  const initEventHandler = useEventCallback(({ detail }) => {
    const {
      title, text, children, okHandler, cancelHandler, width, elevated = false,
    } = detail

    flushSync(() => {
      setState({
        open: true,
        title,
        text,
        elevated,
        events: {
          onClose: () => { /* Not allowed */ },
        },
        maxWidth: width,
        children: [
          ...children?.cancel ? [child(children.cancel, onCancel(cancelHandler), { variant: 'text', color: 'black' })] : [],
          ...children?.ok ? [child(children.ok, onOk(okHandler), { variant: 'contained', color: 'primary' })] : [],
        ],
      })
    })
  })
  useEventHandler(eventBus, initEventName.current, initEventHandler)
  // termination events handler
  const doneEventName = useRef(eventBus.type(G.DATA, G.DONE))
  const doneEventHandler = useEventCallback(() => setState(_defaultState))
  useEventHandler(eventBus, doneEventName.current, doneEventHandler)

  return (<Alert ref={ref} {...state} />)
}

export default Confirm
