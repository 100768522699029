/* eslint-disable object-curly-newline */
/* global React */
import { useEffect, useRef, useState } from 'react'
import { Grid, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import PlatformEvent from 'lib/util/event'
import { useMemoRef } from '@platform/react/hook'
import DecoratedText from 'ui/Element/Text/Decorated'
import FabButton from 'ui/Element/Button/Fab'
import SimpleButton from 'ui/Element/Button/Simple'
import SelectField from 'ui/Element/Field/Select'
import GaiaImageCount from 'ui/Component/Attachment/Count'
import Mention from 'ui/Element/Field/Mention'
import { arrayOf } from 'lib/util'

const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    backgroundColor: theme.palette.gray.main,
    borderRadius: 4,
    border: `3px solid ${theme.palette.secondary.main}`,
  },
  header: {
    padding: theme.spacing(1, 2.5, 0, 2.5),
  },
  deleteIcon: {
    color: theme.palette.text.secondary,
  },
  content: {
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 2.5, 2, 2.5),
  },
  author: {
    minHeight: 56,
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    margin: theme.spacing(1, 0, 2, 0),
    paddingRight: theme.spacing(2),
    '& fieldset': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  type: {
    fontWeight: 500,
    textTransform: 'uppercase',
    border: 'none',
    fontSize: '.9rem',
    marginLeft: theme.spacing(-0.5),
    paddingLeft: theme.spacing(0.5),
    '&::after, &::before, &:hover::after, &:hover::before': {
      borderBottom: ['none', '!important'],
    },
    marginTop: [0, '!important'],
  },
  typeHelperText: {
    display: 'none',
  },
  button: {
    marginLeft: theme.spacing(-0.5),
  },
  time: {
    padding: theme.spacing(1, 2, 1, 2),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2.5, 2.5, 1, 2.5),
  },
  attachButton: {
    display: 'flex',
    alignItems: 'center',
  },
  attachIcon: {
    color: theme.palette.text.secondary,
  },
  attachCount: {
    color: theme.palette.text.secondary,
  },
  ...theme.custom.noteForm,
})

const AuthorLabel = ({ value, classes, xs }) => (
  <Grid
    item
    component={DecoratedText}
    color={'textSecondary'}
    decorators={{ bold: true }}
    className={classes.author}
    xs={xs}
  >
    {value}
  </Grid>
)

const DeleteButton = ({ onClick, classes, xs }) => (
  <Grid
    key={'delete'}
    id={'delete-button'}
    item
    xs={xs}
  >
    <FabButton
      events={{ onClick }}
      classes={{ icon: classes.deleteIcon }}
      icon={{ name: 'close', variant: 'filled' }}
    />
  </Grid>
)

const AttachButton = ({ onClick, classes, xs }) => (
  <Grid
    key={'attach'}
    item
    className={classes.attachButton}
    xs={xs}
  >
    <SimpleButton
      key={'button'}
      events={{ onClick }}
      fullWidth={false}
      classes={{ icon: classes.attachIcon }}
      size={'small'}
      icon={'paperclip'}
      iconProps={{ variant: 'filled' }}
    />
    <GaiaImageCount
      key={'count'}
      group={'note'}
      autoHide={true}
      className={classes.attachCount}
      xs={'auto'}
    />
  </Grid>
)

/**
 * Note Form.
 *
 * Used as a form to add new notes.
 *
 * @param {Object} value                  preset value of the form
 * @param {string} [value.id]             id of the note in edit form
 * @param {string} [value.text]           content of the note in edit form
 * @param {string} [value.type]           type of the note in edit form
 * @param {Object[]} selection            the available types of notes
 * @param {Object} classes
 * @param {Object} events                 an object containing event handlers
 * @param {function} events.onOpen        called at the beginning to alternatively obtain the note
 *                                        {@param selection}.
 * @param {function} events.onChange      called whenever the note's text field is manipulated
 * @param {function} events.onClick       called whenever the save button is clicked
 * @param {function} events.onAttach      called whenever the attach button is clicked
 * @param {function} events.onClose       called whenever the delete button is clicked
 * @param {Object} props                  additional component's properties
 * @param {string} props.meLabel          translation of english 'me'
 * @param {string} props.placeholder      note's text field placeholder
 * @param {string} props.group            the name of the note's attachment group
 * @param {string} props.buttonText       the text of the save button
 * @param {string} props.buttonTitle      the title of the save button
 * @param {Object[]} props.children       note's subelements
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NoteForm = ({ value, selection, events, classes, children, ...props }) => {
  const { id, text: textValue, type: typeValue } = value || {}
  const { onOpen, onChange, onClick, onAttach, onClose, onType } = events
  const inputRef = useRef(null)
  const [text, setText] = useState('')
  const [mentions, setMentions] = useState([])
  const [empty, setEmpty] = useState(true)
  const types = onOpen ? onOpen(null)?.filter(item => item.selectable) : selection
  const [type, setType] = useState(types[0].key)
  const [timeTracking, setTimeTracking] = useState(false)

  useEffect(() => {
    textValue
    && setText(textValue)
    && setEmpty(false)
    typeValue && setType(typeValue)
  }, [textValue, typeValue])

  const handleTextChange = (event) => {
    const { value, mentions: newMentions } = event.detail

    setText(value)
    setMentions(newMentions)
  }

  useEffect(() => {
    const trimmedText = text.trim()
    setEmpty(!trimmedText.length)
  }, [text])

  const handleClick = (event) => {
    const mentionedPersons = mentions.map(mention => mention.key)
    onClick?.(new PlatformEvent(event, {
      ...id && { key: id },
      text,
      type,
      mentions: mentionedPersons,
    }))
  }

  const handleTypeSelection = (event) => {
    const { value } = event.target
    setType(value)
  }

  const onCtrlEnter = handler => (event) => {
    !empty && event.ctrlKey && event.key === 'Enter' && handler(event)
  }

  useEffect(() => {
    type && setTimeTracking(
      onType?.(new PlatformEvent(new CustomEvent('onType'), { value: type })),
    )
  }, [type])

  const spentTime = arrayOf(children).find(child => child.key === 'spentTime')
  const restChildren = arrayOf(children).reduce((acc, child) => {
    child.key !== 'mention' && child.key !== 'spentTime' && acc.push(child)
    return acc
  }, [])

  return (
    <Grid
      className={classes.root}
      container
      item
    >
      <Grid
        className={classes.header}
        container
        item
        xs={12}
      >
        <AuthorLabel
          value={props.meLabel}
          classes={classes}
          xs={true}
        />
        <DeleteButton
          onClick={onClose}
          classes={classes}
          xs={'auto'}
        />
      </Grid>
      <Grid
        className={classes.content}
        container
        item
        columnSpacing={1}
        xs={12}
      >
        <Mention
          Component={TextField}
          item
          xs={12}
          value={text}
          autoFocus={true}
          multiline={true}
          inputRef={inputRef}
          className={classes.input}
          onChange={handleTextChange}
          placeholder={props.placeholder}
          onKeyPress={onCtrlEnter(handleClick)}
          trigger={children.filter(child => child.props?.mention)}
        />
        <Grid
          item
          container
          alignItems={'center'}
          sx={{
            flex: '1 1 50%',
          }}
        >
          <SelectField
            selection={types}
            value={type}
            events={{ onChange: handleTypeSelection }}
            helperText={null}
            FormHelperTextProps={{
              classes: {
                root: classes.typeHelperText,
              },
            }}
            InputProps={{
              classes: {
                root: classes.type,
                input: classes.type,
              },
            }}
          />
        </Grid>
      </Grid>
      {timeTracking && (
        <Grid
          className={classes.time}
          container
          item
        >
          {spentTime}
        </Grid>
      )}
      <Grid
        item
        container
        xs={12}
        className={classes.footer}
      >
        <Grid
          item
          xs={12}
        >
          {restChildren}
        </Grid>
        <AttachButton
          xs={2}
          onClick={onAttach}
          classes={classes}
        />
        <SimpleButton
          fullWidth={false}
          value={props.buttonText}
          title={props.buttonTitle}
          events={{ onClick: handleClick }}
          variant={'text'}
          color={'secondary'}
          className={classes.button}
          disabled={empty}
        />
      </Grid>
    </Grid>
  )
}

export default useMemoRef(withStyles(styles)(NoteForm), props => [
  props.error,
  props.value,
])
