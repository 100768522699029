import NameOrCount from 'ui/Element/List/Item/Cell/NameOrCount'

/**
 * ListItemCellNameOrCountContacts element
 *
 * Element used to display the number of related person objects or the name of the related person
 * object if there is only one.
 *
 * @param {Object} props  additional element properties
 * @returns {JSX.Element} the new ListItemCellCountOrName element
 * @constructor
 */
export const ListItemCellTeamCountUsers = ({ count }) => <NameOrCount count={count || {}} />

export default ListItemCellTeamCountUsers
