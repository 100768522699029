/* eslint-disable no-unused-vars */
/* global React */
import CellHOC from '@platform/react/hoc/list/cell'
import Autocomplete from 'ui/Component/Field/Autocomplete'
import { useCallback, useRef } from 'react'
import { PlatformEvent } from 'lib/util'
import { useMemoRef } from 'platform/react/hook'

/**
 * Returns the ListItemCellUserAdminRoleAtOrg element's current theme styles.
 *
 * @param {Object} theme    the application's current theme
 * @returns {Object}        the ListItemCellUserAdminRoleName component's styles
 */
const styles = theme => ({
  root: {
    width: '100%',
    marginRight: '10px',
  },
})
/**
 * Displays the role input field for each users' role.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @return {*}
 * @constructor
 */
export const ListItemCellUserAdminRoleName = (classes, props = {}) => {
  const { index, label, role, events, view, delete: isDeleted } = props
  const parentRef = useRef(null)

  const handleChange = useCallback((e) => {
    const changeEvent = new PlatformEvent(e, { ...e.detail, index })
    events?.onRoleChange(changeEvent)
  }, [events?.onRoleChange])

  const Field = React.forwardRef(Autocomplete)

  return (
    <Field
      events={{
        onOpen: events?.onRoleOpen,
        onChange: handleChange,
      }}
      fullWidth={false}
      ref={parentRef}
      view={view}
      value={role}
      label={label}
      oneShot={true}
      freeText={false}
      filterLocally={true}
      disabled={isDeleted}
      clearable={false}
      valueOf={() => false}
    />
  )
}

export default useMemoRef(CellHOC(ListItemCellUserAdminRoleName, styles), props => [props.role, props.delete])
