import CellHOC from '@platform/react/hoc/list/cell'
import { Box } from '@mui/material'
import { useCallback } from 'react'
import MenuButton from 'ui/Element/Button/Menu'
import MenuAction from 'ui/Element/Menu/Item/Action'
import { PlatformEvent } from 'lib/util'

/**
 * Custom styles for the note text.
 *
 * @return {{cell: {lineHeight: number}}}
 */
const styles = () => ({
  cell: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '-0.5rem', // align button with rest of row
    '& > button:hover': {
      backgroundColor: 'transparent!important',
    },
  },
})

/**
 * Displays the 'More' icon of the note.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellNoteText = (classes, props = {}) => {
  const { id, deleteLabel, editLabel, events: { onTimeDelete = null, onTimeUpdate = null } } = props

  const handleDelete = useCallback((e) => {
    const clickEvent = new PlatformEvent(e, { id })
    onTimeDelete?.(clickEvent)
  }, [onTimeDelete])

  const handleUpdate = useCallback((e) => {
    const clickEvent = new PlatformEvent(e, { id })
    onTimeUpdate?.(clickEvent)
  }, [onTimeDelete])

  return (
    <Box className={classes.cell}>
      <MenuButton
        width={'10rem'}
        closeAfterClick={true}
        buttonProps={{
          icon: 'more',
          iconProps: {
            variant: 'filled',
          },
          fullWidth: false,
          variant: 'text',
        }}
      >
        <MenuAction
          icon={{
            name: 'edit',
            variant: 'outlined',
          }}
          events={{ onClick: handleUpdate }}
          label={editLabel}
        />
        <MenuAction
          icon={{
            name: 'trash',
            variant: 'outlined',
            color: 'signal.main',
          }}
          events={{ onClick: handleDelete }}
          labelColor={'signal.main'}
          label={deleteLabel}
        />
      </MenuButton>
    </Box>
  )
}

// <SvgIcon
//     padding={'0.375rem'}
//     color={theme.palette.black.main}
//     background={theme.palette.white.main}
//     rounded={true}
//     icon={'more'}
//     variant={'filled'}
// />
// <ActionMenu
//     id={id}
//     // read={read}
//     // labels={labels}
//     anchorEl={anchorEl}
//     events={{
//       onClose: handleClose,
//       // onUpdate: events?.onUpdate,
//       // onDelete: events?.onDelete,
//     }}
// />
export default CellHOC(ListItemCellNoteText, styles)
