import { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Box, Tooltip } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'

/**
 * Determining whether {@param e} is overflowing or not.
 *
 * @param {Object} e    ref to the element
 *
 * @return {Boolean}
 * @private
 */
const _isElementOverflowing = e => e.offsetWidth < e.scrollWidth || e.offsetHeight < e.scrollHeight

/**
 * OverflowTooltip's styles.
 *
 * @param theme
 * @return {*&{root: {overflow: string}, label: {overflow: string, whiteSpace: string, textOverflow: string}}}
 */
const styles = theme => ({
  root: { overflow: 'hidden' },
  label: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  ...theme.custom.overflowTooltip,
})

/**
 * OverflowToolTip component
 *
 * Places the item to render inside a tooltip if it's overflowing.
 *
 * @param {React.Ref} forwardedRef  ref to the element
 * @param {JSX.Element} component   the component to render, will default to {@link Box}
 * @param {object} parentClasses    styles from the parent
 * @param {object} props            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const OverflowTooltip = ({ forwardedRef, component = Box, classes: parentClasses, ...props }) => {
  const {
    placement = 'bottom-start',
    followCursor = true,
    children,
    label = children,
    ...childProps
  } = props

  const classes = useStyles(styles)()
  const [overflowActive, setOverflowActive] = useState(false)

  const setOverflowState = useCallback(() => {
    _isElementOverflowing(forwardedRef.current)
      ? setOverflowActive(true)
      : setOverflowActive(false)
  }, [forwardedRef?.current])

  useLayoutEffect(() => {
    setOverflowState()
    window.addEventListener('resize', setOverflowState)

    return () => {
      window.removeEventListener('resize', setOverflowState)
    }
  }, [])

  // Let's memoize the component to render and only render the tooltip around it
  // if we actually need to
  const content = useMemo(() => (
    <Box
      ref={forwardedRef}
      component={component}
      className={`${classes.label} ${parentClasses?.label ? parentClasses.label : ''}`}
      {...childProps}
    >
      {children}
    </Box>
  ), [classes])

  return !overflowActive ? content : (
    <Tooltip
      title={label}
      placement={placement}
      className={classes.root}
      followCursor={followCursor}
      disableHoverListener={false}
    >
      {content}
    </Tooltip>
  )
}

export default useMemoRef(OverflowTooltip, props => [props.children])
