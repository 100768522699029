/* global React */
import { useTransition } from 'react'
import { Grid, lighten, useTheme } from '@mui/material'
import { withStyles } from '@mui/styles'
import PlatformEvent from 'lib/util/event'
import { useEventCallback } from '@platform/react/hook'
import FabButton from 'ui/Element/Button/Fab'

const styles = theme => ({
  root: {
    position: 'relative',
    height: '100%',
    flexShrink: 0,
    width: 56,
    margin: theme.spacing(0, 0.5),
  },
  button: {
    position: 'absolute',
    left: 0,
    bottom: '-28px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    minHeight: 36,
    transition: 'background-color 250ms ease',
    boxShadow: 'none',
    borderRadius: '50%',
    padding: 0,
    minWidth: 0,
    width: 56,
    height: 56,
    backgroundColor: theme.palette.secondary.main,
    '&:active': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: lighten(theme.palette.secondary.main, 0.3),
      textDecoration: 'none',
      '@media (hover: none)': {
        backgroundColor: lighten(theme.palette.secondary.main, 0.3),
      },
    },
    '& > div:first-child': {
      width: '2rem',
      height: '2rem',
      transform: 'rotate(45deg)', // x -> +
      color: theme.palette.text.inverted,
      '& > *': {
      },
    },
  },
  ...theme.custom.createButton,
})

/**
 * CreateButton component
 *
 * Returns a button intended to be used to create new domain objects, of one type at each given
 * time, by passing a create event to the onCreate method.
 *
 * @param {Object} classes      the component's style classes
 * @param {Object} route        the route to send as the event detail's when the button is clicked
 * @param {boolean} [hidden]    whether the element should not be rendered
 * @param {function} onCreate   the handler to be called whenever the create button is clicked
 * @returns {JSX.Element}       the new CreateButton component
 * @constructor
 */
const CreateButton = ({ classes, route, hidden, onCreate }) => {
  const theme = useTheme()
  const detail = { route }
  const [, startTransition] = useTransition()

  /**
   * Passes the create event to the {@link onCreate} event handler with the component's route prop.
   */
  const create = useEventCallback(event => startTransition(() => {
    onCreate(new PlatformEvent(event, detail))
  }))

  return hidden ? null : (
    <Grid
      item
      className={classes.root}
      xs={'auto'}
    >
      <FabButton
        icon={{ name: 'close', variant: 'filled' }}
        classes={{ root: classes.button, icon: classes.icon }}
        inputProps={{ 'aria-label': 'search' }}
        events={{ onClick: create }}
        color={theme.palette.white.main}
      />
    </Grid>
  )
}

export default withStyles(styles)(CreateButton)
