import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { isArr } from 'lib/util'
import internal from 'model/organisation/collection/internal'

/**
 * Special username styles
 *
 * @param {Object} theme
 * @return {{verified: {color}, invited: {color}}}
 */
const styles = theme => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  verified: {
    color: theme.palette.info.main,
  },
  invited: {
    color: theme.palette.signal.main,
  },
  subRow: {
    gap: '5px',
    display: 'flex',
    alignItems: 'center',
  },
})

/**
 * Intended to display the approval status of an organisation
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellOrganisationApprovalStatus = (classes, props = {}) => {
  const {
    toBeApprovedInfo,
    parent: {
      key: parentId = null,
      trees: {
        parent: parents = [],
      } = {},
    } = {},
  } = props

  const parentIds = parents[0] && isArr(parents[0])
    ? parents[0]
    : parents
  const ids = [parentId, ...parentIds]
  const displayApproval = internal.some(x => ids.includes(x.key))
  const organisationInfo = (displayApproval && <>{toBeApprovedInfo}</>)
    || '-'

  return <>
    <Typography
      variant={'14/bold'}
      className={classes.row}
      sx={{
        color: theme => (
          displayApproval
            ? theme.palette.signal.main
            : 'inherit'
        ),
      }}
    >
      {organisationInfo}
    </Typography>
  </>
}

export default CellHOC(ListItemCellOrganisationApprovalStatus, styles)
