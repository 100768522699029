import CellHOC from '@platform/react/hoc/list/cell'
import { PlatformEvent } from 'lib/util'
import Button from 'ui/Element/Button/Simple'
import { useState } from 'react'

/**
 * Styles
 *
 * @param {Object} theme
 * @return {{verified: {color}, invited: {color}}}
 */
const styles = theme => ({
  root: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  verified: {
    color: theme.palette.info.main,
  },
  invited: {
    color: theme.palette.signal.main,
  },
  subRow: {
    gap: '5px',
    display: 'flex',
    alignItems: 'center',
  },
})

/**
 * Intended to display an action for the organisation
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
export const ListItemCellOrganisationAction = (classes, props = {}) => {
  const [clicked, setClicked] = useState(false)

  const { rowHovered, label, events, id } = props
  const { onAction } = events

  const handleClick = (event) => {
    setClicked(true)

    const clickEvent = new PlatformEvent(event, { key: id })
    onAction?.(clickEvent)
  }

  return !rowHovered ? null : (
    <Button
      disabled={clicked}
      events={{ onClick: handleClick }}
      variant={'contained'}
      value={label}
    />
  )
}

export default CellHOC(ListItemCellOrganisationAction, styles)
