import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import ApplicationContext from '@platform/react/context/application'
import { useContext } from 'react'

/**
 * Displays the name and address of an Organisation.
 *
 * @param {Object} props    element's properties
 * @param {Object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellOrganisationWithAddress = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)
  const { name = '-', address = {} } = props
  const addressSummary = intl.address(address, { inline: true })

  return (
    <>
      <Typography
        title={name}
        color={'black'}
        variant={'body2'}
        className={classes.row}
        sx={{ fontWeight: props.note ? 'light' : 'bold' }}
      >
        {name}
      </Typography>
      <Typography
        variant={'14/regular'}
        title={addressSummary}
        color={'textSecondary'}
        className={classes.row}
      >
        {addressSummary}
      </Typography>
    </>
  )
}

export default CellHOC(ListItemCellOrganisationWithAddress)
