/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { useMemoRef, useStyles } from '@platform/react/hook'
import { Grid, Icon, Typography, useTheme } from '@mui/material'
import { getThemeColor } from 'lib/util'

const styles = theme => ({
  icon: {
    fontSize: '1.5em',
    display: 'flex',
  },
  text: {
    verticalAlign: 'middle',
  },
  ...theme.custom.iconText,
})

/**
 * IconText UI Element.
 *
 * Simple element used to display dynamic text with an {@param icon}, which can also be given
 * {@param variant}, {@param color}, {@param className} and {@param transform} properties.
 *
 * @param {Object} forwardedRef a reference to the root element
 * @param {string} [icon]       the name of an icon
 * @param {string} [text]       a string to be used as text
 * @param {string} [variant]    a text style variant to set for the element
 * @param {string} [color]      a theme's color to set for the element
 * @param {string} [transform]  a text transformation
 * @param {Object} [props]      additional element's properties
 * @returns {JSX.Element}       the new Text element
 * @constructor
 */
const IconText = ({ forwardedRef, icon, text, variant, color, transform, ...props }) => {
  const classes = useStyles(styles)()
  const theme = useTheme()
  const iconColor = getThemeColor(theme, props.iconColor || color || 'text.primary')
  const textColor = getThemeColor(theme, props.textColor || color || 'text.primary')

  return (
    <Grid
      ref={forwardedRef}
      alignItems={'center'}
      columnSpacing={1}
      container
      item
    >
      <Grid
        item
        container
        xs={'auto'}
      >
        <Typography
          variant={variant || 'h6'}
          sx={{ color: iconColor }}
        >
          <Icon

            color={iconColor}
            className={classes.icon}
          >
            {icon}
          </Icon>
        </Typography>
      </Grid>
      <Grid
        item
        xs={'auto'}
      >
        <Typography
          variant={variant || 'h6'}
          transform={transform}
          className={classes.text}
          sx={{ color: textColor }}
        >
          {text}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default useMemoRef(IconText, props =>
  [props.text, props.icon, props.color, props.variant])
