/* eslint-disable object-curly-newline */
/* global G */
import { useContext, useEffect, useRef, useState } from 'react'
import { Grid, ImageList, Typography, useTheme } from '@mui/material'
import { withStyles } from '@mui/styles'
import { useEventCallback, useEventHandler, useMemoRef } from '@platform/react/hook'
import ApplicationContext from '@platform/react/context/application'
import { isRemote } from 'platform/adapter/attachment/helper'

const styles = theme => ({
  vertical: {
    display: 'flex',
    flexWrap: 'wrap', // nowrap for scroll pane
    flexBasis: '100%',
  },
  horizontal: {
    flexWrap: 'nowrap',
    flexBasis: '30%',
    canGrow: 0,
    canShrink: 1,
    justifyContent: 'space-between',
  },
  ...theme.custom.attachmentList,
})

/**
 * Returns the elements to be displayed in case there is no data to show.
 *
 * @param {Object} forwardedRef a reference to the root element.
 * @param {string} text         the text to display
 * @param {boolean} hidden      whether the element should not be displayed
 * @param {number} xs           the number of grids used by the container from xs width
 * @param {number} md           the number of grids used by the container from md width
 * @returns {JSX.Element[]}     the returned elements' array
 * @constructor
 */
export const NoFiles = ({ forwardedRef, text, hidden, xs, md }) => (hidden ? null : (
  <Grid
    item
    container
    component={'li'}
    justifyContent={'center'}
    key={'noData'}
    ref={forwardedRef}
    xs={xs}
    md={md}
  >
    <Typography
      variant={'subtitle1'}
      color={'textPrimary'}
    >
      {text}
    </Typography>
  </Grid>
))

/**
 * Checks whether {@code array} contains only values preceded by !.
 *
 * @param {string[]} array  an array of strings
 * @returns {boolean}       true if it only contains values preceded by !
 */
const filterIsExclusive = array => array.every(item => item.charAt(0) === '!')

/**
 * Filters file items depending on their type.
 *
 * If there is no filter defined, all items will be displayed.
 * If a filter contains a value, only the items that have that value will be displayed.
 * If a filter contains a value preceded by !, the items that have that value will not be displayed.
 *
 * @param {Object[]} items        the items to filter
 * @param {number} limit          the maximum number of items to return
 * @param {Object} filter         the filter options
 * @param {string[]} filter.type  the types to filter the items by
 * @returns {*}
 */
export const filterItems = (items, limit, filter = {}) => {
  const { type } = filter

  if (!type) return items || []

  const exclusive = filterIsExclusive(type)

  const filteredItems = items && items.length ? items.filter(({ value: item }) => {
    const [itemType] = (item.type || item.mimetype || '').split('/')

    if (item.key === -1) return false

    return exclusive
      ? !type.includes(`!${itemType}`)
      : type.includes(itemType)
  }) : []

  return limit ? filteredItems.slice(0, limit) : filteredItems
}

/**
 * Attachment ImageList component
 *
 * Uses the attachment adapter to obtain the list of currently attached files and displays a list of
 * {@code ListItem}s to represent them.
 *
 * @param {function} ListItem           the element used to display each list's element
 * @param {Object[]} [data]             a list of items to be displayed by the list
 * @param {string} [group]              the attachment group identifier
 * @param {Object} [label]              an object containing text labels to be displayed by the list
 * @param {Object} [classes]            an object containing the root element's css classes
 * @param {Object} [itemClasses]        an object containing the list item element's css classes
 * @param [attachmentsGroup]            optional attachment group that that has been attachted to
 * @param {boolean} [showLocal=true]    whether to display local (about to be uploaded) attachments
 * @param {Object} [props]              additional element's properties
 * @param {number} [props.limit]        the maximum number of images to display
 * @param {Object} [props.filter]       the filters to be used to specify the elements to display
 * @param {string} [props.noFilesLabel] label to show if there is no data
 * @param {number} [props.space]        the number of spacings the bottom margin should have
 * @param {Object} [props.events]       the event handlers to be attached to each {@code ListItem}
 * @param {Object} [props.types]        the types of attached items, which may be used by the
 *                                      {@code ListItem} to better display them
 * @returns {JSX.Element}               a new AttachmentList component
 * @constructor
 */
// eslint-disable-next-line no-unused-vars
const AttachmentList = ({ ListItem, data, group, label = {}, classes, itemClasses, attachmentsGroup, showLocal = true, ...props }) => {
  const { limit, filter, noFilesLabel, space, events, download = true, ...structure } = props
  const { eventBus } = useContext(ApplicationContext)
  const [items, setItems] = useState(data ? filterItems(data, limit, filter) : [])
  const theme = useTheme()

  // If we edit an attachment of a message, data will change and we need to reflect that.
  useEffect(() => {
    setItems(data ? filterItems(data, limit, filter) : [])
  }, [data])

  if (group) {
    // dispatched from adapter, once data or cache have been updated
    const _eventName = eventBus.type(G.ATTACHMENT, G.DONE, group)
    const _eventHandler = useEventCallback(({ detail }) => {
      setItems(filterItems(detail[G.DATA], limit, filter))
    })
    useEventHandler(eventBus, _eventName, _eventHandler)
  }

  const children = items.map(item => (!showLocal && !isRemote(item)
    ? null
    : (
      <ListItem
        {...structure}
        events={events}
        key={item.key}
        attachment={item}
        download={download}
        group={attachmentsGroup || group}
        classes={itemClasses}
      />
    )))

  return children.length ? (
    <ImageList
      className={classes.vertical}
      style={{
        margin: theme.spacing(0, 0, space || 0),
      }}
    >
      {children}
    </ImageList>
  ) : (
    <NoFiles
      text={noFilesLabel}
      hidden={!noFilesLabel}
    />
  )
}

export default useMemoRef(withStyles(styles)(AttachmentList), props => [
  props.data,
  props.classes,
  props.itemClasses,
])
