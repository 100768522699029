/* eslint-disable object-curly-newline */
/* global G, React */
import { Box, Grid } from '@mui/material'
import ErrorBoundary from 'ui/Error'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { lazy, memo, Suspense, useContext, useLayoutEffect, useRef } from 'react'
import ApplicationContext from '@platform/react/context/application'
import usePromise from '@platform/react/hook/usePromise'
import ChildrenHOC from '@platform/react/hoc/children'
import { styles } from 'ui/Component/Message/List'
import useEventHandler from '@platform/react/hook/useEventHandler'
import useEventCallback from '@platform/react/hook/useEventCallback'
import { PlatformEvent } from 'lib/util'

/**
 * Helper function to check if `x` is visible.
 * @param {ChildNode|Element} x  the element to check
 * @returns {boolean}
 * @private
 */
const _isVisible = (x) => {
  const rect = x.getBoundingClientRect()
  const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
}

/**
 * Sets the message adapter's data once the list data is loaded and returns the child list.
 *
 * @param {Object} promisedData   the promised list data
 * @param {Object} props          additional component's properties
 * @returns {JSX.Element}         the child list element
 * @constructor
 */
const SuspendedList = memo(({ promisedData, ...props }) => {
  const suspendedData = promisedData.read()
  const { eventBus } = useContext(ApplicationContext)
  const containerRef = useRef()
  const { List } = props.childrenObject

  /**
   * Event handler for incoming messages
   * @type {React.MutableRefObject<*>}
   */
  const documentEventName = useRef(eventBus.type(G.MESSAGE, G.ADD))
  const documentEventHandler = useEventCallback((event) => {
    const { [G.DATA]: newestMessage = {} } = event?.detail || event || {}
    const newMessageElement = containerRef?.current?.lastChild.lastChild
    const changeEvent = new PlatformEvent('change', {
      isVisible: _isVisible(newMessageElement),
      message: newestMessage,
    })
    props?.events?.onArrival?.(changeEvent)
    document.hasFocus() && props.events.onFocus(event)
  })
  useEventHandler(eventBus, documentEventName.current, documentEventHandler)

  /**
   * Event handler for regained focus of window
   * @type {React.MutableRefObject<string>}
   */
  const windowEventName = useRef('focus')
  const windowEventHandler = useEventCallback(e => props.events.onFocus(e))
  useEventHandler(window, windowEventName.current, windowEventHandler)

  useLayoutEffect(() => {
    eventBus.dispatch(eventBus.type(G.MESSAGE, G.DATA), { [G.DATA]: suspendedData })
  }, [])

  return (
    <Box ref={containerRef}>
      <List { ...props } data={suspendedData} />
    </Box>
  )
})

/**
 * MessagePane component.
 *
 * Loads the dynamic components and data and passes them to the child list component.
 *
 * @param {String} message        the path to a message template
 * @param {String} date           the path to a header template
 * @param {String} system         the path to a system message template
 * @param {String} noRequest      the path to a noRequest message template
 * @param {Object} props          additional component's properties
 * @returns {JSX.Element}
 * @constructor
 */
const MessagePane = ({ template: { message, date, system, noRequest }, ...props }) => {
  const MessageItem = lazy(() => import(`@ui/${message}`))
  const DateItem = lazy(() => import(`@ui/${date}`))
  const SystemItem = lazy(() => import(`@ui/${system}`))
  const NoRequest = lazy(() => import(`@ui/${noRequest}`))

  const promisedData = usePromise(props.events.onOpen)

  const classes = useStyles(styles)()

  return (
    <ErrorBoundary>
      <Suspense fallback={
        <Grid
          className={classes.root}
        />
      }>
        <SuspendedList
          {...props}
          promisedData={promisedData}
          MessageItem={MessageItem}
          DateItem={DateItem}
          SystemItem={SystemItem}
          NoRequestItem={NoRequest}
        />
      </Suspense>
    </ErrorBoundary>)
}

export default useMemoRef(ChildrenHOC(MessagePane), props => [props.value.fullHeight])
