/* eslint-disable arrow-body-style, object-curly-newline, import/no-extraneous-dependencies,
no-nested-ternary */
/* global React */
import { useState, useEffect, useTransition, useRef } from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { withStyles } from '@mui/styles'
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'
import PlatformEvent from 'lib/util/event'
import { useMemoRef } from '@platform/react/hook'
import SvgIcon from 'ui/Element/Icon/Svg'

const styles = () => ({
  root: {
    '& #clear': {
      display: 'none',
    },
    '& #clear:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:hover #clear': {
      display: 'flex',
    },
    '& input:focus + #clear, & input:active + #clear': {
      display: 'flex',
    },
  },
})

/**
 * ClearAdornment element
 *
 * Returns the clear button adornment, to be appended to the field.
 *
 * @param {Object} classes    the component's style classes
 * @param {function} onClick  the handler to call whenever the button is clicked
 * @param {number} [size=16]  icon dimensions
 * @param {boolean} show      whether the button should be displayed
 * @returns {JSX.Element}     the new ClearAdornment element
 * @constructor
 */
export const ClearAdornment = ({ classes, onClick, size = 16, show }) => (
  <InputAdornment
    key={'clear'}
    id={'clear'}
    position={'end'}
  >
    {show && (
      <IconButton
        onClick={onClick}
        className={classes.clearAdornment}
        tabIndex={-1}
      >
        <SvgIcon
          icon={'close'}
          className={classes.clearIcon}
          size={size}
        />
      </IconButton>
    )}
  </InputAdornment>
)

/**
 * Returns the visibility icon by using a local file with name {@param icon} or returns the
 * {@param Fallback} element.
 *
 * @param {string} [icon]             the name of a local file icon in assets
 * @param {React.ReactNode} Fallback  the element to return if {@param icon} is not defined
 * @returns {JSX.Element}
 * @constructor
 */
const VisibilityIcon = ({ icon, Fallback }) => (icon
  ? <SvgIcon
    icon={icon}
    raw={false}
    height={24}
    width={24}
    color={'text.secondary'}
    variant={'outlined'}
    fontSize={'small'}
  />
  : <Fallback />)

/**
 * Visibility Adornment
 *
 * Returns the visibility button adornment, to be appended to password input fields.
 *
 * @param {string} visibilityIcon       an icon to be used to display the field's contents
 * @param {string} visibilityOffIcon    an icon to be used to hide the field's contents
 * @param {boolean} toggle              {@code true} to show the {@param visibilityIcon} or
 *                                      {@code false} to show the {@param visibilityOffIcon}
 * @param {function} onClick            a function to be called on click
 * @returns {JSX.Element}
 * @constructor
 */
const VisibilityAdornment = ({ visibilityIcon, visibilityOffIcon, toggle, onClick }) => (
  <InputAdornment
    key={'visibility'}
    position={'end'}
  >
    <IconButton
      onClick={onClick}
      size={'small'}
      tabIndex={-1}
    >
      {toggle
        ? <VisibilityIcon
          Fallback={VisibilityOffOutlined}
          icon={visibilityOffIcon}
        />
        : <VisibilityIcon
          Fallback={VisibilityOutlined}
          icon={visibilityIcon}
        />
      }
    </IconButton>
  </InputAdornment>
)

/**
 * Input Field Element
 *
 * providing value to input field will throw a React warning in regard to switching from
 * uncontrolled to controlled component, so we need to provide value as defaultValue
 * controlled component - we need to propagate new (props) value on each change.
 * uncontrolled component - we set defaults (props) and let component take care of itself,
 * until we force a new set of props.
 *
 * More info: https://fb.me/react-controlled-components
 *
 * @param {Object} forwardedRef                         ref to the root element
 * @param {Object} events                               events for the input field
 * @param {Object} classes                              the component's style classes
 * @param {String} [helperText]                         helper text to be displayed
 * @param {Boolean} [focused]                           whether or not the input should be focused
 * @param {Object} props                                additional props
 * @param {String} [props.value]                        input value
 * @param {String} [props.variant]                      the input style variant
 * @param {Boolean} [props.disabled]                    whether to show the element in disabled
 *                                                      state and prevent the user from using it
 * @param {Boolean} [props.clearable]                   whether to display the clear button
 * @param {String} [props.visibilityIcon]               icon to be shown when the password is
 *                                                      displayed in clear text
 * @param {String} [props.visibilityOffIcon]            icon to be shown when the password is
 *                                                      displayed as dots
 * @param {HTMLInputTypeAttribute|String} [props.type]  type of the input field
 * @return {*}
 * @constructor
 */
const InputField = ({ forwardedRef, events, classes, helperText, focused, ...props }) => {
  const {
    type,
    visibilityIcon,
    visibilityOffIcon,
    disabled,
    clearable,
    group,
    ...restProps
  } = props
  const { onAttachment, onChange, onFeedback, onKeyPress, onBlur } = events

  const [, startTransition] = useTransition()
  const [showPassword, setShowPassword] = useState(false)
  const [value, setValue] = useState(props.value || '')
  const [feedback, setFeedback] = useState(null)
  const inputRef = useRef()

  const password = type === 'password'
  const text = !type || ['text', 'email'].includes(type)

  const clear = (event) => {
    const newValue = ''
    setValue(newValue)
    setFeedback(newValue)
    inputRef.current?.focus()
    startTransition(() => onChange?.(new PlatformEvent(event, { value: newValue })))
  }

  // Handling input events
  const handle = handler => (event) => {
    const rawValue = event.target.value
    const newValue = rawValue.trim()
    setValue(rawValue)
    startTransition(() => handler?.(new PlatformEvent(event, { value: newValue })))

    const newFeedback = onFeedback?.(new PlatformEvent(event, { value: newValue }))
    setFeedback(newFeedback || '')
  }

  // Handling paste events
  const handleAttachmentPaste = (event) => {
    if (event.clipboardData.files.length) {
      event.clipboardData?.files.length
      && onAttachment?.(new PlatformEvent(event, { files: event.clipboardData.files }))
    }
  }

  useEffect(() => {
    setValue(props.value || '')

    // In case we have a onFeedback handler and the input value changes from the outside
    // we need to execute it again
    if (props.value) {
      const newFeedback = onFeedback?.(new PlatformEvent('change', { value: props.value.toString() }))
      setFeedback(newFeedback || '')
    } else {
      setFeedback('')
    }
  }, [props.value])

  return (
    <TextField
      {...restProps}
      ref={forwardedRef}
      inputRef={inputRef}
      className={classes.root}
      fullWidth // magic to make field grow inside grid
      autoFocus={focused}
      onChange={handle(onChange)}
      onKeyUp={handle(onKeyPress)}
      onBlur={handle(onBlur)}
      onPaste={group && handleAttachmentPaste}
      variant={props.variant || 'standard'}
      type={
        type === 'password'
          ? showPassword
            ? 'text'
            : 'password'
          : 'text'
      }
      value={value}
      disabled={disabled}
      helperText={helperText || '\u00A0'}
      FormHelperTextProps={{
        sx: { maxWidth: 'fit-content' },
      }}
      InputProps={{
        endAdornment: <>
          {feedback && (
            <InputAdornment position={'end'}>
              {feedback}
            </InputAdornment>
          )}
          {(text || password) && clearable && !disabled && (
            <ClearAdornment
              show={!!value?.length}
              classes={classes}
              onClick={clear}
            />
          )}
          {password && (
            <VisibilityAdornment
              visibilityIcon={visibilityIcon}
              visibilityOffIcon={visibilityOffIcon}
              onClick={() => setShowPassword(!showPassword)}
              toggle={showPassword}
            />
          )}
        </>,
      }}
    />
  )
}

export default useMemoRef(withStyles(styles)(InputField), props => [
  props.value, props.error, props.placeholder, props.helperText, props.disabled,
])
