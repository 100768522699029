import { Box, Typography } from '@mui/material'
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

/**
 * Displays the {@param props.submitTimestamp} of the note.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellNoteTimestamp = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)

  const { submitTimestamp, startDate } = props
  const localDate = intl.getLocaleDate(startDate || submitTimestamp, { month: 'short', day: '2-digit' })
  const localTime = startDate ? intl.getLocaleDate(submitTimestamp, { hour: '2-digit', minute: '2-digit' }) : '-'

  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    }}>
      <OverflowTooltip
        component={Typography}
        variant={'12/medium'}
      >
        {`${localDate.toUpperCase()}`}
      </OverflowTooltip>
      <OverflowTooltip
        component={Typography}
        variant={'16/medium'}
        color={'black.main'}
      >
        {localTime}
      </OverflowTooltip>
    </Box>
  )
}

export default CellHOC(ListItemCellNoteTimestamp)
