/* eslint-disable implicit-arrow-linebreak */
/* global React */
import { Children } from 'react'
import { useMemoRef } from '@platform/react/hook'
import Step from '@ui/Component/Stepper/Vertical/Step'
import Tabs from 'ui/Component/Group/Tabs'

/**
 * {@param VerticalStep} component that wraps its {@param children} with a {@link TabsGroup}
 * component.
 *
 * Step component designed to be used as child for the {@link VerticalStepper}, and a wrapper
 * for other elements. Each first-level child would be set as a tab of a {@link TabsGroup}
 * component.
 *
 * @param {boolean} header            {@code true} to display a tab button for each child. The
 *                                    button's text will be set from a label property in each
 *                                    child's options
 * @param {Object[]} children         the children elements to be set as one tab each
 * @param {number|string} children.id the value the {@param props.activeTab} must have for
 *                                    this element to be displayed
 * @param {number|string} [stepTab]   the key of the currently active tab's element
 * @param {Object} [props]            the props to set for the {@link VerticalStep} component
 * @returns {JSX.Element}
 * @constructor
 */
const VerticalTabbedStep = ({ header, children, activeTab: stepTab, ...props }) => {
  const [firstChild] = children || [{}]
  const activeTab = stepTab || firstChild.props?.id || 0
  const items = Children.toArray(children)
  const { actions, events, stepProps } = items.find(child => child.props.id === activeTab).props

  return (
    <Step
      key={'basicStep'}
      {...props}
      events={events}
      actions={actions}
    >
      <Tabs
        key={'tabsGroup'}
        header={header}
        activeTab={activeTab}
        stepProps={stepProps}
        xs={12}
      >
        {children}
      </Tabs>
    </Step>
  )
}

export default useMemoRef(VerticalTabbedStep, false)
