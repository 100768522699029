/* global React */
import { Grid, Icon } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import Text from 'ui/Element/Text/Simple'
import { getThemeColor } from 'lib/util'

/**
 * Returns the Date element's current theme styles.
 *
 * @param {Object} theme  the application's current theme
 * @returns {Object}      the Date element's styles
 */
const styles = theme => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  message: {
    color: theme.palette.text.light,
    fontWeight: 500,
    textAlign: 'center',
  },
  icon: {
    fontSize: '4rem',
    width: 66,
    height: 'auto',
    marginBottom: '10px',
  },
  ...theme.custom.systemMessage,
})

/**
 * System Message Item.
 *
 * Message item used to display a system message.
 *
 * @param {Object} message  the message object
 * @returns {JSX.Element} the new SystemMessage element
 * @constructor
 */
const SystemMessage = ({ message }) => {
  const classes = useStyles(styles)()

  return (
    <Grid
      item
      className={classes.root}
    >
      <Icon className={classes.icon} sx={{ color: theme => getThemeColor(theme, message.color) }}>
        {message.icon}
      </Icon>
      <Text
        className={classes.message}
        variant={'body1'}
      >
        {message.value}
      </Text>
    </Grid>
  )
}

export default useMemoRef(SystemMessage, props => [props.value])
