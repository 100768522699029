/* eslint-disable object-curly-newline */
import { Grid } from '@mui/material'
import { useMemoRef, useAttachmentAPI } from '@platform/react/hook'

/**
 * Attachment List Template Pane
 *
 * TODO: This component should use {@link SimpleImage}
 *  Also, fix JSDoc
 *
 * @param {Object} attachment   the attached file
 * @param {number} size         size of the attachment
 * @param {boolean} focus       whether it should have focus
 * @param {Function} onClick    onClick handler
 * @param {string} [group]      attachment group
 * @returns {JSX.Element}
 * @constructor
 */
const AttachmentListTemplatePane = ({ attachment, size, focus, onClick, group }) => {
  const { state, onLoad } = useAttachmentAPI({ attachment, group })
  const [width, height] = size
  return (
    <Grid
      container
      item
      component={'li'}
      onClick={onClick}
      style={{
        padding: focus ? '0.5%' : '1%',
        width,
        height,
        border: focus && '2px solid rgba(0, 0, 0, 0.5)',
        overflow: 'hidden',
      }}
    >
      <img
        alt={attachment.key}
        src={state.src}
        onLoad={onLoad}
        style={{
          width: '100%',
          objectFit: 'contain',
          aspectRatio: '1 / 1',
        }}
      />
    </Grid>)
}

export default useMemoRef(AttachmentListTemplatePane, p => [p.focus])
