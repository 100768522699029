/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { Alert as MUIAlert, Tooltip, Typography, useTheme } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import { getThemeColor } from 'lib/util'
import SvgIcon from 'ui/Element/Icon/Svg'
import Button from 'ui/Element/Button/Simple'

/**
 * Mapping between severity and colors
 * @type {{success: string, warning: string, error: string, info: string}}
 */
const colorMapping = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success',
}

/**
 * Mapping between severity and icons
 * @type {{success: string, warning: string, error: string, info: string}}
 */
const iconMapping = {
  info: 'info',
  warning: 'triangle',
  error: 'close-circle',
  success: 'check',
}

/**
 * Styles for the component
 * @param {Object} theme      the application theme
 * @param {boolean} multiline whether the string is multiline
 * @returns Object
 */
const styles = (theme, { multiline }) => ({
  root: {
    width: '100%',
    borderRadius: '0.25rem',
    padding: [['0.5rem', '0.75rem']],
    '& .MuiAlert-action': {
      padding: 0,
      paddingLeft: '0.5rem',
      marginRight: 0,
      alignItems: 'center',
    },
  },
  ...!multiline && {
    text: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  ...theme.custom.alert,
})

/**
 * Alert UI Element
 *
 * Element to display a text as a MUI alert.
 *
 * @param {Object} forwardedRef           a reference to the root element
 * @param {string} [value]                a string to be used as value
 * @param {string} [variant]              a text style variant to set for the element
 * @param {string} [textColor]            a color style to override the default text color
 * @param {string} [severity]             a severity level
 * @param {string} [iconColor=textColor]  a color style to overwrite the default icon color
 * @param {boolean} [multiline=false]     whether the text can be multiline
 * @param {Object} props                  additional props
 * @param {Object} [props.endIcon]        end icon to use
 * @param {string} [props.tooltipText]    tooltip text to use
 * @returns {JSX.Element}       the new Text element
 * @constructor
 */
const Alert = ({ forwardedRef, value, variant = 'standard', severity = 'info', textColor, iconColor = textColor, multiline = false, ...props }) => {
  const classes = useStyles(styles, { multiline })()
  const theme = useTheme()

  const { onClick = null } = props?.events || {}

  const icon = <SvgIcon
    raw={false}
    {...iconColor && {
      color: getThemeColor(theme, iconColor),
    }}
    icon={iconMapping[severity]}
    variant={'outlined'}
  />

  const endIcon = props.endIcon
    ? <SvgIcon
      raw={false}
      {...textColor && {
        color: getThemeColor(theme, textColor),
      }}
      icon={props.endIcon.name}
      variant={props.endIcon.variant}
    />
    : null

  return (
    <Tooltip title={props.tooltipText || value}>
      <MUIAlert
        ref={forwardedRef}
        icon={icon}
        className={classes.root}
        variant={variant}
        severity={severity}
        action={
          onClick
            ? <Button
              value={props.actionLabel}
              events={props.events}
              color={colorMapping[severity]}
              variant={'contained'}
              {...props.actionProps}
            />
            : endIcon
        }
      >
        <Typography
          variant={'14/bold'}
          className={classes.text}
          {...textColor && {
            color: getThemeColor(theme, textColor),
          }}
        >
          {value}
        </Typography>
      </MUIAlert>
    </Tooltip>
  )
}

export default useMemoRef(Alert, props => [props.value])
