/* eslint-disable no-unused-vars */
/* global React */
import { useAttachmentAPI, useMemoRef, useStyles } from '@platform/react/hook'
import Button from 'ui/Element/Button/Simple'
import { useEffect, useLayoutEffect, useMemo, useState } from 'react'
import { Box, LinearProgress } from '@mui/material'

const styles = (theme, { value, buttonVariant }) => ({
  root: {
    ...value === null && {
      // take the dimensions from the icon injected if we have no value
      minWidth: 0,
      minHeight: 0,
      padding: 0,
      width: '100%',
      height: '100%',
      '& .MuiButton-startIcon': { margin: 0 },
      '& .MuiButton-endIcon': { margin: 0 },
    },
  },
  progress: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.5,
    borderRadius: buttonVariant !== 'contained'
      ? 4
      : theme.components.MuiButton.styleOverrides.root.borderRadius,
  },
  ...theme.custom.attachmentListTemplateButton,
})

/**
 * AttachmentListTemplateButton attachment component
 *
 * Displays a clickable download button for the given attachment.
 * Is a wrapper for the {@link Button} component that
 * feeds it the attachment.
 *
 * Only fetches the actual attachment when clicked.
 *
 * @param {Object} attachment             the attached file
 * @param {Boolean} [showProgress=true]   whether or not to show a progress bar upon clicking
 * @param {string} [className]            class name for the component
 * @param {string} [group]                attachment group
 * @param props
 */
const AttachmentListTemplateButton = ({ attachment = {}, showProgress = true, className, group, ...props }) => {
  const classes = useStyles(styles, { value: props.value, buttonVariant: props.variant })()
  const [clicked, setClicked] = useState(false)
  const [anchor, setAnchor] = useState(null)
  const { state, fetch } = useAttachmentAPI({
    attachment,
    group,
    initial: false,
  })
  const { src, progress } = state
  const { value: item } = attachment
  const isLoading = progress !== 0 && progress !== 100
  const buttonSize = useMemo(() => props.size || 'medium', [props.size])

  // Reset the click state if we change the attachment
  useEffect(() => { setClicked(false) }, [attachment])

  /**
   * Reporting current progress to external state
   */
  useEffect(() => {
    let active = true

    active && props.setProgress && props.setProgress(progress)

    return () => { active = false }
  }, [progress])

  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setClicked(true)
    anchor && anchor.click()
    !anchor && fetch()
  }

  /**
   * Create a download 'link' for the attachment.
   */
  useLayoutEffect(() => {
    let active = true

    if (active && (state.src || attachment?.value?.url) && !state.placeholder) {
      const link = document.createElement('a')
      link.href = src || attachment?.value?.url
      link.download = item.name
      setAnchor(link)
      clicked && link.click()
    }

    return () => { active = false }
  }, [state])

  return (
    <Box sx={{ position: 'relative' }}>
      <Button
        disabled={isLoading}
        variant={props.variant}

        icon={props.icon}
        iconProps={props.iconProps}
        startIcon={props.startIcon}
        startIconProps={props.startIconProps}
        endIcon={props.endIcon}
        endIconProps={props.endIconProps}

        color={props.color}
        size={buttonSize}

        fullWidth={props.fullWidth}
        className={`${classes.root} ${className}`}
        events={{ onClick: props?.onClick || handleClick }}
        {...props.value !== null && { value: props.value || item.name }}
      />
      {isLoading && showProgress && (
        <LinearProgress
          color={'inherit'}
          className={classes.progress}
          variant={'determinate'}
          value={progress}
        />
      )}
    </Box>
  )
}

export default useMemoRef(AttachmentListTemplateButton, props => [props.attachment, props.className])
