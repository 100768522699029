import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

/**
 * Custom styles for the note text.
 *
 * @return {{cell: {lineHeight: number}}}
 */
const styles = () => ({
  text: {
    marginBottom: 1,
  },
})

/**
 * Displays the {@param props.text} and {@param props.submitter} of the note.
 *
 * @param {Object} classes  styling for the cell
 * @param {Object} props    props for the cell
 *
 * @returns {JSX.Element}
 */
const ListItemCellNoteTextAndSubmitter = (classes, props = {}) => {
  const { text = null, submitter: { refs: { person: [person = {}] = null } = {} } = {} } = props
  const { value: { firstName, lastName } = {} } = person || {}

  const submitterName = firstName && lastName
    ? `${person.value.firstName} ${person.value.lastName}`
    : '-'

  return (
    <>
      <Typography
        variant={'14/regular'}
        align={'justify'}
        color={'black.main'}
        component={Typography}
        className={classes.text}
      >
        {text || '-'}
      </Typography>
      <OverflowTooltip
        variant={'14/regular'}
        component={Typography}
        color={'text.secondary'}
      >
        {submitterName}
      </OverflowTooltip>
    </>
  )
}

export default CellHOC(ListItemCellNoteTextAndSubmitter, styles)
