/* eslint-disable no-empty,object-curly-newline */
/* global React */
import { useEffect, useMemo, useTransition } from 'react'
import { TextField } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import PlatformEvent from 'lib/util/event'
import useMemoRef from '@platform/react/hook/useMemoRef'

/**
 * Modal DatePicker
 *
 * {@link TextField} that makes use of Material-UI's {@link DatePicker} to display a modal to
 * facilitate the selection of a date.
 *
 * https://mui.com/x/react-date-pickers/getting-started/
 *
 * @param {Object} forwardedRef           a reference to the root element
 * @param {string} value                  the current input's value
 * @param {Object} events                 an object containing an onChange event handler
 * @param {function} events.onChange      a handler executed whenever a date is set
 * @param {boolean} [fromToday]           if only dates from today onwards can be selected
 * @param {boolean} [untilToday]          if only dates up to today can be selected
 * @param {boolean} [disabled]            if the component is disabled
 * @param {Object} [props]                additional component's properties
 * @param {Object} [props.mask]           custom mask, e.g. __/__/____ __:__ or __/__/____ __:__ _M
 * @param {Object} [props.format]         date format string, e.g. dd/MM/yyyy
 * @param {string} [props.label]          a string to be used as the input's label
 * @param {boolean} [props.defaultToday]  whether the field should start with today's date set
 * @param {string} [props.minDate]        the minimum date allowed to be selected
 * @param {boolean} [props.focused]       whether the field should start focused
 * @param {string} [props.helperText]     information text to display under the field
 * @param {boolean} [props.error]         whether to display the field in error state
 * @returns {JSX.Element}                 a new DatePickerField component
 * @constructor
 */
const DatePickerField = ({ forwardedRef, value, events, fromToday, untilToday, disabled,
  ...props }) => {
  const [, startTransition] = useTransition()
  const now = new Date(Date.now())
  const defaultDate = props.defaultToday ? now : null
  const startDate = useMemo(() => (value
    ? new Date(value)
    : defaultDate
  ), [value])
  const [selectedDate, setSelectedDate] = React.useState(startDate)

  useEffect(() => {
    startDate
      && startDate !== selectedDate
      && setSelectedDate(startDate)
  }, [startDate])

  const handleDateChange = (date) => {
    try {
      // if date === null, user deleted everything, we need to propagate that
      const isoDate = date ? date.toISOString() : null
      setSelectedDate(date) // date is actually a date, otherwise toISOString would have thrown
      const event = new CustomEvent('change', { bubbles: true })
      const platformEvent = new PlatformEvent(event, { value: isoDate })
      startTransition(() => events.onChange(platformEvent))
    } catch (ignored) {
      // It is not an error to have incompletely written dates, but normal if the user wants to
      // write them by hand; we just neither send an event nor store it in the component's state
    }
  }

  // If we have a default value, let's propagate it
  useEffect(() => {
    startDate && handleDateChange(startDate)
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        fullWidth
        id="date-picker-dialog"
        label={props.label}
        mask={props.mask || '__.__.____'}
        inputFormat={props.format || 'dd.MM.yyyy'} // this has to be i18n
        onChange={handleDateChange}
        value={selectedDate}
        disabled={disabled}
        OpenPickerButtonProps={{
          'aria-label': 'change date',
        }}
        minDate={props.minDate || (fromToday && now)}
        maxDate={untilToday && now}
        renderInput={inputProps => (
          // this has to be a controlled component; we cannot use our InputField here because it's
          // setting its defaultValue prop - a controlled component must set its value prop
          <TextField
            {...inputProps}
            ref={forwardedRef}
            helperText={props.helperText || '\u00A0'}
            error={props.error}
            autoFocus={props.focused}
            autoComplete={props.autoComplete}
            variant={'standard'}
            fullWidth
          />
        )}
      />
    </LocalizationProvider>
  )
}

export default useMemoRef(DatePickerField, props => [props.value, props.error])
