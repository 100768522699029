/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Shows the corresponding label together with the value.
 *
 * @param {number} value              the value to show and to be considered to choose the label
 * @param {Object} [labels]           an object containing te available labels
 * @param {string} [labels.none]      the label to show when value is 0
 * @param {string} [labels.singular]  the label to show when value is 1
 * @param {string} [labels.plural]    the label to show when value is greater than 1
 * @returns {string|number}           either a concatenation of the value and its label or the value
 *                                    alone if there are no labels
 */
const valueToLabel = (value, labels) => {
  if (!labels) {
    return value
  }
  switch (value) {
    case 0:
      return labels.none
    case 1:
      return `1 ${labels.singular}`
    default:
      return `${value} ${labels.plural}`
  }
}

/**
 * Element used to display the number of related objects or the name of the related object if there
 * is only one.
 *
 * Attempts to obtain the first property inside {@code props}. If it has a data property, which
 * should contain the only related object, returns its name. Otherwise, if there is no data object,
 * or it has no name, returns the count property. If there isn't any, returns 0.
 *
 * @param {Object} [labels]
 * @param {Object} [itemsCount]
 * @param {Object} [props]    the object from which to extract either the related object or a count
 * @param {Object} [classes]  style classes to be applied to the elements
 * @returns {JSX.Element[]} a new {@link Typography} element containing the value stated in the
 *                          description
 */
export const ListItemCellNameOrCount = (classes, { labels, count: itemsCount, ...props }) => {
  const itemsCountValue = itemsCount?.value || itemsCount
  const modelProp = itemsCountValue?.[Object.keys(itemsCountValue)[0]] || {}
  const refProp = modelProp?.[Object.keys(modelProp)[0]] || {}
  const {
    data,
    count,
  } = refProp

  const label = `${(data && (data.name || data.number)) || valueToLabel(count || 0, labels)}`

  return (
    <Typography
      title={label}
      component={'span'}
      className={classes.row}
    >
      {label}
    </Typography>
  )
}

export default CellHOC(ListItemCellNameOrCount)
