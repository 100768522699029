import { Box } from '@mui/material'
import { useContext } from 'react'
import ApplicationContext from '@platform/react/context/application'
import DecoratedText from 'ui/Element/Text/Decorated'
import CellHOC from '@platform/react/hoc/list/cell'

/**
 * Returns the ListItemCellSerialUnidentified element's current theme styles.
 *
 * @param {Object} theme  the application's current theme
 * @returns {Object}      the ListItemCellSerialUnidentified element's styles
 */
const styles = theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: theme.palette.error.main,
  },
})

/**
 * Returns the translated device's serialUnidentified label.
 *
 * @returns {string}  the serialUnidentified label
 */
const serialUnidentifiedLabel = () => {
  const { intl } = useContext(ApplicationContext)
  return intl._t(
    'label.serialUnidentified',
    {
      ns: 'device',
      _key: 'label.serialUnidentified',
      defaultValue: 'Serial number cannot be identified',
    },
  )
}

/**
 * Displays a label stating that a serial number cannot be identified.
 *
 * @returns {JSX.Element}
 */
const ListItemCellDeviceSerialUnidentified = () => {
  const serialUnidentified = serialUnidentifiedLabel()

  return (
    <DecoratedText
      variant={'body2'}
      decorators={{
        error: true,
        uppercase: true,
        bold: true,
      }}
    >
      <Box
        component={'span'}
        title={serialUnidentified}
      >
        {serialUnidentified}
      </Box>
    </DecoratedText>
  )
}

export default CellHOC(ListItemCellDeviceSerialUnidentified, styles)
