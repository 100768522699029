/* eslint-disable object-curly-newline,arrow-body-style,import/no-extraneous-dependencies */
/* global React */
import { Grid, Link } from '@mui/material'
import { withStyles } from '@mui/styles'
import { useMemoRef } from '@platform/react/hook'
import TextBlock from '@ui/Element/Text/Block'
import SimpleImage from 'ui/Element/Image/Simple'

const styles = theme => ({
  root: {},
  icon: {
    width: '55%',
    margin: 'auto auto 3em',
  },
  text: {
    backgroundColor: theme.palette.common.white,
  },
  textContainer: {
    flexFlow: 'column',
  },
  iconContainer: {
    height: '100%',
  },
  link: {
    clear: 'both',
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    fontWeight: 500,
    cursor: 'pointer',
  },
  ...theme.custom.textIconBlock,
})

/**
 * TextIconBlock element
 *
 * Displays text together with an SVG-Icon.
 *
 * @param {Object} forwardedRef a reference to the root element.
 * @param {Object} events       an object containing the element's event handlers
 * @param {string} icon         the name of the file where the element's icon must be obtained from
 * @param {Object} classes      the element's style classes
 * @param {Object} props        additional element's properties
 * @param {number} [props.gap]  amount of spacing between icon and text
 * @returns {JSX.Element}
 * @constructor
 */
const TextIconBlock = ({ forwardedRef, events, filename, classes, ...props }) => {
  return (
    <Grid
      ref={forwardedRef}
      item
      container
      component={'span'}
      className={classes.root}
      direction={props.direction || 'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      flexWrap={'nowrap'}
      columnSpacing={props.gap}
      elevation={0}
    >
      <Grid
        item
        container
        className={classes.iconContainer}
        md={5}
        xs={12}
      >
        <SimpleImage filename={filename} />
      </Grid>
      <Grid
        item
        container
        className={classes.textContainer}
        md={7}
        xs={12}
      >
        <TextBlock
          align={props.textAlign || 'left'}
          value={props.text}
          className={classes.text}
        />

        {props.link && (
          <Link
            onClick={events && events.onClick}
            className={classes.link}
            underline="hover">
            {props.link}
          </Link>
        )}
      </Grid>
    </Grid>
  )
}

export default useMemoRef(withStyles(styles)(TextIconBlock), props => [props.text])
