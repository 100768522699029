/* eslint-disable object-curly-newline */
/* global G, React */
import { Grid } from '@mui/material'
import ErrorBoundary from 'ui/Error'
import { useStyles, useMemoRef } from '@platform/react/hook'
import { lazy, Suspense, useContext, useLayoutEffect } from 'react'
import ApplicationContext from '@platform/react/context/application'
import usePromise from '@platform/react/hook/usePromise'
import ChildrenHOC from '@platform/react/hoc/children'
import { styles } from 'ui/Component/Message/List'

/**
 * Sets the note adapter's data once the list data is loaded and returns the child list.
 *
 * @param {Object} promisedData   the promised list data
 * @param {Object} props          additional component's properties
 * @returns {JSX.Element}         the child list element
 * @constructor
 */
const SuspendedList = ({ promisedData, ...props }) => {
  const suspendedData = promisedData.read()
  const { eventBus } = useContext(ApplicationContext)
  const { List } = props.childrenObject

  useLayoutEffect(() => {
    eventBus.dispatch(eventBus.type(G.NOTE, G.DATA), { [G.DATA]: suspendedData })
  })

  return <List { ...props } data={suspendedData} />
}

/**
 * NotePane component.
 *
 * Loads the dynamic components and data and passes them to the child list component.
 *
 * @param {string} message  the path to a message template
 * @param {Object} props    additional component's properties
 * @returns {JSX.Element}
 * @constructor
 */
const NotePane = ({ template, ...props }) => {
  const Note = lazy(() => import(`@ui/${template}`))
  const promisedData = usePromise(props.events.onOpen)

  const classes = useStyles(styles)()

  return (
    <ErrorBoundary>
      <Suspense fallback={
        <Grid
          className={classes.root}
        />
      }>
        <SuspendedList
          {...props}
          promisedData={promisedData}
          Note={Note}
        />
      </Suspense>
    </ErrorBoundary>)
}

export default useMemoRef(ChildrenHOC(NotePane), [])
