/* eslint-disable object-curly-newline,implicit-arrow-linebreak */
import { useStyles } from '@platform/react/hook'
import { Grid, Icon, Typography, useTheme } from '@mui/material'
import DecoratedText from 'ui/Element/Text/Decorated'
import { getThemeColor, isArr } from 'lib/util'
import SvgIcon from 'ui/Element/Icon/Svg'
import { useEffect, useState } from 'react'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

/**
 * Returns the StatusIconText element's current theme styles.
 *
 * @param {Object} theme  the application's current theme
 * @returns {Object}      the StatusIconText component's styles
 */
const styles = theme => ({
  root: {
    gap: '0.5rem',
  },
  dot: {
    // vertically center the dot
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    height: '1.5em',
  },
  text: {},
  ...theme.custom.statusIconText,
})

/**
 * StatusIconText UI element.
 *
 * Simple element used to display dynamic text, which can also be given variant, color, className
 * and children properties.
 *
 * @param {Object} events               an object containing an onOpen event handler
 * @param {Function} events.onOpen      a function that returns the status to be displayed by
 *                                      passing it its key
 * @param {String} [value]              a string to be used as value
 * @param {String} [variant]            a text style variant to set for the element
 * @param {Object} [decorators]         an object containing truthy properties, each corresponding
 *                                      to one  css property to be applied
 * @param {Boolean} [showText=true]     whether to show the status text
 * @param {Boolean} [dot=false]         whether to show a dot instead of the icon
 * @param {React.Ref} ref               a forward reference to the root element
 * @returns {JSX.Element}               the new Text element
 * @constructor
 */
const StatusIconText = ({
  events,
  value,
  variant,
  decorators,
  showText = true,
  dot = false,
}, ref) => {
  const theme = useTheme()
  const classes = useStyles(styles)()
  const [currentStatus, setCurrentStatus] = useState({})
  const decorator = { [currentStatus?.color || 'disabled']: true, ...decorators }

  useEffect(() => {
    (async () => {
      /**
       * If {@link value} is not used to get the status (so the {@code onOpen} handler gets the
       * status from somewhere else), the {@link currentStatus} will never change because either
       * the {@link value} never changes or it isn't used in the handler. This means the component
       * will not update.
       *
       * This happens in cases where we display a badge in a details screen that can be changed in
       * the edit screen. In those cases, the {@link value} for this component should be fetched
       * by the action logic and then passed through into this component via {@code set} in the
       * action logic.
       */
      const [status = {}] = isArr(value)
        ? value
        : await events?.onOpen?.(value) || [{}]
      setCurrentStatus(status)
    })()
  }, [value])

  const icon = dot
    ? <SvgIcon
      width={10}
      height={10}
      raw={false}
      icon={'dot'}
      variant={'filled'}
      color={getThemeColor(theme, currentStatus?.color || 'disabled')}
    />
    : <Icon>
      {currentStatus.icon}
    </Icon>

  return (
    <Grid
      item
      container
      ref={ref}
      className={classes.root}
    >
      {!currentStatus.icon ? null : (
        <Grid
          item
          variant={variant}
          decorators={decorator}
          component={!dot ? DecoratedText : 'div'}
          className={!dot ? classes.icon : classes.dot}
        >
          {icon}
        </Grid>
      )}
      {!showText || !currentStatus.value ? null : (
        <OverflowTooltip
          variant={'14/bold'}
          component={Typography}
          color={getThemeColor(theme, currentStatus?.color || 'disabled')}
        >
          {currentStatus.value?.toUpperCase()}
        </OverflowTooltip>
      )}
    </Grid>
  )
}

export default StatusIconText
