import { Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import ApplicationContext from '@platform/react/context/application'
import { useContext } from 'react'

/**
 * Intended to display the name of the submitTimestamp of a cart.
 *
 * @param {Object} props    element's properties
 * @param {Object} classes  element's style classes
 *
 * @returns {JSX.Element}
 */
const ListItemCellCartCreated = (classes, props = {}) => {
  const { intl } = useContext(ApplicationContext)
  const { submitTimestamp } = props

  const created = intl.getLocaleDate(submitTimestamp, { dateStyle: 'long' })

  return <>
    <Typography
      variant={'14/medium'}
      color={'textDark'}
    >
      {submitTimestamp ? created : '-'}
    </Typography>
  </>
}

export default CellHOC(ListItemCellCartCreated)
