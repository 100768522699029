/* eslint-disable no-nested-ternary */
import { useState } from 'react'
import { Divider, Grid, LinearProgress, Typography, useTheme } from '@mui/material'
import { ArrowForward as ArrowForwardIcon, Download as DownloadIcon } from '@mui/icons-material'
import { PlatformEvent } from 'lib/util'
import { formatFilesize } from 'lib/util/attachment'
import { useMemoRef, useStyles } from '@platform/react/hook'
import SvgIcon, { SvgFileIcon } from 'ui/Element/Icon/Svg'
import MuiListTemplateButton from 'ui/Component/Attachment/List/Template/Button'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

const styles = theme => ({
  rowLeft: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.75rem',
    width: 'calc(100% - 2rem)',
  },
  rowTitle: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 2rem)',
  },
})

/**
 * Gets the type of the file icon based on {@param types}.
 *
 * @param {Object} item     file to work with
 * @param {Object} types    predefined types
 * @returns {string|"generic"}
 */
const getFileIcon = (item, types) => {
  const extension = item.name.slice(item.name.lastIndexOf('.') + 1)
  const type = Object.keys(types).find(id => types[id].includes(extension))

  return `${type || 'generic'}`
}

/**
 * A generic list item for usage in a {@link FileExplorer} or, more precisely
 * a {@link FileFolder} component.
 *
 * @param {Object} attachment         the attachment to download
 * @param {Object} node               the current node to render.
 * @param {Object} events             events passed from parent component
 * @param {Object} options            additional props
 * @returns {JSX.Element}
 * @constructor
 */
const GenericFileItem = ({ attachment, node, events, ...options }) => {
  const theme = useTheme()
  const [progress, setProgress] = useState(0)
  const isLoading = progress !== 0 && progress !== 100

  const { classes: parentClasses, header, footer } = options
  const classes = { ...useStyles(styles)(), ...parentClasses }

  const handleClick = () => {
    const clickEvent = new PlatformEvent('click', {
      id: node.id,
      skipSingleNode: options.skipSingleNode,
      notifyOnSkippable: options.notifyOnSkippable,
    })
    events?.onClick(clickEvent)
  }

  const nodeType = events?.onNodeType(node.type)

  const labelEvent = new PlatformEvent('label', { node, list: true })
  const nodeLabel = events?.onNodeLabel?.(labelEvent)

  const fileType = nodeType === 'File' && node?.attachment?.value?.name
    ? getFileIcon(node?.attachment?.value, options.types)
    : node?.url
      ? getFileIcon({ name: node.url }, options.types)
      : null

  const fileIcon = options.fileIcons
  // if node.icon is set, it takes precedence
    ? (node?.icon && <SvgIcon
      variant={node.icon.variant}
      icon={node.icon.name}
      // if not, use generic file icon
    />) || (nodeType === 'File' && <SvgFileIcon icon={fileType} />)
    : null

  const forwardIcon = options.forwardIcon
    ? <SvgIcon
      color={theme.palette.gray['500']}
      icon={options.forwardIcon.name}
      variant={options.forwardIcon.variant}
      raw={false}
    />
    : <ArrowForwardIcon/>

  const downloadIcon = options.downloadIcon
    ? <SvgIcon
      icon={options.downloadIcon.name}
      variant={options.downloadIcon.variant}
      raw={false}
    />
    : <DownloadIcon/>

  return (
    <>
      {isLoading && (
        <LinearProgress
          className={classes.progress}
          variant={'determinate'}
          value={progress}
        />
      )}
      <Grid
        container
        onClick={handleClick}
        className={classes.folderRow}
      >
        <Grid
          item
          className={classes.rowLeft}
        >
          {options.fileIcons && fileIcon}
          <Grid className={classes.rowTitle}>
            {header && (
              <Typography variant={'12/bold'}>
                {header}
              </Typography>
            )}
            <OverflowTooltip variant={'14/medium'}>
              {nodeLabel || node.name}
            </OverflowTooltip>
            {footer && (
              <Typography
                variant={'12/bold'}
                color={theme.palette.text.link}
              >
                {footer}
              </Typography>
            )}
          </Grid>
          {attachment && isLoading && (
            <Typography
              variant={'14/medium'}
              sx={{ color: 'text.secondary' }}
            >
              {formatFilesize(attachment.value.size)}
            </Typography>
          )}
        </Grid>
        {attachment
          ? <Grid
            item
            className={classes.icon}
          >
            <MuiListTemplateButton
              group={options.group}
              attachment={attachment}
              showProgress={false}
              value={downloadIcon}
              {...{ setProgress }}
            />
          </Grid>
          : <Grid
            item
            className={classes.icon}
          >
            {forwardIcon}
          </Grid>
        }
      </Grid>
      {!options.lastItem && (
        <Divider className={classes.divider} />
      )}
    </>
  )
}

export default useMemoRef(GenericFileItem, props => [props.node, props.classes, props.events])
