/* eslint-disable no-extra-parens */
/* global React */
import { darken, Fab, Typography, useTheme } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import SvgIcon from 'ui/Element/Icon/Svg'
import { getThemeColor } from 'lib/util'

const styles = (theme, { disabled, outline, small, fullWidth, contentStart, background }) => ({
  root: {
    cursor: 'pointer',
    boxShadow: 'none',
    backgroundColor: background ? getThemeColor(theme, background) : 'transparent',
    transition: 'background-color 250ms ease',

    ...outline && {
      border: `${outline}px solid ${theme.palette.divider}`,
      boxShadow: '0px 4px 6px 0px rgb(0 0 0 / 5%)',
    },

    width: small ? '2rem' : '2.5rem', // 32px / 40px
    height: small ? '2rem' : '2.5rem',
    minWidth: '2rem',
    minHeight: '2rem',

    ...fullWidth && { width: '100%' },
    ...contentStart && { justifyContent: 'flex-start' },
    ...disabled && {
      backgroundColor: [['transparent'], '!important'],
      opacity: [[0.3], '!important'],
    },

    '&:active': {
      boxShadow: 'none',
    },
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: background
        ? darken(getThemeColor(theme, background), 0.1)
        : theme.palette.border.main,

      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
      textDecoration: 'none',
    },
  },
  icon: {},
  text: {
    marginLeft: '0.5rem',
  },
  ...theme.custom.fabButton,
})

/**
 * Floating Action Button
 *
 * Will display a floating action button, using {@link SvgIcon} under the hood.
 *
 * @param {Object} forwardedRef     a reference to the inner root element
 * @param {string} icon             the button's icon, from the Material-UI icon library
 * @param {string} value            the button's text
 * @param {Object} events           an object containing the element's event handlers
 * @param {function} events.onClick the event handler to be called whenever the button is clicked
 * @param {boolean} disabled        whether the button should be grayed out and not clickable
 * @param {string} variant          the variant to use
 * @param {string} color            the color of the component's icon
 * @param {string} background       background color to use
 * @param {Object} props            additional element's properties
 * @param {number} props.outline    the number of pixels of width of the element's outline
 * @param {boolean} props.small     whether to display the button with a smaller size
 * @param {boolean} props.fullWidth whether the element should have the same width as its parent
 * @returns {JSX.Element}           a new FabButton element
 * @constructor
 */
const FabButton = ({
  forwardedRef,
  icon,
  value,
  events,
  disabled,
  variant,
  color = 'common.black',
  background,
  ...props
}) => {
  const theme = useTheme()
  const { outline, small, fullWidth, contentStart } = props
  const iconProps = {
    icon: icon.name,
    variant: icon.variant,
    color: getThemeColor(theme, color),
    height: small ? '1rem' : '1.25rem',
    width: small ? '1rem' : '1.25rem',
  }

  const classes = { ...useStyles(styles, {
    disabled, outline, small, fullWidth, contentStart, background,
  })(),
  ...props.classes }

  const handleClick = event => events.onClick(event)

  return (
    <Fab
      variant={variant}
      disabled={disabled}
      onClick={handleClick}
      className={`${classes.root} ${classes?.button || ''}`}
    >
      <SvgIcon
        {...iconProps} // {icon, variant, color}
      />
      {!value ? null : (
        <Typography
          className={classes.text}
        >
          {value}
        </Typography>
      )}
    </Fab>
  )
}

export default useMemoRef(FabButton, props => [props.value, props.disabled])
