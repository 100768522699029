/* global React */
/* eslint-disable no-nested-ternary */
import { Grid, Paper, Tooltip } from '@mui/material'
import { useMemoRef, useStyles } from '@platform/react/hook'
import ChildrenHOC from '@platform/react/hoc/children'
import Text from 'ui/Element/Text/Simple'
import { isStr } from 'lib/util'
import { cellStyles } from '@platform/react/hoc/list/cell'
import { useEffect, useState } from 'react'

/**
 * Returns the PropertyText element's current theme styles.
 *
 * @param {Object} theme        the application's current theme
 *
 * @param {Boolean} isIcon      whether the label is an icon
 * @param {Boolean} isMultiLine whether the value is multiline text
 * @returns {Object}            the PropertyText component's styles
 */
const styles = (theme, { isIcon, isMultiline }) => ({
  root: {
    padding: '0.15rem',
    alignItems: isMultiline ? 'flex-start' : isIcon ? 'center' : 'baseline',
    ...cellStyles.root,
  },
  label: cellStyles.row,
  value: {
    lineHeight: isMultiline ? 1.5 : 'inherit',
    padding: '0 0.6rem',
    color: theme.palette.common.black,
    ...cellStyles.row,
  },
  ...theme.custom.propertyText,
})

/**
 * Get the correct child components according to their {@code type} property.
 *
 * @param {Object[]} children     optional children array
 * @param {Object} childrenObject the object obtained from processing the element's
 *                                      configuration with {@link ChildrenHOC}
 * @return {{Label: (*|null), Value: (*|null)}}
 * @private
 */
const _getChildComponents = ({ children, childrenObject }) => {
  const isVisible = name => children.some(child => child.props?.type === name && !child.props?.hidden)
  const isHelper = name => children.some(child => child.props?.type === name && child.props?.helper)
  const component = name => Object.keys(childrenObject).find(
    childObject => childObject.toLowerCase() === children.find(
      child => child.props?.type === name,
    )?.key.toLowerCase(),
  )

  return {
    label: {
      options: {
        isVisible: isVisible('label'),
        isHelper: isHelper('label'),
      },
      Label: childrenObject[component('label')],
    },
    value: {
      options: {
        isVisible: isVisible('value'),
        isHelper: isHelper('value'),
      },
      Value: childrenObject[component('value')],
    },
  }
}

/**
 * Displays the label with an optional tooltip in case {@param tooltip} is provided.
 *
 * @param {JSX.Element} Label the react component to display the label
 * @param {String} label      the label to display
 * @param {String} [tooltip]  the tooltip to display
 * @returns {JSX.Element|null}
 */
const withTooltip = (Label, label, tooltip) => {
  const Component = Label ? <Label label={label} /> : null

  if (!tooltip) return Component ?? null

  const ForwardedLabel = React.forwardRef((labelProps, ref) => (
    <div ref={ref} {...labelProps} />))

  return (
    <Tooltip title={tooltip}>
      <ForwardedLabel>
        {Label && <Label label={label} />}
      </ForwardedLabel>
    </Tooltip>
  )
}

/**
 * PropertyText UI element.
 *
 * Two {@link Text} elements, representing a pair Label - Value. By default, {@param label}
 * will be shown as the label and {@param value} as its value. If {@param props.children}
 * is not empty however, these components will be used instead.
 *
 * The value of their {@code key} property doesn't matter, because it may be different
 * for data mapping purposes. Instead, one should declare a {@code type = 'label' | 'value'}
 * property in their config.
 *
 * If a children as a property {@code helper} equal to {@code true}, it will be treated as
 * a helper text instead of the actual label/value. That means that {@param label} or
 * {@param value} will be displayed anyway, and the respective children will be displayed
 * below it (acting as a helper text for the former).
 *
 * @param {Object} forwardedRef         a reference to the root element
 * @param {string} value                the string to set as value
 * @param {string} label                the string to set as label
 * @param {string} [tooltip]            an optional tooltip to display then hovering the label
 * @param {Boolean} [isIcon=false]      whether the label is an icon, if so special styling needs
 *                                      to be applied
 * @param {Number} [width=5]            width of the left column in grid space (1-12)
 *                                      the right column will adjust accordingly
 * @param {boolean} [hidden]            whether the element should not be displayed
 * @param {boolean} [uppercase=true]    whether to display label with capital letters
 * @param {boolean} [autoHide]          whether the element should be hidden if it has no value
 * @param {Object} [props]              additional props
 * @param {Object[]} [props.children]   optional children array
 * @param {Object} props.childrenObject the object obtained from processing the element's
 *                                      configuration with {@link ChildrenHOC}
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PropertyText = ({
  forwardedRef,
  value,
  label,
  tooltip,
  isIcon = false,
  width = 5,
  hidden,
  uppercase = true,
  autoHide,
  ...props
}) => {
  const [children, setChildren] = useState(props.children)
  const classes = useStyles(styles, {
    isIcon,
    isMultiline: value && isStr(value) && (value.match(/\n/g) || []).length,
  })()
  const {
    label: { Label = null, options: labelOptions } = {},
    value: { Value = null, options: valueOptions } = {},
  } = children?.length ? _getChildComponents(props) : {}
  const labelWidth = isIcon ? 'auto' : width
  const valueWidth = isIcon ? 'auto' : 12 - width
  const textTransform = uppercase ? 'uppercase' : 'none'
  const hide = hidden || (!value && (autoHide || !props.children))

  // trigger a rerender if children's content changed,
  // regardless of whether its containing array did it
  useEffect(() => {
    setChildren((prevChildren) => {
      const changed = prevChildren.some((child, index) => child !== props.children[index])
      return changed ? props.children : prevChildren
    })
  }, [props.children])

  const isText = !Value || valueOptions.isHelper || !valueOptions.isVisible

  return hide ? null : (
    <Grid
      component={Paper}
      elevation={0}
      ref={forwardedRef}
      className={classes.root}
      alignItems={'center'}
      justifyContent={'flex-start'}
      background={'transparent'}
      container
      item
      xs={12}
      md={12}
      xl={12}
    >
      <Grid
        component={isIcon ? 'div' : Text}
        className={classes.label}
        variant={'body2'}
        value={(!Label || labelOptions.isHelper || !labelOptions.isVisible) && label}
        item
        xs={labelWidth}
        md={labelWidth}
        xl={labelWidth}
        sx={{ textTransform }}
      >
        {withTooltip(Label, label, tooltip)}
      </Grid>
      <Grid
        {...isText && { component: Text }}
        className={classes.value}
        variant={'button'}
        value={isText && value}
        item
        xs={valueWidth}
        md={valueWidth}
        xl={valueWidth}
      >
        {Value && <Value value={value} />}
      </Grid>
    </Grid>
  )
}

export default useMemoRef(ChildrenHOC(PropertyText), p => [p.label, p.value])
