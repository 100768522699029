import { Box, Typography } from '@mui/material'
import CellHOC from '@platform/react/hoc/list/cell'
import { empty } from 'lib/util/object'
import internal from 'model/organisation/collection/internal'
import { useEffect, useState } from 'react'

/**
 * Intended to display additional information about the service item. Is only visible if the user
 * in question passes the acl check.
 *
 * @param {Object} classes  element's style classes
 * @param {Object} props    element's properties
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const ListItemCellServiceItemInfo = (classes, props = {}) => {
  const [approval, setApproval] = useState(null)

  const information = []
  const {
    acl,
    locationExtension = null,
    placement = null,
    toBeValidated = {},
    toBeValidatedInfo = null,
    toBeApprovedInfo = null,
    installedAt: {
      key: parentId = null,
      trees: {
        parent: parentsIds = [],
      } = {},
    } = {},
  } = props

  const displayValidation = !empty(toBeValidated)

  useEffect(() => {
    const ids = [parentId, ...parentsIds]

    setApproval(internal.some(x => ids.includes(x.key)))
  }, [])

  locationExtension && information.push(locationExtension)
  placement && information.push(placement)
  !information.length && information.push('-')

  return (
    <Typography
      color={'black'}
      variant={'body2'}
      className={classes.row}
    >
      { /** @type {React.ReactNode[]} */
        acl !== false && (displayValidation || approval)
          ? <Box
            component={'span'}
            className={classes.row}
            sx={{
              color: theme => theme.palette.signal.main,
              fontWeight: 700,
            }}
          >
            {approval ? toBeApprovedInfo : toBeValidatedInfo}
          </Box>
          : information.map((item, index) => (
            <Box
              key={index}
              component={'span'}
              className={classes.row}
            >
              {item}
            </Box>
          )).reduce((result, item) => (result.length ? [result, ', ', item] : [item]), [])}
    </Typography>
  )
}

export default CellHOC(ListItemCellServiceItemInfo)
