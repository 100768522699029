/* global G */
import { Autocomplete, Box, Grid } from '@mui/material'
import PlatformEvent from 'lib/util/event'
import { useContext, useEffect, useRef, useState } from 'react'
import ReactMarkdown from 'react-markdown'
import ApplicationContext from '@platform/react/context/application'
import { useStyles } from '@platform/react/hook'
import OverflowTooltip from 'ui/Element/Text/OverflowTooltip'

/**
 * Mention component styles
 *
 * @param theme
 * @return {*&{optionContent: {overflow: string, whiteSpace: string, flex: number, flexDirection: string, display: string, textOverflow: string}, optionLabel: {color: string, fontSize: number, fontStyle: string}, input: {textFillColor: string, color: string}, root: {width: string, position: string}, text: {whiteSpace: string, "& a": {color, textDecoration: string}, zIndex: number}}}
 */
const styles = theme => ({
  root: {
    width: '100%',
    position: 'absolute',
  },
  optionContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  optionLabel: {
    fontSize: 12,
    fontStyle: 'italic',
    color: 'text.secondary',
  },
  text: {
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    whiteSpace: 'pre-wrap',
    wordBreak: 'break-word',
  },
  input: {
    color: 'black',
    // This will make every text transparent but the cursor itself.
    // We don't want to see the actual text in the input because we render it in our wrapper
    textFillColor: 'transparent', // https://caniuse.com/?search=%20-webkit-text-fill-color
  },
  ...theme.custom.mention,
})

/**
 * Mention Component
 *
 * Can hook into an input field (like MUI's {@code TextField}) and make it able to mention
 * things (user, tickets, ...) in it.
 *
 * @param {JSX.Element} Component the input component to hook into
 * @param {String} initialValue   initial value for the input
 * @param {Object} inputTrigger   trigger to use for the mentions
 * @param {Object} props          additional props
 * @return {JSX.Element}
 * @constructor
 */
const Mention = ({ Component, value: initialValue, trigger: inputTrigger, ...props }) => {
  const classes = useStyles(styles)()
  const { session: { [G.CONTEXT]: context } } = useContext(ApplicationContext)

  const {
    xs,
    sm,
    md,
    lg,
    xl,
    item,
    onChange: externalOnChange,
    onKeyDown: externalOnKeyDown,
    ...restProps
  } = props

  const gridProps = { item, xs, sm, md, lg, xl }

  // Ref to our wrapper document
  const rootRef = useRef()

  const [inputValue, setInputValue] = useState(initialValue)
  const [renderedValue, setRenderedValue] = useState(initialValue)
  const [activeTrigger, setActiveTrigger] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [mentions, setMentions] = useState([])
  const [lastPosition, setLastPosition] = useState(0)
  const [components, setComponents] = useState({})
  const [shouldResetCaret, setShouldResetCaret] = useState(false)
  // TODO: Make pointer cursor work for links
  // const [linkNodes, setLinkNodes] = useState([])
  // const [cursor, setCursor] = useState('text')
  const [trigger] = useState(inputTrigger)

  // Setting the value if it changes from the outside
  useEffect(() => {
    initialValue
      && initialValue !== inputValue
      && setInputValue(initialValue)
  }, [initialValue])

  /**
   * Transforming the raw text value into markdown by replacing every {@code mention} occurrence
   * with its respective markup string.
   */
  useEffect(() => {
    /**
     * The user just chose a suggestion. Lets but the cursor right behind the new text. We need
     * to do this, otherwise the cursor would jump to the end of the textarea.
     */
    if (shouldResetCaret !== false) {
      const proposedPosition = lastPosition + shouldResetCaret.length
      const newPosition = inputValue.at(proposedPosition) === '\n'
        ? proposedPosition - 1
        : proposedPosition

      components.text?.setSelectionRange(newPosition, newPosition)
      setShouldResetCaret(false)
    }

    // Transforming raw text into mentions
    if (mentions.length) {
      /**
       * We reduce {@code mentions} starting with {@code inputValue}. For each
       * {@code currentMention} we replace all occurrences of {@code currentMention.display}
       * in {@code allMentions} (meaning {@code inputValue}) with the markup.
       */
      const transformedInputValue = mentions.reduce((allMentions, currentMention) => {
        const { markup, ...restMention } = currentMention
        const mentionMarkup = Object.keys(restMention).reduce(
          (transformedMention, key) => transformedMention.replace(
            `__${key}__`, restMention[key],
          ), markup,
        )

        return allMentions.replaceAll(currentMention.display, mentionMarkup)
      }, inputValue)
      setRenderedValue(transformedInputValue)
    } else {
      // If we don't have any mentions, just use the raw input value.
      setRenderedValue(inputValue)
    }
  }, [inputValue])

  /**
   * Let's also check the inverse: Do we have names in {@code renderedValue}, but not accompanying
   * entries in {@code mentions}? If so, delete them. Otherwise, the user could delete a mention
   * (with backspace, but we'd still notify him).
   */
  useEffect(() => {
    const currentMentions = mentions.reduce(
      (acc, key) => (!renderedValue.includes(key.display) ? acc : [...acc, key]), [],
    )

    /**
     * Prevent unnecessary state updates. Each update will trigger a call to
     * {@link externalOnChange} so we only want to update it if we really need to.
     */
    if (currentMentions.length !== mentions.length) {
      setMentions(currentMentions)
    }
  }, [renderedValue])

  /**
   * Handle changes of input
   *
   * @param {Object} event    the incoming event
   * @param {String} newValue the new value
   * @param {String} reason   why it has been updated
   * @return {Promise<void>}
   */
  const handleInputChange = async (event, newValue, reason) => {
    const currentPosition = event?.target?.selectionStart

    // This gets triggered if the user presses "Enter"
    if (reason === 'reset') {
      // If there is no active trigger, the user just pressed enter for a linebreak
      if (!activeTrigger) return

      /**
       * The user had open suggestions but typed something in that does not match with anything
       * like "@dalsjhbdsa" and pressed enter. Cancel the active trigger so that we don't trigger
       * a search.
       */
      if (newValue === '') {
        setActiveTrigger(false)
        setSuggestions([])
        return
      }

      /**
       * If there is, {@code newValue} will be the {@code display} of the selected suggestion.
       * So find it and add it to {@code mentions}.
       */
      const { markup, route } = activeTrigger.trigger.props
      const { module, action } = route

      const selectedSuggestion = suggestions.find(suggestion => suggestion.display === newValue)

      /**
       * Add the {@code suggestion} to {@code mentions} only if it's not in it already
       * We don't want to notify a user twice only because the user wrote their name twice.
       */
      setMentions(prevMentions => [
        ...prevMentions,
        prevMentions.every(x => x.display !== selectedSuggestion.display)
          ? {
            ...selectedSuggestion,
            markup: markup.replace('__key__', `/#/${context}/${module}/${action}/__key__`),
          }
          : {},
      ])

      // After we added the selected {@code suggestion} to {@code mentions}, reset everything else.
      setActiveTrigger(false)
      setSuggestions([])

      // Get what the user type in, so e.g. "@test" so that we can replace it with the new value.
      const replacedValue = `${inputValue.substring(0, activeTrigger.pos)}${newValue}${inputValue.substring(lastPosition)}`

      setShouldResetCaret(newValue)
      setInputValue(replacedValue)

      return
    }

    /**
     * If we reach this place, the user currently has open suggestions, but hasn't chosen (Enter)
     * one or aborted (Esc). The user can continue to type, so lets first persist what they
     * typed in.
     */
    setInputValue(newValue)
    const nativeEvent = x => new PlatformEvent(event, { value: x, mentions })

    /**
     * Determine if the user has moved around (e.g. clicked somewhere else with the mouse) or
     * moved with the keyboard or entered a space.
     */
    const hasMoved = newValue[currentPosition - 1] === ' '
        || !newValue.substring(newValue.indexOf(' '), currentPosition)
          .split(' ')
          .pop()
          .includes(activeTrigger?.trigger?.props?.trigger)

    // If they have, but they also have open suggestions, cancel them.
    if (hasMoved && activeTrigger) {
      setActiveTrigger(false)
      setSuggestions([])
    }

    const currentChar = newValue[currentPosition - 1]
    const appropriateTrigger = trigger.find(t => t.props.trigger === currentChar)

    /**
     * Check if the user currently has no open suggestions but want some based on the last char
     * they wrote. If it's the correct char for a trigger, set that trigger. Also save the current
     * position of the cursor, so we know where to inject the suggestion later.
     */
    if (!activeTrigger && appropriateTrigger) {
      setActiveTrigger({
        pos: currentPosition - 1,
        trigger: appropriateTrigger,
      })
    }

    /**
     * Finally, the user hasn't moved the cursor and has written at least one char after the
     * trigger char, like "@a". Let's get what they typed and perform a search.
     */
    if (!hasMoved && activeTrigger) {
      const searchTermEnd = newValue.indexOf(' ', activeTrigger.pos + 1)
      const currentSearchTerm = searchTermEnd === -1
        ? newValue.substring(activeTrigger.pos + 1)
        : newValue.substring(activeTrigger.pos + 1, searchTermEnd)

      // Once we get the results, narrow down the suggestions based on it.
      const result = await activeTrigger.trigger.props.events.onChange(
        /**
         * We use {@link currentSearchTerm} instead of {@link debouncedSearchTerm} here because
         * the latter would give us the last search term. So we just use it to wait until it's
         * debounced but then use the current term anyway.
         */
        nativeEvent(currentSearchTerm),
      )
      result.length && setSuggestions(result)
    }

    // If the user simply has no active trigger, they are just typing normal text.
    if (!activeTrigger) {
      setInputValue(newValue)
    }

    /**
     * Save the current position of the cursor for the next invocation, so we can determine if
     * the user has moved.
     */
    setLastPosition(currentPosition)

    // Execute potential {@code onChange} handlers from the child component.
    externalOnChange?.(nativeEvent(newValue))
  }

  /**
   * Handle keyboard presses
   *
   * @param {KeyboardEvent} e  incoming event
   */
  const handleKeyPress = (e) => {
    // Enable the user to go up and down if no suggestions are open
    if ((e.key === 'ArrowDown' || e.key === 'ArrowUp') && !activeTrigger) {
      e.defaultMuiPrevented = true
    }

    // If the user presses Escape, abort the current suggestions
    e.key === 'Escape' && setSuggestions([]) && setActiveTrigger(false)

    /**
     * In case we have an external {@code onKeyDown} handler (like CTRL + Enter in notes),
     * execute it.
     */
    externalOnKeyDown?.(e)
  }

  /**
   * The user has just typed a trigger char (e.g. "@"). Let's perform an inital search so that
   * they get feedback right away. In {@link handleInputChange} we will narrow it further down
   * based on what they type after the trigger char.
   */
  useEffect(() => {
    (async () => {
      if (activeTrigger) {
        const result = await activeTrigger.trigger.props.events.onChange(null)

        result.length && setSuggestions(result)
      }
    })()
  }, [activeTrigger])

  /**
   * In order to show the mentions (hyperlinks) "inside" the input, we need to overlay it with a div
   * that contains our markup. For that to happen, our wrapper needs to have the EXACT same styling
   * that was applied to the underlying MUI {@link Autocomplete} component. So we attach a
   * {@link rootRef} to our wrapper, traverse the DOM to find the class names of the components
   * we need, and apply them to our divs.
   */
  useEffect(() => {
    if (rootRef.current) {
      const renderComponent = rootRef?.current?.firstChild.firstChild.firstChild
      const autoCompleteComponent = rootRef?.current?.lastChild
      const inputComponent = autoCompleteComponent?.firstChild
      const textAreaComponent = inputComponent?.firstChild

      setComponents({
        input: inputComponent,
        text: textAreaComponent,
        render: renderComponent,
      })
    }
  }, [rootRef])

  useEffect(() => {
    externalOnChange?.(new PlatformEvent(new CustomEvent('change', { detail: { value: inputValue, mentions } })))
  }, [mentions])

  /**
   * Handle clicking on a link.
   *
   * This is it: We are working with two layers: First the input field itself, then below it
   * (positioned absolutely but with lower zIndex) the div rendering the markup. So naturally,
   * if we click on a link, nothing happens because we're clicking inside the input field.
   *
   * To make this work, we can use {@code document.elementsFromPoint} to get the elements we're
   * hovering and if it's a link, click it.
   *
   * Note: Why don't we change zIndex to flip the wrapper div and input field? We could do that,
   * but then we can't select text anymore because we're selecting the text in the div.
   *
   * @param {MouseEvent} e    onClick event
   * @param {Object} anchorEl the mui anchorEl
   */
  const handleInputClick = (e, anchorEl) => {
    const elems = document.elementsFromPoint(e.clientX, e.clientY)

    elems.forEach((elem) => {
      if (elem.nodeName === 'A') elem.click()
    })

    // Executing MUI autocomplete's native onClick handler
    anchorEl.InputProps.onClick?.(e)
  }

  /**
   * Wrapper component to overlay the input field.
   *
   * This looks hideous. But we need to mimic the DOM structure of the MUI autocomplete field
   * and its child components so that we can apply their styles to our wrapper in order to have
   * the exact same appearance (margin, padding, ...). We use the {@link rootRef} for that.
   *
   * By doing that, we can than position our wrapper absolutely, so that it lays above the input
   * field and has the same dimensions. If we don't do that, the text we render would be misaligned
   * with the cursor of the input field.
   *
   * @param {Object} params MUI params for the underlying input component
   * @return {JSX.Element}
   */
  const wrapper = params => (
    <div ref={rootRef} style={{ position: 'relative' }}>
      <div className={`${classes.root} ${restProps.className}`}>
        <div className={components.input?.className}>
          <div className={`${components.text?.className} ${classes.text}`}>
            <ReactMarkdown
              unwrapDisallowed={true} // but we want the strings themselves
              disallowedElements={['p']}
              components={{
                a: ({ href, children }) => (
                  <a
                    href={href}
                    target={'_blank'}
                    rel={'nofollow'}
                  >
                    {children}
                  </a>
                ),
              }}
            >
              {/* react-markdown doesn't play nice with new lines. They need a non-breakable
                   space in order to render a line break. If we don't do this, our markup and
                   the actual value will be out of sync. */}
              {renderedValue.replace(/\n/gi, '&nbsp;\n')}
            </ReactMarkdown>
          </div>
        </div>
      </div>
      <Component
        {...params}
        {...restProps}
        InputProps={{
          ...params.InputProps,
          onClick: (e) => { handleInputClick(e, params) },
          // TODO: Make pointer cursor work for links
          // onMouseMove: (e) => {
          //   components.render.childNodes.forEach((node) => {
          //     if (node.nodeName === 'A') {
          //       const currentNode = linkNodes.find(linkNode => linkNode.node.outerText === node.outerText)
          //       const range = currentNode?.range
          //
          //       if (range) {
          //         if (range.getBoundingClientRect().left <= e.clientX
          //             && range.getBoundingClientRect().right >= e.clientX
          //             && range.getBoundingClientRect().top - 10 <= e.clientY
          //             && range.getBoundingClientRect().bottom + 10 >= e.clientY) {
          //           cursor === 'text' && setCursor('pointer')
          //         }
          //       }
          //     }
          //   })
          // },
        }}
        className={`${restProps.className} ${inputValue.length ? classes.input : ''}`}
      />
    </div>
  )

  // TODO: Make pointer cursor work for links
  // useEffect(() => {
  //   const htmlCollection = components?.render?.children
  //   const children = htmlCollection ? Array.from(htmlCollection) : []
  //   const currentLinks = children.reduce((acc, key) => {
  //     const range = key.ownerDocument.createRange()
  //     range.selectNodeContents(key)
  //
  //     return [...acc, { node: key, range }]
  //   }, [])
  //   setLinkNodes(currentLinks)
  // }, [inputValue])

  return (
    <Grid
      {...gridProps}
    >
      <Autocomplete
        freeSolo
        disableClearable
        clearOnBlur={false}
        clearOnEscape={false}

        options={suggestions}
        inputValue={inputValue}
        open={!!suggestions.length}

        onKeyDown={handleKeyPress}
        onInputChange={handleInputChange}

        renderInput={params => wrapper(params)}
        getOptionLabel={option => option?.display || ''}
        renderOption={(rootProps, option) => (
          <Box
            component={'li'}
            className={classes.option}
            {...rootProps}
            key={option.key}
          >
            <Box
              key={'item'}
              component={'div'}
              className={classes.optionContent}
            >
              {!option.display ? null : (
                <Box
                  key={'label'}
                  component={'span'}
                  className={classes.optionLabel}
                >
                  {option.display}
                </Box>
              )}
              <OverflowTooltip>
                {option?.value || ''}
              </OverflowTooltip>
            </Box>
          </Box>
        )}
        filterOptions={(options, state) => {
          if (!activeTrigger) return []

          const nextWhitespace = state.inputValue.indexOf(' ', activeTrigger.pos + 1)
          const nextLinebreak = state.inputValue.indexOf('\n', activeTrigger.pos + 1)

          const possiblePositions = [nextWhitespace, nextLinebreak].reduce(
            (acc, key) => (key > 0 ? [...acc, key] : acc), [],
          )
          const filterTermEnd = possiblePositions.length
            ? Math.min(...possiblePositions)
            : -1

          const triggerValue = filterTermEnd === -1
            ? state.inputValue.substring(activeTrigger.pos + 1)
            : state.inputValue.substring(activeTrigger.pos + 1, filterTermEnd)

          const trimmedTriggerValue = triggerValue.at(0) === activeTrigger.trigger.props.trigger
            ? triggerValue.substring(1)
            : triggerValue

          return options.filter(option => option.value.includes(trimmedTriggerValue))
        }}
      />
    </Grid>
  )
}

export default Mention
